import React, {Component} from 'react';

export default class ImageZoom extends Component {

    imgurl = (image) => {
        let dim = '0x0';
        let prefix = 'https://api.html5.run/unsafe/';
        if (this.props.dimensions) {
            // console.log(this.props.dimensions)
            prefix += this.props.dimensions.width + 'x' + this.props.dimensions.height + "/smart/";
        }
        // return prefix+image;
        return image;
    }

    render() {
        const {image} = this.props;
        const imgclass = this.props.imgclass ? this.props.imgclass : '';

        return (
            <img src={`${this.imgurl(image)}`} className={"img-fluid image_zoom_cls-0 " + imgclass} style={this.props.style ? this.props.style : {}}/>
        );
    }
}