import React, {Component} from "react";
import Slider from "react-slick";
import "../commonAll/index.scss";
import {connect} from "react-redux";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faCity,
    faEnvelope,
    faGlobe,
    faVideo,
} from "@fortawesome/free-solid-svg-icons";
import {
    Facebook,
    Linkedin,
    Video,
    Mail,
    PhoneCall,
    Home,
    Twitter,
} from "react-feather";
// import custom Components
import RelatedProduct from "../commonAll/related-product";
import Breadcrumb from "../commonAll/breadcrumb";
import Details from "./common/product/details";
//import Doctordetails from "./common/product/details-doctor";
import Price from "./common/product/price";
import PriceDoctor from "./common/product/price-doctor";
import DoctorDetails from "./common/product/DoctorDetails";
import DetailsTopTabs from "./common/details-top-tabs";
import DoctorTopTabs from "./common/doctor-top-tabs";
import {addToCart, addToCartUnsafe, addToWishlist} from "../../redux/actions";
import ImageZoom from "./common/product/image-zoom";
import SmallImages from "./common/product/small-image";
import axios from "axios";
import appConfig from "../../config";
import _ from "lodash";
import CommentsHosital from "./common/comments/comments-hosital";
import Interweave from "interweave";
import {Button, Rate, Skeleton} from "antd";
import {Link as ReactLink} from "react-router-dom";
import {Anchor} from "antd";
import HospitalSpecialities from "./common/product/hospital-specialitis";
import ReactHtmlParser from "react-html-parser";
import {Helmet} from "react-helmet";
import {cleanTags} from "../../api/apicalls";
import Text from "antd/es/typography/Text";
import {RiseOutlined} from "@ant-design/icons";
import Hospital from "../search/hospital-card";

const {Link} = Anchor;

class Doctor extends Component {
    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            isLoading: true,
            top: "",
            show: true,
            meta: {
                title: "Hosplan",
                desc: ""
            }
        };
    }

    listenScrollEvent = (e) => {
        // console.log(window.scrollY)
        if (window.scrollY > 100) {
            this.setState({top: "0px"});
        } else {
            this.setState({top: "40px"});
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent);
        document.getElementById("sticky").style.display = "none";
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        const {
            match: {params},
        } = this.props;
        this.doctorId = params.id;
        console.log("DOCTOR/ITEM-PAGE State : ", this.state);
        console.log("DOCTOR/ITEM-PAGE Props : ", this.props);
        console.log("DOCTOR/ITEM-PAGE useParams : ", params);

        let d = this.props.doctors.doctors;
        let did = this.props.did;
        let doctor = _.find(d, {slug: did});
        if (doctor) {
            console.log('DOCTOR/ITEM: Data Found', doctor);
            this.setState({doctor, isLoading: false});
        } else {
            this.getDoctor(params.id);
        }
    }

    getDoctor(id) {
        // let param = "/" + id;
        let param = "?slug=" + id;
        console.log("param is ", param);
        axios.get(appConfig.doctors + param).then((response) => {
            // Handle success.
            console.log('DOCTOR/ITEM: Data Received', response.data);
            let doctor = response.data[0];
            this.setState({
                doctor,
                isLoading: false,
                meta: {
                    title: doctor.Name + " || Hosplan",
                    desc: doctor.ShortDetails
                }
            });
        });
    }

    render() {
        const {
            symbol,
            addToCart,
            addToCartUnsafe,
            addToWishlist,
            auth,
        } = this.props;
        var products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true,
        };
        var productsnav = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        };
        let item = this.state.doctor;
        // console.log("doctor::item", item);
        //console.log("DOCTOR/ITEM-PAGE : ", item);
        window.diprops = this.props;
        window.distate = this.state;

        const hospitalPlaceholder = `${process.env.PUBLIC_URL}/assets/images/building.png`;
        const doctorPlaceholder = `${process.env.PUBLIC_URL}/assets/images/doctors/Doctor-Male-Placeholder.jpg`;

        return (
            <div className="doctor-body doc-main container">
                {/*SEO Support*/}
                <Helmet>
                    <title>{this.state.meta.title}</title>
                    <meta name="description" content={this.state.meta.desc}/>
                </Helmet>
                {/*SEO Support End */}
                {item ? (
                    <div >
                        {/*<section id="Overview" style={{paddingTop: "0"}}>*/}
                            {item ? (
                                <div
                                    className="row price-doctor-inside-card hide-me"
                                    style={{top: this.state.top}}
                                    // style={{top: '0px'}}
                                >
                                    <PriceDoctor
                                        symbol={symbol}
                                        item={item}
                                        navOne={this.state.nav1}
                                        addToCartClicked={addToCart}
                                        BuynowClicked={addToCartUnsafe}
                                    />
                                </div>
                            ) : null}
                        {/*</section>*/}


                        <section id="Overview" style={{paddingTop: "0"}} >
                            {/*{item ? (*/}
                            {/*    <div*/}
                            {/*        className="price-doctor-inside-card hide-me"*/}
                            {/*        style={{top: this.state.top}}*/}
                            {/*    >*/}
                            {/*        <PriceDoctor*/}
                            {/*            symbol={symbol}*/}
                            {/*            item={item}*/}
                            {/*            navOne={this.state.nav1}*/}
                            {/*            addToCartClicked={addToCart}*/}
                            {/*            BuynowClicked={addToCartUnsafe}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*) : null}*/}

                            {item ? (
                                <div className="doctor-container">
                                    <div className="doctor-inner-container">
                                        <div
                                            style={{
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                marginTop: "10px",
                                            }}
                                        >
                                            <div className="doctor-page-card dark-bg">
                                                <div className="summary">
                                                    <div style={{marginRight: "24px"}}>
                                                        {item.Pictures && item.Pictures.length ? (
                                                            <img
                                                                className="designated-provider-image"
                                                                style={{width: '235px',height: '300px',objectFit: 'cover'}}
                                                                src={item.Pictures[0].url}
                                                            />
                                                        ) : (
                                                            <img
                                                                className="designated-provider-image"
                                                                style={{width: '235px',height: '300px',objectFit: 'cover'}}
                                                                src="/assets/images/doctors/Doctor-Male-Placeholder.jpg"
                                                            />
                                                        )}
                                                    </div>
                                                    <div>
                                                        <div style={{color: "#fff"}} className="h2">
                                                            {item.Name}
                                                        </div>

                                                        {item.Education ? (
                                                            <p style={{color: "#fff", marginTop: "10px"}}>
                                                                {item.Education}
                                                            </p>
                                                        ) : null}

                                                        <p style={{color: "#fff", marginTop: "10px"}}>
                                                            {item.Designation}
                                                        </p>
                                                        {item.Department ? (
                                                            <p style={{color: "#fff", marginTop: "10px"}}>
                                                                {item.Department}
                                                            </p>
                                                        ) : null}
                                                        {item.Experience ? (
                                                            <p style={{color: "#fff", marginTop: "10px"}}>
                                                                Experience : {item.Experience} years
                                                            </p>
                                                        ) : null}

                                                        {item.hospital?
                                                            <ReactLink
                                                                to={`${process.env.PUBLIC_URL}/hospital/${
                                                                    item.hospital.Alias
                                                                }`}
                                                            >
                                                                {/*<FontAwesomeIcon*/}
                                                                {/*    style={{color: "#fff", marginRight: "5px"}}*/}
                                                                {/*    icon={faCity}*/}
                                                                {/*/>*/}
                                                                <h4 style={{color:'#fff',  textDecoration:'underline'}}>{item.hospital.Name}</h4>
                                                            </ReactLink>
                                                            :''}


                                                        <Text style={{fontSize: '16px', color:'#fff',}}>
                                                            Rating on Google :
                                                        </Text>
                                                        <Rate
                                                            disabled
                                                            allowHalf
                                                            value={item.googleRating ? item.googleRating : item.Rating}
                                                            // value={
                                                            //     item.hospital && item.hospital.Rating
                                                            //         ? item.hospital.Rating
                                                            //         : 0
                                                            // }
                                                        />


                                                        <br/>

                                                        {/*{item.City ? (*/}
                                                        {/*    <p style={{color: "#fff", marginTop: "10px"}}>*/}
                                                        {/*        */}
                                                        {/*        {item.City}*/}
                                                        {/*    </p>*/}
                                                        {/*) : null}*/}
                                                        <HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{
                                                            color: '#9a9a9a',
                                                            fontSize: '10px',
                                                            textTransform: 'none',
                                                            padding: '0.1rem 0.4rem',
                                                            background: '#ececec',
                                                        }}/>
                                                    </div>
                                                </div>
                                                <div className="make-appt row mt-3">
                                                    <div className="col-sm-4">
                                                        {/*<p style={{color: "#fff", fontWeight: "bold"}}>*/}
                                                        {/*    <span> Share | Save</span>*/}
                                                        {/*</p>*/}
                                                        {/*{item.Address ? (*/}
                                                        {/*    <p style={{color: "#fff"}}>*/}
                                                        {/*        <Home*/}
                                                        {/*            style={{*/}
                                                        {/*                height: "18px",*/}
                                                        {/*                width: "18px",*/}
                                                        {/*                marginRight: "8px",*/}
                                                        {/*            }}*/}
                                                        {/*        />*/}
                                                        {/*        {item.Address}*/}
                                                        {/*    </p>*/}
                                                        {/*) : null}*/}
                                                        <div className="doctor__social">
                                                            {item.Email ? (
                                                                <a href={item.Email}>
                                                                    <Mail
                                                                        style={{
                                                                            color: "#fff",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                    />
                                                                </a>
                                                            ) : null}
                                                            {item.Facebook ? (
                                                                <a href={item.Facebook}>
                                                                    <Facebook
                                                                        style={{
                                                                            color: "#fff",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                    />
                                                                </a>
                                                            ) : null}
                                                            {item.Linkedin ? (
                                                                <a href={item.Linkedin}>
                                                                    <Linkedin
                                                                        style={{
                                                                            color: "#fff",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                    />
                                                                </a>
                                                            ) : null}
                                                            {item.Website ? (
                                                                <a href={item.Website}>
                                                                    <FontAwesomeIcon
                                                                        style={{
                                                                            color: "#fff",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                        icon={faGlobe}
                                                                    />
                                                                </a>
                                                            ) : null}
                                                            {item.Twitter ? (
                                                                <a href={item.Twitter}>
                                                                    <Twitter
                                                                        style={{
                                                                            color: "#fff",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                    />
                                                                </a>
                                                            ) : null}
                                                            {item.Video ? (
                                                                <a href={item.Video}>
                                                                    <Video
                                                                        style={{
                                                                            color: "#fff",
                                                                            width: "20px",
                                                                            height: "20px",
                                                                        }}
                                                                    />
                                                                </a>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="doctor-about-container">
                                                    <hr style={{backgroundColor: "#fff"}}/>
                                                    <div style={{color: "#fff"}} className="about-doctor">
                                                        <div className='docDarkBack'>
                                                            <Interweave content={cleanTags(item.Description, "<b><i><u><p><ol><ul>")}/>
                                                            {/*<Interweave content={cleanTags(item.ShortDetails, "<b><i><u><p><ol><ul>")}/>*/}
                                                        </div>
                                                        {/*{this.state.show*/}
                                                        {/*    ? ReactHtmlParser(item.ShortDetails)*/}
                                                        {/*    : item.Description.substring(0, 200) + "..."}*/}
                                                    </div>
                                                    {/*<button*/}
                                                    {/*    className="readMore btn btn-light"*/}
                                                    {/*    onClick={() => {*/}
                                                    {/*        this.setState({show: !this.state.show});*/}
                                                    {/*    }}*/}
                                                    {/*>*/}
                                                    {/*    {this.state.show ? "read less" : "read More"}*/}
                                                    {/*</button>*/}
                                                    <hr style={{backgroundColor: "#fff"}}/>
                                                </div>
                                                <div className="doctorBookingInPage">
                                                    {item ? (
                                                        <div
                                                            className="row doctor-price-card"
                                                            style={{top: this.state.top}}
                                                            // style={{top: '0px'}}
                                                        >
                                                            <PriceDoctor
                                                                symbol={symbol}
                                                                item={item}
                                                                navOne={this.state.nav1}
                                                                addToCartClicked={addToCart}
                                                                BuynowClicked={addToCartUnsafe}
                                                            />
                                                        </div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <Skeleton active avatar paragraph={{rows: 7}}/>
                            )}
                        </section>

                        <Anchor className="m-0 width-75">
                            <Link
                                href={`/doctor/${this.doctorId}#Overview`}
                                title="Overview"
                            />
                            <Link href={`/doctor/${this.doctorId}#Rating`} title="Rating"/>
                            {item.ShortDetails ? (
                                <Link href={`/doctor/${this.doctorId}#short`} title="Details"/>
                            ) : null}
                            <Link
                                href={`/doctor/${this.doctorId}#Awards`}
                                title="Awards & Membership"
                            />
                            {item.UniqueContribution ? (
                                <Link
                                    href={`/doctor/${this.doctorId}#Map`}
                                    title="Location"
                                />
                            ) : null}
                            {/*<Link*/}
                            {/*    href={`/doctor/${this.doctorId}#HospitalSection`}*/}
                            {/*    title="Hospital"*/}
                            {/*/>*/}
                        </Anchor>

                        <div
                            style={{marginLeft: "auto", marginRight: "auto"}}
                            className="container-fluid"
                        >

                            <section id="HospitalSection" className="width-75">
                                <Hospital data={item.hospital} index={0}/>
                                {/*{item && item.hospital ? (*/}
                                {/*    <section>*/}
                                {/*        <div className="media">*/}
                                {/*            <div className="borderDesc container">*/}
                                {/*                <h3>Hospital</h3>*/}
                                {/*                <ReactLink*/}
                                {/*                    to={`${process.env.PUBLIC_URL}/hospital/${*/}
                                {/*                        item.hospital.Alias*/}
                                {/*                    }`}*/}
                                {/*                >*/}
                                {/*                    <img*/}
                                {/*                        className="img-fluid hospital-card-image"*/}
                                {/*                        src={`${item.hospital.Pictures && item.hospital.Pictures[0] ? item.hospital.Pictures[0].url : hospitalPlaceholder}`}*/}
                                {/*                        alt=""*/}
                                {/*                        style={{width: "400px", margin: "0"}}*/}
                                {/*                    />*/}
                                {/*                </ReactLink>*/}
                                {/*                <div className="media-body align-self-center">*/}
                                {/*                    <Rate*/}
                                {/*                        disabled*/}
                                {/*                        value={item.hospital.Rating}*/}
                                {/*                        style={{fontSize: 14, color: "#ffa201"}}*/}
                                {/*                    />*/}
                                {/*                    <ReactLink*/}
                                {/*                        to={`${process.env.PUBLIC_URL}/hospital/${*/}
                                {/*                            item.hospital.Alias*/}
                                {/*                        }`}*/}
                                {/*                    >*/}
                                {/*                        <h6>{item.hospital.Name}</h6>*/}
                                {/*                    </ReactLink>*/}
                                {/*                </div>*/}

                                {/*            </div>*/}
                                {/*        </div>*/}
                                {/*    </section>*/}
                                {/*) : null}*/}
                            </section>

                            <section id="short" className="width-75">
                                {item.Description ? (
                                    <div className="borderDesc container">
                                        <h4 className="product-title">Description :</h4>
                                        {/*<Interweave content={item.ShortDetails}/>*/}
                                        {/*{ReactHtmlParser(item.Description)}*/}
                                        <div className='hspDescPage'>
                                            <Interweave content={cleanTags(item.ShortDetails)}/>
                                        </div>
                                    </div>
                                ) : null}
                            </section>
                            <section>
                                {item && item.Awards ? (
                                    <div className="borderDesc container">
                                        <h4 className="product-title">Awards :</h4>
                                        {/*<Interweave content={item.Awards}/>*/}
                                        {/*{item.UniqueContribution}*/}
                                        {/*{ReactHtmlParser(item.Awards)}*/}
                                        <div className='hspDescPage'>
                                            <Interweave content={cleanTags(item.Awards)}/>
                                        </div>
                                    </div>
                                ) : null}
                            </section>
                            <section id="Awards" className="width-75">
                                <section>
                                    {item && item.Memberships ? (
                                        <div className="borderDesc container">
                                            <h4 className="product-title">Memberships :</h4>
                                            {/*<Interweave content={item.Memberships}/>*/}
                                            {/*{item.UniqueContribution}*/}
                                            {/*{ReactHtmlParser(item.Memberships)}*/}
                                            <div className='hspDescPage'>
                                                <Interweave content={cleanTags(item.Memberships)}/>
                                            </div>
                                        </div>
                                    ) : null}
                                </section>

                            </section>
                            <section id="short" className="width-75">
                                {item.UniqueContribution ? (
                                    <div className="borderDesc container">
                                        <h4 className="product-title">Contributions :</h4>

                                        {/*<Interweave content={item.UniqueContribution}/>*/}
                                        {/*{ReactHtmlParser(item.UniqueContribution)}*/}
                                        <div className='hspDescPage'>
                                            <Interweave content={cleanTags(item.UniqueContribution)}/>
                                        </div>
                                    </div>
                                ) : null}
                            </section>

                            <hr/>

                            <section id="Map" className="width-75">
                                <h3>Location: <Button
                                    type="primary"
                                    icon={<RiseOutlined/>}
                                    type="dashed"
                                    onClick={() => {
                                        let lnk = `https://www.google.com/maps/dir/?api=1&destination_place_id=${item.place_id}&destination=${item.place_id}`
                                        window.open(lnk, "_blank")
                                    }}
                                >Get Directions</Button></h3>
                                <DoctorTopTabs item={item}/>
                            </section>


                            <section id="Rating">
                                {item ? (
                                    <section className="tab-product m-0">
                                        {/* <div className="container"> */}
                                        <div className="row">
                                            <div className="col-sm-12 col-lg-12">
                                                <CommentsHosital
                                                    item={item}
                                                    auth={auth}
                                                    type={"doctor"}
                                                />
                                            </div>
                                        </div>
                                        {/* </div> */}
                                    </section>
                                ) : (
                                    <Skeleton active avatar paragraph={{rows: 7}}/>
                                )}
                            </section>
                        </div>
                        {/*<section id="Overview" style={{paddingTop: "0"}}>*/}
                        {/*{item ? (*/}
                        {/*    <div*/}
                        {/*        className="price-doctor-inside-card hide-me"*/}
                        {/*        style={{top: this.state.top}}*/}
                        {/*    >*/}
                        {/*        <PriceDoctor*/}
                        {/*            symbol={symbol}*/}
                        {/*            item={item}*/}
                        {/*            navOne={this.state.nav1}*/}
                        {/*            addToCartClicked={addToCart}*/}
                        {/*            BuynowClicked={addToCartUnsafe}*/}
                        {/*        />*/}
                        {/*    </div>*/}
                        {/*) : null}*/}
                        {/*</section>*/}
                    </div>
                ) : (
                    <Skeleton active avatar paragraph={{rows: 7}}/>
                )}
            </div>

            //     <div style={{backgroundColor:"#ECECEC"}} >
            //     <div className='container inPageFrame'>
            //     {/*<div className='container inPageFrame' style={{margin:'0 10% 0 10%'}}>*/}
            //     {/*<div style={{margin:'0 10% 0 10%',boxShadow: "0px 3px 5px 5px rgba(0,0,0,0.15)"}}>*/}

            //         <Breadcrumb title={' Doctor / ' + (item ? item.Name : " ")}/>

            //         {/*Section Start*/}
            //         {(item) ?
            //             <section>
            //                 <div className="collection-wrapper">
            //                     <div className="container">
            //                         <div className="row">
            //                             <div className="col-lg-4 product-thumbnail">
            //                             <h3 style={{marginBottom: '5px'}}> {item.Name} {item.Verified?<img src={`${process.env.PUBLIC_URL}/assets/images/icons/verified.svg`} style={{width:'20px', paddingBottom:'10px'}} alt={"verified"}/>:''}</h3>
            //                                 {item.Pictures.length ?
            //                                     <Slider {...products} asNavFor={this.state.nav2}
            //                                             ref={slider => (this.slider1 = slider)}
            //                                             className="product-right-slick">
            //                                         {item.Pictures.map((vari, index) =>
            //                                             <div key={index}>
            //                                                 <ImageZoom image={vari.url}
            //                                                            className="img-fluid image_zoom_cls-0"/>
            //                                             </div>
            //                                         )}
            //                                     </Slider>
            //                                     : <Slider {...products} asNavFor={this.state.nav2}
            //                                               ref={slider => (this.slider1 = slider)}
            //                                               className="product-right-slick">
            //                                         <div>
            //                                             <ImageZoom
            //                                                 image={"/assets/images/doctors/Doctor-Male-Placeholder.jpg"}
            //                                                 className="img-fluid image_zoom_cls-0"/>
            //                                         </div>
            //                                     </Slider>}

            //                                 {/*<br/>*/}
            //                                 {/*<br/>*/}
            //                                 {/*{item.hospital ? <div className="media">*/}
            //                                 {/*    <Link to={`${process.env.PUBLIC_URL}/doctor/${item.hospital.Alias}`}>*/}
            //                                 {/*        <img className="img-fluid" src={`${item.hospital.Pictures[0].url}`} alt="" style={{width:'200px', margin:'20px'}}/>*/}
            //                                 {/*    </Link>*/}
            //                                 {/*    <div className="media-body align-self-center">*/}
            //                                 {/*        <Rate disabled value={item.hospital.Rating} style={{ fontSize: 14, color: '#ffa201' }}/>*/}
            //                                 {/*        <Link to={`${process.env.PUBLIC_URL}/doctor/${item.hospital.Alias}`}>*/}
            //                                 {/*            <h6>{item.hospital.Name}</h6></Link>*/}

            //                                 {/*    </div>*/}
            //                                 {/*</div> : ""}*/}

            //                             </div>
            //                             {/* Product Details */}
            //                             {/* <Doctordetails item={item} addToWishlistClicked={addToWishlist}/> */}
            //                             <DoctorDetails item={item} />
            //                             {/* Product Price Details */}
            //                             <PriceDoctor symbol={symbol} item={item} navOne={this.state.nav1}
            //                                          addToCartClicked={addToCart} BuynowClicked={addToCartUnsafe}/>
            //                             {/*<img src={"/assets/images/not-available.jpg"}  className="img-fluid image_zoom_cls-0" />*/}

            //                         </div>
            //                     </div>
            //                 </div>
            //             </section> : <Skeleton active avatar paragraph={{rows: 7}}/>}
            //         {/*Section End*/}

            //         <br/>
            //         <br/>

            //         <section>
            //             <div className="collection-wrapper">
            //                 <div className="container">
            //                     <div className="row">
            //                         <div className="col-md-8">
            //                             {/*<div>*/}
            //                             {(item) ?
            //                                 <section>
            //                                     <div className="borderDesc container">
            //                                         <h4 className="product-title">Doctor’s Profile</h4>
            //                                         <Interweave content={item.Description}/>
            //                                         {/*{item.Description}*/}
            //                                     </div>
            //                                     <br/>
            //                                     <br/>
            //                                     {item.UniqueContribution ?
            //                                         <div className="borderDesc container">
            //                                             <h4 className="product-title">Unique Contribution</h4>
            //                                             <Interweave content={item.UniqueContribution}/>
            //                                             {/*{item.UniqueContribution}*/}
            //                                         </div> : ""}
            //                                 </section> : <Skeleton active avatar paragraph={{rows: 7}}/>}

            //                             {(item) ?
            //                                 <section className="tab-product m-0">
            //                                     <div className="container">
            //                                         <div className="row">
            //                                             <div className="col-sm-12 col-lg-12">
            //                                                 <DoctorTopTabs item={item}/>
            //                                                 <CommentsHosital item={item} auth={auth} type={'doctor'}/>
            //                                             </div>

            //                                         </div>
            //                                     </div>
            //                                 </section> : <Skeleton active avatar paragraph={{rows: 7}}/>}
            //                         </div>
            //                         <div className="col-md-4">

            //                             {item && item.hospital ? <section>
            //                                 <div className="media">
            //                                     <div className="borderDesc container">
            //                                         <Link to={`${process.env.PUBLIC_URL}/hospital/${item.hospital.Alias}`}>
            //                                             <img className="img-fluid" src={`${item.hospital.Pictures[0].url}`}
            //                                                  alt="" style={{width: '200px', margin: '20px'}}/>
            //                                         </Link>
            //                                         <div className="media-body align-self-center">
            //                                             <Rate disabled value={item.hospital.Rating}
            //                                                   style={{fontSize: 14, color: '#ffa201'}}/>
            //                                             <Link
            //                                                 to={`${process.env.PUBLIC_URL}/hospital/${item.hospital.Alias}`}>
            //                                                 <h6>{item.hospital.Name}</h6>
            //                                             </Link>
            //                                         </div>
            //                                     </div>
            //                                 </div>
            //                             </section> : ""}

            //                             <section>
            //                                 {item && item.Memberships ?
            //                                     <div className="borderDesc container">
            //                                         <h4 className="product-title">Memberships :</h4>
            //                                         <Interweave content={item.Memberships}/>
            //                                         {/*{item.UniqueContribution}*/}
            //                                     </div> : ""}
            //                             </section>
            //                             <section>
            //                                 {item && item.Awards ?
            //                                     <div className="borderDesc container">
            //                                         <h4 className="product-title">Awards :</h4>
            //                                         <Interweave content={item.Awards}/>
            //                                         {/*{item.UniqueContribution}*/}
            //                                     </div> : ""}
            //                             </section>
            //                         </div>
            //                     </div>
            //                 </div>
            //             </div>
            //         </section>
            //         {/*<RelatedProduct />*/}
            //     </div>
            // </div>
            //
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.match.params.id;
    console.log("productId", productId);
    console.log("state", state);
    return {
        item: state.data.products.find((el) => el.id == productId),
        // symbol: state.data.symbol,
        symbol: "BDT",
        doctors: state.doctors,
        did: productId,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
    {addToCart, addToCartUnsafe, addToWishlist}
)(Doctor);
