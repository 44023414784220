import React, { Component } from "react";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import { getDoctors } from "../../../redux/actions/doctorActions";
import { Skeleton } from "antd";

import { getTotal, getCartProducts } from "../../../redux/reducers";
import {
  addToCart,
  addToWishlist,
  addToCompare,
  filterAddHospitalCities,
  filterAddCities,
  filterDCity,
} from "../../../redux/actions";
import {
  getVisibleproducts,
  getVisibleDoctors,
  getDoctorsForSearchParam,
  getHospitalsForSearchParam,
  searchKeywords,
} from "../../../services";
//import ProductListItem from "./_old/product-list-item";
import DoctorListItem from "./doctor-list-item";
import SearchCheck from "../../search/search";
import {
  getParams,
  getSearchParamID,
  getHospitals as searchHospitals,
  getCount,
} from "../../../api/apicalls";
import _ from "lodash";
import qs from "qs";

class SearchListing extends Component {
  constructor(props) {
    super(props);

    this.state = {
      limit: 10,
      page: 0,
      hasMoreItems: true,
      searchResult: [],
      filteredResults: [],
      isLoading: true,
      qerd: "",
      qerh: "",
      searchParams: [],
      count: 0,
      filterq: "",
    };
  }

  componentWillMount() {
    if (this.props.location.search) {
      this.getListingData();
    } else {
      console.log("This is not right");
    }
  }

  // filterData() {
  //     let result = this.state.searchResult;
  //     let {filterHos} = this.props;
  //     console.log("hospital Listing Props", this.props);
  //
  //     let tempResult = [];
  //     result.map(product => {
  //
  //         //  return product;
  //         if (
  //             (filterHos.hCheckedCity.length ? filterHos.hCheckedCity.includes(product.City) : true) &&
  //             (product.Rating <= filterHos.hrate[1] && product.Rating >= filterHos.hrate[0])
  //         ) {
  //             tempResult.push(product);
  //
  //         }
  //
  //
  //     })
  //     let filteredResults = tempResult;
  //
  //
  //     console.log("hi", filteredResults)
  //     this.setState({filteredResults: filteredResults})
  //     //    filter(product=>(product.Rating<=filterHos.drate.max && product.Rating>=filterHos.drate.min)).filter(product=>(product.Experience<=filterHos.experience.max && product.Experience>=filterHos.experience.min))
  //     //    .filter(product=>(product.Price<=filterHos.dprices.max && product.Price>=filterHos.dprices.min))
  //
  //     // //    map((product)=>{
  //     // //     if((product.Rating<=filterHos.drate.max && product.Rating>=filterHos.drate.min)&&
  //     // //     (product.Experience<=filterHos.experience.max && product.Experience>=filterHos.experience.min)&&
  //     // //     (product.OnVideo===filterHos.availableFor.videoConference && product.OnPhone===filterHos.availableFor.onCall && product.OnLocation===filterHos.availableFor.inHospital)&&
  //     // //      (product.Price<=filterHos.dprices.max && product.Price>=filterHos.dprices.min)){
  //     // //          return product
  //     // //      }
  //     // // })
  // }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      // this.getSearchParam();
    }
    // if (prevProps.searchString !== this.props.searchString) {
    //     console.log("SEARCH-LISTING :: searchString Updated")
    //     this.getListingData();
    // }
    if (prevProps.searchString !== this.props.searchString) {
      // console.log("SEARCH-LISTING :: searchString Updated")
      this.setState(
        {
          searchResult: [],
          filteredResults: [],
          limit: 10,
          page: 0,
          hasMoreItems: true,
          isLoading: true,
        },
        () => {
          this.getListingData();
        }
      );
    }
    if (
      JSON.stringify(prevProps.filterHos.hCheckedCity) !==
      JSON.stringify(this.props.filterHos.hCheckedCity)
    ) {
      this.filterData();
    }
    if (
      JSON.stringify(prevProps.filterHos.rateSelected) !==
      JSON.stringify(this.props.filterHos.rateSelected)
    ) {
      this.filterData();
    }
  }

  filterData() {
    let { filterHos } = this.props;
    let qobj = {};

    let cities = filterHos.hCheckedCity;
    let rateSelected = filterHos.rateSelected;

    let cq = [];
    if (cities && cities.length) {
      cities.map((c, indx) => {
        let q = { City: c };
        if (rateSelected) {
          q.googleRating_gte = rateSelected;
        }
        cq.push(q);
      });
      console.log("Cities Query ", cq);
      qobj = {
        _where: {
          _or: cq,
        },
      };
    } else {
      if (rateSelected) {
        qobj = {
          _where: [{ googleRating_gte: rateSelected }],
        };
      }
    }

    let q = qs.stringify(qobj, { encode: false });

    this.setState(
      {
        searchResult: [],
        filteredResults: [],
        limit: 10,
        page: 0,
        hasMoreItems: true,
        isLoading: true,
        filterq: q,
        // docExp: this.props.filterDoc.docExp,
      },
      () => {
        this.getListingData();
      }
    );
  }

  getSearchCount = (query) => {
    getCount(query).then((count) => {
      console.log("Count is : ", count);
      this.setState({ count });
    });
  };

  getListingData = (qer) => {
    // eslint-disable-next-line no-restricted-globals
    let params = getParams(location.href);
    let prms = [];

    let qerh,
      qerd = "";

    // console.log("The params are : ", params)
    if (params["Treatment"]) {
      prms.push(this.getSearchItem("treatment", params["Treatment"]));
    }
    if (params["Condition"]) {
      prms.push(this.getSearchItem("condition", params["Condition"]));
    }
    if (params["Specialisation"]) {
      prms.push(this.getSearchItem("specialisation", params["Specialisation"]));
    }
    if (params["Speciality"]) {
      prms.push(this.getSearchItem("speciality", params["Speciality"]));
    }
    if (params["Hospital"]) {
      prms.push(this.getSearchItem("hospital", params["Hospital"]));
    }
    if (params["Doctor"]) {
      prms.push(this.getSearchItem("doctor", params["Doctor"]));
    }
    // console.log("SEARCH FOR ", prms);
    qerh = this.createHosQuery(prms);
    qerd = this.createDocQuery(prms);

    this.setState({ qerh, qerd, searchParams: prms });

    if (this.state.filterq) {
      qerh = this.state.filterq + "&" + qerh;
    } else {
      // qerh="?"+qerh
    }
    this.getSearchCount("?" + qerh);

    // console.log("Search Query is : ", qerh);

    searchHospitals("?" + qerh, this.state.limit, this.state.page).then(
      (src) => {
        // console.log("SEARCH RESULT : ", src);
        if (src.length) {
          let hos = src;

          let arr = _.sortBy(src, "id");

          this.setState({
            searchResult: [...this.state.searchResult, ...arr],
            filteredResults: [...this.state.filteredResults, ...arr],
            isLoading: false,
            page: this.state.page + this.state.limit,
          });

          let cities = [];
          arr.forEach((element) => {
            if (cities.indexOf(element.City) === -1) cities.push(element.City);
          });

          this.props.handleCity(cities);

          this.props.filterAddCities(cities);
          this.props.filterDCity(cities);
          if (this.state.searchResult.length >= this.state.count) {
            this.setState({ hasMoreItems: false });
          }
        } else {
          this.setState({ isLoading: false });
        }
      }
    );
  };

  getListingDataOld = () => {
    // eslint-disable-next-line no-restricted-globals
    let params = getParams(location.href);
    let prms = [];
    // console.log("SEARCH :: params for url are : ", params);
    if (Object.keys(params).length && Object.keys(params)[0] !== "") {
      Object.keys(params).map((param, idx) => {
        // console.log("SEARCH :: The individual Param is : ", param, ' - ', params[param]);
        prms.push(getSearchParamID(param, params[param].replaceAll("-", " ")));
      });
      Promise.all(prms).then((resp) => {
        // console.log("SEARCH :: ALL PROMISE : ", resp);
        let qer = "";
        resp.map((query) => {
          // console.log("SEARCH :: queary is : ", query[0])
          qer = qer.length
            ? qer + "&" + searchKeywords[query[0].category] + "=" + query[0].id
            : searchKeywords[query[0].category] + "=" + query[0].id;
        });
        console.log("SEARCH :: Final Queary is : ", qer);
        searchHospitals("?" + qer).then((r) => {
          this.setState({ searchResult: [...r], filteredResults: [...r] });
          let cities = [];
          r.forEach((element) => {
            if (cities.indexOf(element.City) === -1) cities.push(element.City);
          });
          this.props.handleCity(cities);

          this.props.filterAddHospitalCities(cities);
        });
      });
    }
  };

  createHosQuery = (prms) => {
    let qerh = "";
    prms.map((query) => {
      if (query) {
        if (query.category === "hospital") {
          qerh = qerh.length ? qerh + "&" + "id=" + query.id : "id=" + query.id;
        } else if (query.category === "doctor") {
          qerh = qerh.length
            ? qerh + "&" + "doctors.id=" + query.id
            : "doctors.id=" + query.id;
        } else {
          qerh = qerh.length
            ? qerh + "&" + searchKeywords[query.category] + "=" + query.id
            : searchKeywords[query.category] + "=" + query.id;
        }
      }
    });
    // console.log("SEARCH qerh = ", qerh);
    return qerh;
  };

  createDocQuery = (prms) => {
    let qerd = "";
    prms.map((query) => {
      if (query) {
        if (query.category === "doctor") {
          qerd = qerd.length ? qerd + "&" + "id=" + query.id : "id=" + query.id;
        } else {
          qerd = qerd.length
            ? qerd + "&" + searchKeywords[query.category] + "=" + query.id
            : searchKeywords[query.category] + "=" + query.id;
        }
      }
    });
    // console.log("SEARCH qerd = ", qerd);
    return qerd;
  };

  fetchMoreItems = () => {
    console.log(
      "reached ",
      this.state.limit,
      this.props.doctors.doctors.length
    );
    this.getListingData();
  };

  getSearchItem = (cat, slug) => {
    let baseItem = {
      category: cat,
      id: slug,
    };
    let item = {};
    // return item;

    switch (cat) {
      case "treatment":
        item = _.find(this.props.suggTreatments, { value: slug });
        break;
      case "condition":
        item = _.find(this.props.suggConditions, { value: slug });
        break;
      case "specialisation":
        item = _.find(this.props.suggSpecialisations, { value: slug });
        break;
      case "speciality":
        item = _.find(this.props.suggSpecialisations, { value: slug });
        break;
      case "hospital":
        item = _.find(this.props.suggHospitals, { value: slug });
        break;
      case "doctor":
        item = _.find(this.props.suggDoctors, { value: slug });
        break;
      default:
        console.log("Match Nothing");
    }
    return item ? item : baseItem;
  };

  getSkeleton = () => {
    if (!this.state.isLoading) {
      return null;
    } else {
      return <Skeleton active avatar paragraph={{ rows: 7 }} />;
    }
  };

  render() {
    // console.log("search-listing::Filters", this.props.filters);

    const { filteredResults, searchResult } = this.state;
    // console.log("searchResult:", searchResult);
    window.dlstate = this.state;
    window.srclistingprops = this.props;
    // console.log("DOCTOR/LISTING-PAGE props : ", this.props);
    // console.log("DOCTOR/LISTING-PAGE state : ", this.state);

    return (
      <div>
        <div className="product-wrapper-grid">
          <div className="product-top-filter">
            <div className="container-fluid p-0">
              <div className="row">
                <div className="col-xl-12">
                  <div className="filter-main-btn">
                    {/*<span onClick={this.openFilter}*/}
                    {/*      className="filter-btn btn btn-theme"><i*/}
                    {/*    className="fa fa-filter"*/}
                    {/*    aria-hidden="true"></i> Filter</span>*/}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  {this.state.isLoading ? (
                    <span>Searching ...</span>
                  ) : (
                    <span>Found {this.state.count} Hospital(s)</span>
                  )}
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid">
            <div
              className="scrollableDiv row"
              style={{ paddingTop: "10px", height: "fit-content" }}
            >
              {searchResult.length && searchResult.length > 0 ? (
                <InfiniteScroll
                  dataLength={filteredResults.length} //This is important field to render the next data
                  next={this.fetchMoreItems}
                  hasMore={this.state.hasMoreItems}
                  loader={<div className="loading-cls" />}
                  useWindow={false}
                  scrollableTarget="scrollableDiv"
                  scrollThreshold={0.4}
                  endMessage={
                    <p className="seen-cls seen-it-cls">
                      {/*<b>End of Search result</b>*/}
                    </p>
                  }
                >
                  {filteredResults.map((product, index) => {
                    return (
                      <div className={`${"col-lg-12"}`} key={index}>
                        <SearchCheck
                          type="Hospital"
                          data={product}
                          index={index}
                          query={this.state.searchParams}
                          section={"hospital"}
                        />
                        {/*<SearchCheck type="Doctor" data={product}/>*/}
                      </div>
                    );
                  })}
                </InfiniteScroll>
              ) : (
                this.getSkeleton()
              )}

              {!searchResult.length && searchResult.length === 0 ? (
                <div className="row">
                  <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                    <img
                      src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                      className="img-fluid mb-4"
                    />
                    <h3>
                      Sorry! Couldn't find the result you were looking For!!!{" "}
                    </h3>
                    <p>
                      Please check if you have misspelt something or try
                      searching with other words.
                    </p>
                    <Link
                      to={`${process.env.PUBLIC_URL}/`}
                      className="btn btn-solid"
                    >
                      continue
                    </Link>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  // products: getVisibleDoctors(state.data, state.filters),
  // eslint-disable-next-line no-restricted-globals
  // products: getDoctorsForSearchParam(getParams(location.href)),

  suggTreatments: state.data.treatments,
  suggConditions: state.data.conditions,
  suggSpecialisations: state.data.specialisations,
  suggHospitals: state.data.hospitals,
  suggDoctors: state.data.doctors,

  searchString: state.filters.searchString,

  doctors: state.doctors,
  filters: state.filters,
  symbol: state.data.symbol,
});

export default connect(mapStateToProps, {
  addToCart,
  addToWishlist,
  addToCompare,
  getDoctors,
  filterAddCities,
  filterDCity,
  filterAddHospitalCities,
})(withRouter(SearchListing));
