import React from "react";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-feather";

const NewCard2 = () => {
  return (
    <div
      className="card d-flex flex-row p-3 align-items-center"
      style={{
        height: "100%",
        border: "2px solid",
        borderLeft: "40px solid",
        borderColor: "rgb(241, 241, 154)",
      }}
    >
      <img
        src="/assets/images/2.jpg"
        alt="car3"
        height="61px"
        width="61px"
        style={{ marginLeft: "-40px" }}
      />
      <div className="px-3">
        <h4>new Heading</h4>
        <p>hi this is the text</p>
      </div>
      <Link to="/">
        <ArrowRight color="#008cff" />
      </Link>
    </div>
  );
};

export default NewCard2;
