import React, { Component } from "react";
import { Input } from "antd";

class LiveChat extends Component {
  render() {
    // const {
    //   symbol,
    //   item,
    //   addToCartClicked,
    //   BuynowClicked,
    //   addToWishlistClicked,
    // } = this.props;

    return (
      <div
        style={{
          fontSize: "17px",
          letterSpacing: "2px",
          border: "2px solid rgb(238, 238, 238)",

          padding: "20px",
          height: "35%",
        }}
      >
        <h3 style={{ textAlign: "center" }}>Live Chat</h3>
        <h4>Your Name:</h4>
        <Input
          style={{
            marginBottom: "10px",
            borderRadius: "5px",
            boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
          }}
          placeholder="Your Name"
        />
        <h4>Mobile Number:</h4>
        <Input
          style={{
            marginBottom: "10px",
            borderRadius: "5px",
            boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
          }}
          placeholder="Mobile Number"
        />
        <h4>Your Email:</h4>
        <Input
          style={{
            marginBottom: "10px",
            borderRadius: "5px",
            boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
          }}
          placeholder="Email"
        />
        <h4>Your Symptomps:</h4>
        <Input.TextArea
          style={{
            marginBottom: "10px",
            borderRadius: "5px",
            boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
          }}
          placeholder="Symptomps"
        />
        <br />
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
            }}
            className="btn btn-solid w-100"
          >
            Chat Now
          </button>
        </div>
        <hr />
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
            }}
            className="btn btn-solid w-100"
          >
            Get Opinion
          </button>
        </div>
        <div style={{ textAlign: "center" }}>
          <button
            style={{
              borderRadius: "5px",
              boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
            }}
            className="btn btn-solid w-100"
          >
            Tele Consult
          </button>
        </div>
      </div>
    );
  }
}

export default LiveChat;
