import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Text from "antd/lib/typography/Text";
import {Popover} from "antd";
import HospitalSpecialities from "../../products/common/product/hospital-specialitis";

class HospitalSpclType extends Component {


    getHSPType = (item) => {
        let body = "";
        let type = "MultiSpeciality";

        if (!item.type) {
            if (item.specialities && item.specialities.length <= 3 && item.specialities.length > 0) {
                type = "SuperSpeciality"
            } else {
                type = "MultiSpeciality"
            }
        } else {
            type = item.type
        }

        if (type === "SuperSpeciality") {
            return (<div>
                {/*<Popover content={<HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{*/}
                {/*    color: '#9a9a9a',*/}
                {/*    fontSize: '10px',*/}
                {/*    textTransform: 'none',*/}
                {/*    padding: '0.1rem 0.4rem',*/}
                {/*    background: '#ececec',*/}
                {/*}}/>} title="Specialities" placement="bottom" overlayStyle={{maxWidth:'400px'}}>*/}
                <Text style={{fontSize: '12px', fontStyle: 'italic', textDecorationLine: 'underline', textDecorationStyle: 'dashed', cursor: 'pointer'}}>
                    Super-Speciality
                    <HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{
                        color: '#9a9a9a',
                        fontSize: '10px',
                        textTransform: 'none',
                        padding: '0.1rem 0.4rem',
                        background: '#ececec',
                    }}/>
                </Text>
                {/*</Popover>*/}
            </div>)
        } else if (type === "MultiSpeciality") {
            return (<div>
                <Popover content={<HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{
                    color: '#9a9a9a',
                    fontSize: '10px',
                    textTransform: 'none',
                    padding: '0.1rem 0.4rem',
                    background: '#ececec',
                }}/>} title="Specialities" placement="bottom" overlayStyle={{maxWidth: '400px'}}>
                    <Text style={{fontSize: '12px', fontStyle: 'italic', textDecorationLine: 'underline', textDecorationStyle: 'dashed', cursor: 'pointer'}}>
                        Multi-Speciality
                    </Text>
                </Popover>
            </div>)
        } else if (type === "MultiSuperSpeciality") {
            return (<div>
                <Popover content={<HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{
                    color: '#9a9a9a',
                    fontSize: '10px',
                    textTransform: 'none',
                    padding: '0.1rem 0.4rem',
                    background: '#ececec',
                }}/>} title="Specialities" placement="bottom" overlayStyle={{maxWidth: '400px'}}>
                    <Text style={{fontSize: '12px', fontStyle: 'italic', textDecorationLine: 'underline', textDecorationStyle: 'dashed', cursor: 'pointer'}}>
                        Multi-Super-Speciality
                    </Text>
                </Popover>
            </div>)
        }
        return body;
    }

    render() {
        const {item} = this.props;
        return (
            <div>
                {this.getHSPType(item)}
            </div>
        )
    }
}

export default HospitalSpclType;