import React, { Component } from 'react';
import Breadcrumb from "../commonAll/breadcrumb";
import SimpleReactValidator from "simple-react-validator";
import { getCartTotal, getUser } from "../../services";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { decrementQty, incrementQty, removeFromCart } from "../../redux/actions";
import axios from "axios";
import appConfig from "../../config";
import { logout } from "../../redux/actions/authActions";
import Modal from 'react-responsive-modal';
import PatientReports from "./common/patientReports";

class DoctorBookings extends Component {

    constructor(props) {
        super(props);

        this.state = {
            user: getUser(),
            bookings: [],
            open: false,
            patient: {}
        }
        this.validator = new SimpleReactValidator();
    }

    componentDidMount() {
        let user = getUser();
        if (!user) {
            window.open(`${process.env.PUBLIC_URL}/`, '_self');
        } else {
            this.getBookings(user);
        }
    }
   
    printDateTime = (dt) => {
        let x = new Date(dt);
        let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
        return dtStr;
    }

    onOpenModal = (itm) => {
        this.setState({
            open: true,
            patient: itm.user
        });
    };

    onCloseModal = () => {
        this.setState({ open: false });
    };

    getBookings = (user) => {
        console.log("User : ", user);
        var bookings = [];
        let filter = { user: user.user.Doctor.id };
        // console.log("user.user.Doctor.id", user.user.Doctor.id);
        axios
            .get(appConfig.bookingOrder + '?doctor=' + user.user.Doctor.id, {
                headers: {
                    Authorization: 'Bearer ' + user.jwt
                }
            })
            .then(response => {
                // Handle success.
                // console.log('Well done!');
                this.setState({ bookings: response.data });
                // console.log('booking data', response.data);
            })
            .catch(error => {
                // Handle error.
                console.log('An error occurred:', error);
            });
    }

    render() {
        const { cartItems, symbol, total, user } = this.props;
        // console.log("cart item", cartItems);

        const { bookings } = this.state;
        console.log('bookings data', bookings)
        // console.log("DASHBOARD/USER  user : ", user);
        // console.log("DASHBOARD/USER  props : ", this.props);
        // console.log("DASHBOARD/USER  state : ", this.state);
        // console.log("state is ", this.state);

        if (user.isLogedIn && user.user.type === "doctor") {
            console.log("DASHBOARD/USER  user Object : ", user);
        } else {
            // window.open(`${process.env.PUBLIC_URL}/`, "_self");
            // console.error("DASHBOARD/USER  user not allowed on this location : ", user.user.type);
        }


        return (
            <div>
                <Breadcrumb title={'Bookings'} />


                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <div className="account-sidebar">
                                    <a className="popup-btn">
                                        my account
                                    </a>
                                </div>
                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                        <span className="filter-back">
                                            <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                        </span>
                                    </div>
                                    <div className="block-content">
                                        <ul>
                                            <li><a
                                                href={`${process.env.PUBLIC_URL}/pages/doctordashboard`}>Dashboard</a>
                                            </li>
                                            <li className="active"><a
                                                href={`${process.env.PUBLIC_URL}/pages/doctorbookings`}>My Bookings</a>
                                            </li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/doctorprofile`}>Edit Profile</a>
                                            </li>
                                            <li><a href={`${process.env.PUBLIC_URL}/pages/doctorprofileedit`}>Edit Doctor Profile</a>
                                            </li>
                                            {/*<li><a href="#">My Account</a></li>*/}
                                            {/*<li><a href="#">My Wishlist</a></li>*/}
                                            {/*<li><a href="#">Address Book</a></li>*/}
                                            {/*<li><a href="#">Newsletter</a></li>*/}
                                            {/*<li><a href="#">Change Password</a></li>*/}
                                            <li className="last"><a href="#" onClick={this.props.logout}>Log Out</a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        {bookings.length > 0 ?
                                            <section className="cart-section section-b-space">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-06">
                                                            <div className="page-title">
                                                                <h2>My Bookings</h2>
                                                            </div>
                                                            <div className="welcome-msg">
                                                                <p style={{ textTransform: 'capitalize' }}>Hello, {user.user.user.name} !</p>
                                                                <p>Access the appointment by clicking Start Session</p>
                                                            </div>
                                                            <div style={{ padding: '20px' }}><br /></div>
                                                            {/*<h3 style={{marginBottom: '45px', textAlign: 'center'}}>My Bookings </h3>*/}
                                                            <table className="table cart-table"
                                                                style={{ border: '1px solid #ddd' }}>
                                                                <thead>
                                                                    <tr className="table-head">
                                                                        <th scope="col" className='showMid'
                                                                            style={{ paddingTop: '20px' }}>Patient
                                                                        </th>
                                                                        <th scope="col" className='showMid'
                                                                            style={{ paddingTop: '20px' }}>Contact
                                                                        </th>
                                                                        {/*<th scope="col" style={{paddingTop: '20px'}}>Type</th>*/}
                                                                        <th scope="col" style={{ paddingTop: '20px' }} className='showMid'>
                                                                            Type / Time
                                                                        </th>
                                                                        <th scope="col" className='showMid'
                                                                            style={{ paddingTop: '20px' }}>
                                                                            Link
                                                                        </th>
                                                                        {/*<th scope="col"></th>*/}
                                                                    </tr>
                                                                </thead>
                                                                {bookings.map((itm, index) => {
                                                                    let item = itm.Bookings[0];
                                                                    // console.log("item is ", item);
                                                                    return (
                                                                        <tbody key={index}>
                                                                            <tr>
                                                                                <td className='showMid'>
                                                                                    <span
                                                                                        style={{ textTransform: 'capitalize' }}>
                                                                                        {itm.user.name}<br />
                                                                                        {/*<Link*/}
                                                                                        {/*    to={`${process.env.PUBLIC_URL}/conference/${itm.id}`}>*/}
                                                                                        {/*    Start Session*/}
                                                                                        {/*</Link><br />*/}
                                                                                        <span onClick={() => this.onOpenModal(itm)}>Get Info</span>
                                                                                    </span>
                                                                                </td>
                                                                                <td className='showMid'>
                                                                                    <span>
                                                                                        {itm.user.phone}<br />
                                                                                        {itm.user.email}
                                                                                    </span>
                                                                                </td>
                                                                                {/*<td>*/}
                                                                                {/*    <span>{item.BookingType}</span>*/}
                                                                                {/*</td>*/}
                                                                                <td className='showMid'>
                                                                                    <span>{item.BookingType}
                                                                                        <br />{this.printDateTime(item.BookingTime)}</span>

                                                                                </td>
                                                                                <td className='showMid'>
                                                                                    <Link
                                                                                        to={`${process.env.PUBLIC_URL}/conference/${itm.id}`}>
                                                                                        Start Session
                                                                                    </Link><br />
                                                                                    {/*<a href={item.BookingURL + "&isAdmin=1"}*/}
                                                                                    {/*    target="_blank">*/}
                                                                                    {/*    Start Session*/}
                                                                                    {/*</a>*/}
                                                                                </td>
                                                                                {/*<td></td>*/}
                                                                            </tr>
                                                                        </tbody>)
                                                                })}
                                                            </table>
                                                            {/*<table className="table cart-table table-responsive-md">*/}
                                                            {/*    <tfoot>*/}
                                                            {/*    <tr>*/}
                                                            {/*        <td>total price :</td>*/}
                                                            {/*        <td><h2>{symbol} {total} </h2></td>*/}
                                                            {/*    </tr>*/}
                                                            {/*    </tfoot>*/}
                                                            {/*</table>*/}
                                                        </div>
                                                    </div>

                                                </div>
                                            </section>
                                            :
                                            <section className="cart-section section-b-space">
                                                <div className="container">
                                                    <div className="row">
                                                        <div className="col-sm-12">
                                                            <div>
                                                                <div className="col-sm-12 empty-cart-cls text-center">
                                                                    <img
                                                                        src={`${process.env.PUBLIC_URL}/assets/images/icon-empty-cart.png`}
                                                                        className="img-fluid mb-4" alt="" />
                                                                    <h3>
                                                                        <strong>Your Booking List is Empty</strong>
                                                                    </h3>
                                                                    <h4>Chat with us for free. To help you find the most
                                                                        suitable
                                                                        treatment.</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </section>
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
                        <div className="modal-content quick-view-modal">
                            <div className="modal-body">
                                <div className="row">
                                    {/*<div className="col-lg-6  col-xs-12">*/}

                                    {/*</div>*/}
                                    {/*<div className="col-lg-6 rtl-text">*/}

                                    {/*</div>*/}
                                    <PatientReports doctor={user.user.user.Doctor} patient={this.state.patient} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    symbol: state.data.symbol,
    total: getCartTotal(state.cartList.cart),
    user: state.auth,
})

export default connect(
    mapStateToProps,
    { removeFromCart, incrementQty, decrementQty, logout }
)(DoctorBookings)

//export default Dashboard