// let domain = "https://api.html5.run";

// let domain = "http://localhost:1337";
// let domain = "http://ghost.a-i.in:1338";
let domain = "https://hosplanapi.html5.run";
// let domain = "http://localhost:1338";
let cdnDomain = "https://hosplanapi.html5.run";
// let cdnDomain = "https://hosapi.b-cdn.net";


let appConfig = {
    apiUrl: domain,
    loginUrl: domain + "/auth/local",
    bookingOrder: domain + "/booking-orders",
    bookingOrderCreate: domain + "/booking-orders/order",
    register: domain + "/auth/local/register",
    user: domain + 'user',
    users: domain + "/users",
    testReports: domain + "/test-reports",
    prescriptions: domain + "/prescriptions",
    executeBK: domain + "/booking-orders/executebk",

    // checkoutCallback: 'https://hosplan.com/checkout',
    checkoutCallback: 'https://bgd.hosplan.com/checkout',
    // checkoutCallback: 'http://localhost:3000/checkout',
    comments: domain + "/comments",
    upload: domain + "/upload",

    home: cdnDomain + "/home",
    cancer: cdnDomain + "/cancer",
    tests: cdnDomain + "/tests",
    searchHospital: cdnDomain + "/home/srch",
    searchDoctor: cdnDomain + "/home/srcd",
    getHCities: cdnDomain + "/home/gethcities",
    getDCities: cdnDomain + "/home/getdcities",
    hospitalCount: cdnDomain + "/hospitals/count",
    limitedSearch: cdnDomain + "/home/vrntst",
    condition: cdnDomain + "/conditions",
    treatment: cdnDomain + "/treatments",
    specialisation: cdnDomain + "/specialities",
    speciality: cdnDomain + "/specialities",
    superSpeciality: cdnDomain + "/superspecialities",
    SuperSpeciality: cdnDomain + "/superspecialities",
    hospital: cdnDomain + "/hospitals",
    doctor: cdnDomain + "/doctors",
    hospitals: cdnDomain + "/hospitals",
    doctors: cdnDomain + "/doctors",

    doctorPlaceholder : `${process.env.PUBLIC_URL}/assets/images/doctors/Doctor-Male-Placeholder.jpg`,

    imgapi: 'https://api.html5.run/unsafe/',

    locationApi:
        "https://asia-east2-handy-amplifier-259811.cloudfunctions.net/geolocation",

    inr2bdt: 1.16,

    config: {
        // startAudioOnly: true,
        startWithAudioMuted: true,
        debug: false,
        // disable1On1Mode: true,
        doNotStoreRoom: true,
        remoteVideoMenu: {
            disableKick: true,
        },
        disableRemoteMute: true,

        disableDeepLinking: true,

        ///////
        disableThirdPartyRequests: true,
        // Disable Audio Dots
        disableAudioLevels: true,
        enableNoisyMicDetection: false,

        startWithVideoMuted: true,

        // // Disable H.264
        // // disableH264: true,
        // // p2p: {
        // //     disableH264: true,
        // // },
        // // Disables all audio processing
        disableAP: false,
        // // Disables Acoustic Echo Cancellation
        disableAEC: false,
        // // Disables Automatic Gain Control
        disableAGC: false,
        // // Disables Noise Suppression
        disableNS: false,
        // // Disables Highpass Filter
        disableHPF: true,
        ///////
        enableLayerSuspension: false,

        prejoinPageEnabled: false,
        // Limit number of video feeds forwarded
        // channelLastN: 6,
        fileRecordingsEnabled: true,
        liveStreamingEnabled: true,
        hiddenDomain: 'recorder.m2.lauk.in',
        // resolution: 480,
        // constraints: {
        //     video: {
        //         height: {
        //             ideal: 480,
        //             max: 720,
        //             min: 240
        //         }
        //     }
        // },
        // etherpad_base:'https://whiteboard.lauk.in:9001/'
        etherpad_base: 'https://m2.lauk.in/etherpad/p/'
        // etherpad_base:'http://localhost:5001/'
    },

    interfaceConfig: {
        DISABLE_VIDEO_BACKGROUND: true,
        DEFAULT_BACKGROUND: '#e5e3e3',
        // DEFAULT_BACKGROUND: '#e5e3e3',
        DEFAULT_REMOTE_DISPLAY_NAME: 'Guest',
        TOOLBAR_ALWAYS_VISIBLE: true,
        INITIAL_TOOLBAR_TIMEOUT: 36000,
        TOOLBAR_TIMEOUT: 36000,

        DEFAULT_LOGO_URL: 'https://hosplan.com/assets/images/icon/logo.png',
        DEFAULT_WELCOME_PAGE_LOGO_URL: 'https://hosplan.com/assets/images/icon/logo.png',

        TOOLBAR_BUTTONS: [
            'microphone',
            'camera',
            'desktop',
            'fullscreen',
            'fodeviceselection',
            'hangup',
            // 'profile',
            'chat',
            'settings',
            'raisehand',
            'videoquality',
            'filmstrip',
            'tileview',
            // 'download',
            // 'recording',
            // 'livestreaming',
            // 'stats', 'mute-everyone', 'sharedvideo',
            // 'videobackgroundblur',
            // 'etherpad',
        ],

        // DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
        DISABLE_FOCUS_INDICATOR: true,
        DISABLE_DOMINANT_SPEAKER_INDICATOR: true,

        //
        // VERTICAL_FILMSTRIP: false,
        // filmStripOnly: true,
        SET_FILMSTRIP_ENABLED: true,
    },

    docspecial: [
        {value: "Bariatric", label: "Bariatric"},
        {value: "Cardiology", label: "Cardiology"},
        {value: "Dental", label: "Dental"},
        {value: "Division of Urology", label: "Division of Urology"},
        {value: "Gastroentrology", label: "Gastroentrology"},
        {value: "General Surgery", label: "General Surgery"},
        {value: "MBBS MD", label: "MBBS MD"},
        {value: "Nephrology", label: "Nephrology"},
        {value: "Orthopedics", label: "Orthopedics"},
        {value: "Oncology", label: "Oncology"},
        {value: "Neurology", label: "Neurology"},
        {value: "Transplant", label: "Transplant"},
        {value: "Gynaecology", label: "Gynaecology"},
        {value: "ENT", label: "ENT"},
        {value: "Eye", label: "Eye"},
        {value: "IVF", label: "IVF"},
        {value: "Gastroenterology", label: "Gastroenterology"},
        {value: "Infertility", label: "Infertility"},
        {value: "Bariatric Surgeries", label: "Bariatric Surgeries"},
        {value: "Gastric Bypass", label: "Gastric Bypass"},
        {
            value: "Laparoscopic Sleeve Gastrectomy",
            label: "Laparoscopic Sleeve Gastrectomy",
        },
        {
            value: "Laparoscopic Gastric Banding",
            label: "Laparoscopic Gastric Banding",
        },
        {value: "Weight Loss Surgery", label: "Weight Loss Surgery"},
        {value: "Diabetes Surgery", label: "Diabetes Surgery"},
        {value: "DERMATOLOGY", label: "DERMATOLOGY"},
        {value: "ADVANCED LAPAROSCOPY", label: "ADVANCED LAPAROSCOPY"},
        {value: "MAXILLO FACIAL SURGERY", label: "MAXILLO FACIAL SURGERY"},
        {value: "HORMONE CLINIC", label: "HORMONE CLINIC"},
        {value: "DENTAL CLINIC", label: "DENTAL CLINIC"},
        {value: "Laparoscopic Surgery", label: "Laparoscopic Surger"},
        {value: "Gastrornterology", label: "Gastrornterology"},
        {value: "Eye Care", label: "Eye Care"},
        {value: "Ophthalmology", label: "Ophthalmology"},
        {value: "Spine", label: "Spine"},
        {value: "Rehabilitation", label: "Rehabilitation"},
        {value: "Adult Immunisation Clinic", label: "Adult Immunisation Clinic"},
        {value: "Allergy", label: "Allergy"},
        {value: "Anesthesiology", label: "Anesthesiology"},
        {
            value: "Bone Marrow Transplantation",
            label: "Bone Marrow Transplantation",
        },
        {value: "Breast Cancer", label: "Breast Cancer"},
        {value: "Cancer Chemotherapy", label: "Cancer Chemotherapy"},
        {value: "Cancer Radiation Therapy", label: "Cancer Radiation Therapy"},
        {value: "Cancer Surgery", label: "Cancer Surgery"},
        {value: "Cancer Urology", label: "Cancer Urology"},
        {
            value: "Cardiovascular and Thoracic Surgery",
            label: "Cardiovascular and Thoracic Surgery",
        },
        {value: "Clinical Genetics", label: "Clinical Genetic"},
        {value: "Clinical Haematology", label: "Clinical Haematology"},
        {value: "Neonatology", label: "Neonatology"},
    ],

    countries: [
        "Afghanistan",
        "Albania",
        "Algeria",
        "American Samoa",
        "Andorra",
        "Angola",
        "Anguilla",
        "Antarctica",
        "Antigua and Barbuda",
        "Argentina",
        "Armenia",
        "Aruba",
        "Australia",
        "Austria",
        "Azerbaijan",
        "Bahamas",
        "Bahrain",
        "Bangladesh",
        "Barbados",
        "Belarus",
        "Belgium",
        "Belize",
        "Benin",
        "Bermuda",
        "Bhutan",
        "Bolivia",
        "Bosnia and Herzegowina",
        "Botswana",
        "Bouvet Island",
        "Brazil",
        "British Indian Ocean Territory",
        "Brunei Darussalam",
        "Bulgaria",
        "Burkina Faso",
        "Burundi",
        "Cambodia",
        "Cameroon",
        "Canada",
        "Cape Verde",
        "Cayman Islands",
        "Central African Republic",
        "Chad",
        "Chile",
        "China",
        "Christmas Island",
        "Cocos (Keeling) Islands",
        "Colombia",
        "Comoros",
        "Congo",
        "Congo, the Democratic Republic of the",
        "Cook Islands",
        "Costa Rica",
        "Cote d'Ivoire",
        "Croatia (Hrvatska)",
        "Cuba",
        "Cyprus",
        "Czech Republic",
        "Denmark",
        "Djibouti",
        "Dominica",
        "Dominican Republic",
        "East Timor",
        "Ecuador",
        "Egypt",
        "El Salvador",
        "Equatorial Guinea",
        "Eritrea",
        "Estonia",
        "Ethiopia",
        "Falkland Islands (Malvinas)",
        "Faroe Islands",
        "Fiji",
        "Finland",
        "France",
        "France Metropolitan",
        "French Guiana",
        "French Polynesia",
        "French Southern Territories",
        "Gabon",
        "Gambia",
        "Georgia",
        "Germany",
        "Ghana",
        "Gibraltar",
        "Greece",
        "Greenland",
        "Grenada",
        "Guadeloupe",
        "Guam",
        "Guatemala",
        "Guinea",
        "Guinea-Bissau",
        "Guyana",
        "Haiti",
        "Heard and Mc Donald Islands",
        "Holy See (Vatican City State)",
        "Honduras",
        "Hong Kong",
        "Hungary",
        "Iceland",
        "India",
        "Indonesia",
        "Iran (Islamic Republic of)",
        "Iraq",
        "Ireland",
        "Israel",
        "Italy",
        "Jamaica",
        "Japan",
        "Jordan",
        "Kazakhstan",
        "Kenya",
        "Kiribati",
        "Korea, Democratic People's Republic of",
        "Korea, Republic of",
        "Kuwait",
        "Kyrgyzstan",
        "Lao, People's Democratic Republic",
        "Latvia",
        "Lebanon",
        "Lesotho",
        "Liberia",
        "Libyan Arab Jamahiriya",
        "Liechtenstein",
        "Lithuania",
        "Luxembourg",
        "Macau",
        "Macedonia, The Former Yugoslav Republic of",
        "Madagascar",
        "Malawi",
        "Malaysia",
        "Maldives",
        "Mali",
        "Malta",
        "Marshall Islands",
        "Martinique",
        "Mauritania",
        "Mauritius",
        "Mayotte",
        "Mexico",
        "Micronesia, Federated States of",
        "Moldova, Republic of",
        "Monaco",
        "Mongolia",
        "Montserrat",
        "Morocco",
        "Mozambique",
        "Myanmar",
        "Namibia",
        "Nauru",
        "Nepal",
        "Netherlands",
        "Netherlands Antilles",
        "New Caledonia",
        "New Zealand",
        "Nicaragua",
        "Niger",
        "Nigeria",
        "Niue",
        "Norfolk Island",
        "Northern Mariana Islands",
        "Norway",
        "Oman",
        "Pakistan",
        "Palau",
        "Panama",
        "Papua New Guinea",
        "Paraguay",
        "Peru",
        "Philippines",
        "Pitcairn",
        "Poland",
        "Portugal",
        "Puerto Rico",
        "Qatar",
        "Reunion",
        "Romania",
        "Russian Federation",
        "Rwanda",
        "Saint Kitts and Nevis",
        "Saint Lucia",
        "Saint Vincent and the Grenadines",
        "Samoa",
        "San Marino",
        "Sao Tome and Principe",
        "Saudi Arabia",
        "Senegal",
        "Seychelles",
        "Sierra Leone",
        "Singapore",
        "Slovakia (Slovak Republic)",
        "Slovenia",
        "Solomon Islands",
        "Somalia",
        "South Africa",
        "South Georgia and the South Sandwich Islands",
        "Spain",
        "Sri Lanka",
        "St. Helena",
        "St. Pierre and Miquelon",
        "Sudan",
        "Suriname",
        "Svalbard and Jan Mayen Islands",
        "Swaziland",
        "Sweden",
        "Switzerland",
        "Syrian Arab Republic",
        "Taiwan, Province of China",
        "Tajikistan",
        "Tanzania, United Republic of",
        "Thailand",
        "Togo",
        "Tokelau",
        "Tonga",
        "Trinidad and Tobago",
        "Tunisia",
        "Turkey",
        "Turkmenistan",
        "Turks and Caicos Islands",
        "Tuvalu",
        "Uganda",
        "Ukraine",
        "United Arab Emirates",
        "United Kingdom",
        "United States",
        "United States Minor Outlying Islands",
        "Uruguay",
        "Uzbekistan",
        "Vanuatu",
        "Venezuela",
        "Vietnam",
        "Virgin Islands (British)",
        "Virgin Islands (U.S.)",
        "Wallis and Futuna Islands",
        "Western Sahara",
        "Yemen",
        "Yugoslavia",
        "Zambia",
        "Zimbabwe",
    ],
};

export default appConfig;
