import React, {Component} from "react";

import {IntlActions} from "react-redux-multilingual";

import {withRouter} from "react-router-dom";
import moment from "moment";
// Import custom components
import store from "../../../redux/store";

import {Layout, Menu, Rate} from "antd";
import {
    UploadOutlined,
    UserOutlined,
    VideoCameraOutlined,
} from "@ant-design/icons";

import {
    addToCart,
    addToCompare,
    addToWishlist,
    changeCurrency,
    filterSearchString,
    getSuggestionsConditions,
    getSuggestionsDoctors,
    getSuggestionsHospitals,
    getSuggestionsSpeciality,
    getSuggestionsTreatments,
    filterClean,
} from "../../../redux/actions";
import {connect} from "react-redux";
import TopBarDark from "./common/topbar-dark";
import LogoImage from "./common/logo";
import {Input, Select, Skeleton, InputNumber, DatePicker} from "antd";

import {
    faUserMd,
    faHospital,
    faBriefcaseMedical,
    faGlobe,
    faHotel,
    faPlane,
    faHeartbeat,
    faTimes,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
// import Select from "react-select";

import {getParams, getSearchData, storeTest} from "../../../api/apicalls";
// import history from "../../../services/history";

import {getDoctors} from "../../../redux/actions/doctorActions";
import {getSuggestions} from "../../../redux/actions/index";
import Tab from "./header-tabs";
import SubmenuList from "./headerSubmenuList";
import TreatmentList from "./lists/treatmentList";
import HospitalList from "./lists/hospitalList";
import DoctorList from "./lists/doctorList";
import PreventionList from "./lists/preventionList";
import axios from "axios";
import appConfig from "../../../config";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import _ from 'lodash';
import Row from "antd/es/descriptions/Row";

const {Sider} = Layout;

const {Option} = Select;
const components = {
    DropdownIndicator: null,
    //NoOptionsMessage: "Searching ..."
};

function disabledDate(current) {
    // Can not select days before today and today

    return current && current < moment().startOf("day");
}

class HeaderInside extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sliderOpen: true,
            isLoading: false,
            showForm: false,
            searchName: "search",
            tabSelected: "treatment",
            selectedDoctorOption: [],
            selectedHospitalOption: [],
            selectedSpecialOption: [],
            suggestionsDoc: [],
            suggestionsHos: [],
            conditionpcl: [],
            treatmentSpcl: [],
            hospitalSpcl: [],
            doctorSpcl: [],
            // suggestionsSpcl: appConfig.docspecial,
            suggestionsSpcl: [],
            children: [],
            menu: 1,
            menuVisible: false,
            defaultTreatment: "",
            defaultCondition: "",
            defaultSpecialisation: "",
            defaultHospital: "",
            defaultDoctor: "",

            showPromo:false,

            medicalVisaData: {
                CountryofOrigin: "",
                DestinationCountry: "",
                DestinationHospital: "",
                TravelDate: "",
                NoofAttendents: "",
            },
            airAmbulanceData: {
                Country: "",
                City: "",
                Destination: "",
                Hospital: "",
                Date: "",
            },
        };
    }

    getHospitalList() {
        // let param = "/" + id;

        axios.get(appConfig.hospitals).then((response) => {
            // console.log("response", response.data);
            let hospitalList = [];
            hospitalList.push(
                <option value="" key="please" disabled>
                    Please Select
                </option>
            );
            response.data.forEach((element) => {
                // console.log("Element is : ", element);
                hospitalList.push(
                    <option value={element.name} key={element.id}>
                        {element.Name}
                    </option>
                );
            });
            this.setState({hospitalList});
        });
    }

    /*=====================
               Pre loader
          ==========================*/
    componentDidMount() {
        // setTimeout(function() {
        //     document.querySelector(".loader-wrapper").style = "display: none";
        // }, 2000);

        this.getFinderData();
        this.getSearchParam();
        let href = window.location.href;
        let result = href.split("/");
        let Param = result[result.length - 2];

        if (Param === "search") {
            this.setState({tabSelected: "treatment"});
            localStorage.removeItem("state");
        }
        if (Param === "hos") {
            this.setState({tabSelected: "hospital"});
            localStorage.removeItem("state");
        }
        if (Param === "doct") {
            this.setState({tabSelected: "doctor"});
            localStorage.removeItem("state");
        }
        if (Param === "medicalvisa") {
            this.setState({tabSelected: "medicalvisa"});

            localStorage.removeItem("state");
        }

        if (Param === "accomodation") {
            this.setState({tabSelected: "accomodation"});
            localStorage.removeItem("state");
        }
        if (Param === "airambulance") {
            this.setState({tabSelected: "airambulance"});
            localStorage.removeItem("state");
        }

        // TODO: Remove these line as it is just for testing
        // let str = storeTest();
        // console.log("STORE:: ", str);

        // this.getHospitalList();
        // eslint-disable-next-line no-restricted-globals
        let url = location.href.split('/');
        if (url[3] === "hospital") {
            console.log("I am searching in  hospital")
            this.setState({tabSelected: 'hospital'})
        }
        if (url[3] === "doctor") {
            console.log("I am searching in  hospital")
            this.setState({tabSelected: 'doctor'})
        }
        console.log("Header route is : ", url);
        if (url[3] === "") {
            this.setState({showPromo: true})
        }else{
            this.setState({showPromo: false})
        }

    }

    componentWillMount() {
        window.addEventListener("scroll", this.handleScroll);
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchString !== this.props.searchString) {
            // console.log("HEADER :: searchString Updated");
            this.setState(
                {
                    menuVisible: false,
                },
                this.getSearchParam
            );
            // this.getSearchParam();
        }
    }

    //new code
    handleTab = (tabSelected, search) => {
        // console.log("tabSelected", tabSelected);
        this.setState({
            tabSelected: tabSelected,
            searchName: search,
        });
    };
    handleTab1 = (tabSelected, search) => {
        // console.log("tabSelected", tabSelected);
        this.setState({
            tabSelected: tabSelected,
            searchName: search,
            showForm: true,
        });
    };

    handleInputFirst = (event) => {
        if (event.target.value === "") {
            document.getElementById(event.target.name).style.display = "inline";
            this.setState({
                medicalVisaData: {
                    ...this.state.medicalVisaData,
                    [event.target.name]: event.target.value,
                },
            });
        } else {
            document.getElementById(event.target.name).style.display = "none";
            this.setState({
                medicalVisaData: {
                    ...this.state.medicalVisaData,
                    [event.target.name]: event.target.value,
                },
            });
        }
        this.medicalVisaHandler(event);
        //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
    };

    handleAirAmbulanceInputFirst = (event) => {
        if (event.target.value === "") {
            document.getElementById(event.target.name).style.display = "inline";
            this.setState({
                medicalVisaData: {
                    ...this.state.medicalVisaData,
                    [event.target.name]: event.target.value,
                },
            });
        } else {
            document.getElementById(event.target.name).style.display = "none";
            this.setState({
                medicalVisaData: {
                    ...this.state.medicalVisaData,
                    [event.target.name]: event.target.value,
                },
            });
        }
        this.airAmbulanceHandler(event);
        //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
    };

    handleTravelDate = (date, dateString) => {
        // console.log(date, dateString);
        this.setState({
            medicalVisaData: {
                ...this.state.medicalVisaData,
                TravelDate: dateString,
            },
        });
    };

    handleAirAmbulanceDate = (date, dateString) => {
        // console.log(date, dateString);
        this.setState({
            airAmbulanceData: {...this.state.airAmbulanceData, Date: dateString},
        });
    };

    handleInput = (event) => {
        if (event.target.value === "") {
            document.getElementById(event.target.name).style.display = "inline";
            this.setState({
                medicalVisaData: {
                    ...this.state.medicalVisaData,
                    [event.target.name]: event.target.value,
                },
            });
        } else {
            document.getElementById(event.target.name).style.display = "none";
            this.setState({
                medicalVisaData: {
                    ...this.state.medicalVisaData,
                    [event.target.name]: event.target.value,
                },
            });
        }

        //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
    };

    handleAirAmbulanceInput = (event) => {
        if (event.target.value === "") {
            document.getElementById(event.target.name).style.display = "inline";
            this.setState({
                airAmbulanceData: {
                    ...this.state.airAmbulanceData,
                    [event.target.name]: event.target.value,
                },
            });
        } else {
            document.getElementById(event.target.name).style.display = "none";
            this.setState({
                airAmbulanceData: {
                    ...this.state.airAmbulanceData,
                    [event.target.name]: event.target.value,
                },
            });
        }

        //  console.log("med-visa",document.getElementById('med-visa').childNodes[0].childNodes[1].value);
    };

    medicalVisaHandler = (tab) => {
        let data = this.state.medicalVisaData;
        let flag = true;

        for (let x in data) {
            if (data[x] === "") {
                flag = false;
                break;
            }
        }

        if (flag) {
            console.log("we Are Ready to Go to Next Page with Data", data);
            let stringifyData = JSON.stringify(data);
            let path = "/medicalvisa/city?data=" + stringifyData;
            this.props.history.push(path);
        }
    };

    airAmbulanceHandler = (tab) => {
        let data = this.state.airAmbulanceData;
        let flag = true;

        for (let x in data) {
            if (data[x] === "") {
                flag = false;
                break;
            }
        }

        if (flag) {
            console.log("we Are Ready to Go to Next Page with Data", data);
            let stringifyData = JSON.stringify(data);
            let path = "/airambulance/city?data=" + stringifyData;
            this.props.history.push(path);
        }

    };

    getSearchParam = () => {
        // console.log("SEARCH :: Get Search Header Parameter : ")
        // eslint-disable-next-line no-restricted-globals
        let url = location.href;

        let params = getParams(url);
        // console.log(url, params);
        // console.log("SEARCH :: Get Search Header Parameter : ", params)
        if (params["Treatment"]) {
            this.setState({
                // defaultTreatment: params['Treatment'].replace('-', ' ')
                defaultTreatment: params["Treatment"].replaceAll("-", " "),
                // menuVisible: true,
            });
        }
        if (params["Condition"]) {
            this.setState({
                defaultCondition: params["Condition"].replaceAll("-", " "),
                // menuVisible: true,
            });
        }
        if (params["Speciality"]) {
            this.setState({
                defaultSpecialisation: params["Speciality"].replaceAll("-", " "),
                // menuVisible: true,
            });
        }
        if (params["Hospital"]) {
            this.setState({
                defaultHospital: params["Hospital"].replaceAll("-", " "),
                // menuVisible: true,
            });
        }
        if (params["Doctor"]) {
            this.setState({
                defaultDoctor: params["Doctor"].replaceAll("-", " "),
                // menuVisible: true,
            });
        }
        this.setState({
            menuVisible: true,
        });
    };

    getFinderData = () => {
        if (!this.props.suggTreatments || !this.props.suggTreatments.length)
            this.props.getSuggestionsTreatments();

        if (!this.props.suggConditions || !this.props.suggConditions.length)
            this.props.getSuggestionsConditions();

        if (
            !this.props.suggSpecialisations ||
            !this.props.suggSpecialisations.length
        )
            this.props.getSuggestionsSpeciality();

        if (!this.props.suggHospitals || !this.props.suggHospitals.length)
            this.props.getSuggestionsHospitals();

        if (!this.props.suggDoctors || !this.props.suggDoctors.length)
            this.props.getSuggestionsDoctors();
    };

    handleScroll = () => {
        let number =
            window.pageXOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            0;
        // console.log("the Number is ", number);
        if (number >= 300) {
            if (window.innerWidth < 576) {
                document.getElementById("sticky").classList.remove("hidden");
                document.getElementById("sticky").classList.add("fixed");
            } else document.getElementById("sticky").classList.add("fixed");

            document.getElementById("sticky").classList.remove("hidden");
        } else {
            document.getElementById("sticky").classList.remove("fixed");

            document.getElementById("sticky").classList.add("hidden");
        }
        if (number >= 150) {
            document.querySelector(".mobile-menu").classList.add("fixed");
        } else {
            document.querySelector(".mobile-menu").classList.remove("fixed");
        }
    };

    handleSpeclChange = (selectedSpecialOption, item, type) => {
        console.log(
            "SEARCH :: Header Param recived : ",
            selectedSpecialOption,
            item,
            type
        );
        this.setDefaultState(type, (selectedSpecialOption && selectedSpecialOption.value) ? selectedSpecialOption.value : selectedSpecialOption, item);
    };

    setDefaultState = (cat, value, item) => {
        console.log("HEADER :: setDefaultState : ", cat, value)
        if (cat === "Treatment") {
            this.setState(
                {
                    // defaultTreatment: params['Treatment'].replace('-', ' ')
                    defaultTreatment: value ? value : "",
                    // menuVisible: true,
                },
                () => this.makeSearchURL(value, item, cat)
            );
        }
        if (cat === "Condition") {
            this.setState(
                {
                    defaultCondition: value ? value : "",
                    // menuVisible: true,
                },
                () => this.makeSearchURL(value, item, cat)
            );
        }
        if (cat === "Speciality") {
            this.setState(
                {
                    defaultSpecialisation: value ? value : "",
                    // menuVisible: true,
                },
                () => this.makeSearchURL(value, item, cat)
            );
        }
        if (cat === "Hospital") {
            console.log("REDIRECT TO HOSPITAL ", value, item, cat);
            // eslint-disable-next-line no-restricted-globals
            let url = location.href.split('/');


            let path = `${process.env.PUBLIC_URL}/hospital/${value}`;

            // eslint-disable-next-line no-restricted-globals
            if (location.href.indexOf('?') >= 0) {
                if (url[4]) {
                    let urd = url[4].split('?')
                    let postfix = urd[urd.length - 1]
                    if (postfix) {
                        path += "?" + postfix
                    }
                }
            }


            console.log("path is ", path)
            // eslint-disable-next-line no-restricted-globals
            location.href = path;

            // this.props.history.push(path);
            // this.props.history.push(path);


            // this.setState(
            //     {
            //         defaultHospital: value ? value : "",
            //         // menuVisible: true,
            //     },
            //     () => this.makeSearchURL(value, item, cat)
            // );
        }
        if (cat === "Doctor") {
            console.log("REDIRECT TO HOSPITAL ", value, item, cat);
            let path = `${process.env.PUBLIC_URL}/doctor/${value}`;
            // eslint-disable-next-line no-restricted-globals
            location.href = path;
            // this.setState(
            //     {
            //         defaultDoctor: value ? value : "",
            //         // menuVisible: true,
            //     },
            //     () => this.makeSearchURL(value, item, cat)
            // );
        }
        this.setState({
            menuVisible: true,
        });
    };

    //             defaultTreatment: '',
    //             defaultCondition: '',
    //             defaultSpecialisation: '',
    //             defaultHospital: '',
    //             defaultDoctor: '',

    makeSearchURL = (selectedSpecialOption, item, type) => {
        console.log(
            "SEARCH :: Header Param recived : ",
            selectedSpecialOption,
            item,
            type
        );
        // this.setDefaultState(type, selectedSpecialOption);
        // if (selectedSpecialOption && type) {
        let search = this.state.searchName;
        let pathPrefix = `${search}/india`;

        let path = `${process.env.PUBLIC_URL}/`;

        // eslint-disable-next-line no-restricted-globals
        let url = location.href.split('/');
        if (url[3] === "hospital") {
            console.log("I am searching in  hospital")
            pathPrefix = "hospital/" + url[4].split('?')[0];
            this.setState({tabSelected: 'hospital'})
        }


        let srcObj = {};
        let str = "";

        let autoSpcl = false;

        // if (this.state.defaultTreatment) {
        if (type === 'Treatment') {
            str = "?Treatment=" + this.state.defaultTreatment.replace(/\s+/g, "-");
            let spl = _.find(this.props.suggTreatments, {'value': this.state.defaultTreatment})
            console.log("the search treatment is : ", spl);
            if (spl && spl.speciality) {
                str += "&Speciality=" + spl.speciality;
                autoSpcl = true;
            }
        }
        // if (this.state.defaultCondition) {
        if (type === 'Condition') {
            str = str.length
                ? str + "&Condition=" + this.state.defaultCondition.replace(/\s+/g, "-")
                : "?Condition=" + this.state.defaultCondition.replace(/\s+/g, "-");

            let spl = _.find(this.props.suggConditions, {'value': this.state.defaultCondition})
            console.log("the search condition is : ", spl);
            if (spl && spl.speciality) {
                str += "&Speciality=" + spl.speciality;
                autoSpcl = true;
            }
        }
        // if (this.state.defaultSpecialisation && !autoSpcl){
        if (type === 'Speciality' && !autoSpcl) {
            str = str.length
                ? str +
                "&Speciality=" +
                this.state.defaultSpecialisation.replace(/\s+/g, "-")
                : "?Speciality=" +
                this.state.defaultSpecialisation.replace(/\s+/g, "-");
        }
        // if (this.state.defaultHospital) {
        if (type === 'Hospital') {
            str = str.length
                ? str + "&Hospital=" + this.state.defaultHospital.replace(/\s+/g, "-")
                : "?Hospital=" + this.state.defaultHospital.replace(/\s+/g, "-");
        }
        // if (this.state.defaultDoctor) {
        if (type === 'Doctor') {
            str = str.length
                ? str + "&Doctor=" + this.state.defaultDoctor.replace(/\s+/g, "-")
                : "?Doctor=" + this.state.defaultDoctor.replace(/\s+/g, "-");
        }
        // this.setState(
        //     {selectedSpecialOption},
        //     () => console.log(`Option selected:`, this.state.selectedSpecialOption)
        // );

        this.setState({
            param: {},
        });

        // let str = selectedSpecialOption.replace(/\s+/g, '-')
        // let param = type + "=" + str;

        path = `${path}${pathPrefix}${str}`;
        console.log("SEARCH :: URL Path ", path);
        console.log("what is this path", path);

        this.props.filterSearchString(path);


        window.location.href = path;

    };

    changeLanguage(lang) {
        store.dispatch(IntlActions.setLocale(lang));
    }

    openNav() {
        var openmyslide = document.getElementById("mySidenav");
        if (openmyslide) {
            openmyslide.classList.add("open-side");
        }
    }

    openSearch() {
        document.getElementById("search-overlay").style.display = "block";
    }

    closeSearch() {
        document.getElementById("search-overlay").style.display = "none";
    }

    load = () => {
        this.setState({isLoading: true});
        fetch().then(() => {
            // deal with data fetched
            this.setState({isLoading: false});
        });
    };

    render() {
        // console.log("hospitalList", this.state.hospitalList);
        // console.log("this.state.medicalVisaData", this.state.medicalVisaData);
        // console.log("this.state.airAmbulanceData", this.state.airAmbulanceData);
        const countries = appConfig.countries;

        // console.log(countries);

        const countryOption = [];
        countries.forEach((element) => {
            countryOption.push(
                <option
                    value={element}
                    key={element}
                    disabled={
                        this.state.medicalVisaData.CountryofOrigin === element ||
                        this.state.medicalVisaData.DestinationCountry === element
                    }
                >
                    {element}
                </option>
            );
        });
        const {
            selectedDoctorOption,
            suggestionsDoc,
            tabSelected,
            defaultCondition,
            defaultDoctor,
            defaultHospital,
            defaultSpecialisation,
            defaultTreatment,
        } = this.state;

        window.headerProps = this.props;
        window.headerState = this.state;

        // console.log("SEARCH :: defaultTreatment : ", this.state.defaultTreatment)
        return (
            <div>
                <header id="minor" className="minor">


                    <TopBarDark
                        tabSelected={tabSelected}
                        handleTab={this.handleTab}
                        handleSpeclChange={this.handleSpeclChange}
                        defaultTreatment={defaultTreatment}
                        defaultHospital={defaultHospital}
                        defaultDoctor={defaultDoctor}
                        defaultCondition={defaultCondition}
                        defaultSpecialisation={defaultSpecialisation}
                    />
                    <div className="mobile-menu" style={{
                        width: '95%',
                        /* align-items: center; */
                        /* position: relative; */
                        /* width: 750px; */
                        // margin: '10px auto 10px auto',
                        // margin:'10px',
                        boxShadow: '0px 4px 8px 4px rgb(0 0 0 / 15%)',
                        borderRadius: '10px',
                        backgroundColor: 'white',
                        paddingRight: '20px',
                        textAlign: 'center',
                        paddingLeft: '30px',

                        marginTop: '10px',
                        marginBottom: '10px',
                        marginRight: '2.5%',
                        marginLeft: '2.5%',
                    }}>
                        {this.state.showForm ? (
                            <div className="showForm">
                                <div className="showForm__header">
                                    <FontAwesomeIcon
                                        icon={faArrowLeft}
                                        onClick={() => {
                                            this.setState({
                                                showForm: false,
                                            });
                                        }}
                                    />
                                    <span>{this.state.tabSelected} </span>
                                </div>
                                <ul
                                    className="ul"
                                    style={{
                                        display: `${tabSelected === "treatment" ? "flex" : "none"}`,
                                        flexDirection: "column",
                                    }}
                                >
                                    <TreatmentList
                                        treatment={defaultTreatment}
                                        handleSpeclChange={this.handleSpeclChange}
                                        condition={defaultCondition}
                                        specialisation={defaultSpecialisation}
                                        hospital={defaultHospital}
                                        doctor={defaultDoctor}
                                    />
                                </ul>

                                <ul
                                    className="ul"
                                    style={{
                                        display: `${tabSelected === "hospital" ? "flex" : "none"}`,
                                        flexDirection: "column",
                                    }}
                                >
                                    <HospitalList
                                        treatment={defaultTreatment}
                                        handleSpeclChange={this.handleSpeclChange}
                                        condition={defaultCondition}
                                        specialisation={defaultSpecialisation}
                                        hospital={defaultHospital}
                                    />
                                </ul>

                                <ul
                                    className="ul"
                                    style={{
                                        display: `${tabSelected === "doctor" ? "flex" : "none"}`,
                                        flexDirection: "column",
                                    }}
                                >
                                    <DoctorList
                                        treatment={defaultTreatment}
                                        handleSpeclChange={this.handleSpeclChange}
                                        condition={defaultCondition}
                                        specialisation={defaultSpecialisation}
                                        hospital={defaultHospital}
                                        doctor={defaultDoctor}
                                    />
                                </ul>

                                <ul
                                    className="ul"
                                    onClick={() => {
                                        this.state.medicalVisaData.DestinationCountry === ""
                                            ? (document.getElementById(
                                                "DestinationCountry"
                                            ).style.display = "inline")
                                            : (document.getElementById(
                                                "DestinationCountry"
                                            ).style.display = "none");

                                        this.state.medicalVisaData.DestinationHospital === ""
                                            ? (document.getElementById(
                                                "DestinationHospital"
                                            ).style.display = "inline")
                                            : (document.getElementById(
                                                "DestinationHospital"
                                            ).style.display = "none");

                                        this.state.medicalVisaData.TravelDate === ""
                                            ? (document.getElementById("TravelDate").style.display =
                                                "inline")
                                            : (document.getElementById("TravelDate").style.display =
                                                "none");

                                        this.state.medicalVisaData.NoofAttendents === ""
                                            ? (document.getElementById(
                                                "NoofAttendents"
                                            ).style.display = "inline")
                                            : (document.getElementById(
                                                "NoofAttendents"
                                            ).style.display = "none");
                                    }}
                                    style={{
                                        display: `${
                                            tabSelected === "medicalvisa" ? "flex" : "none"
                                        }`,
                                        flexDirection: "column",
                                    }}
                                >
                                    <li className="li-list">
                                        <h4>
                                            Country of Origin
                                            <img
                                                id="CountryofOrigin"
                                                style={{display: "none"}}
                                                src="/assets/images/danger-icon.png"
                                                alt=""
                                                width="20"
                                                height="20"
                                            />
                                        </h4>
                                        {/* <Input
                                                style={{ border: "0" }}
                                                name="CountryofOrigin"
                                                onBlur={this.handleInputFirst}
                                                onChange={this.handleInput}
                                                value={this.state.medicalVisaData.CountryofOrigin}
                                                placeholder="Country of Origin" /> */}
                                        <select
                                            name="CountryofOrigin"
                                            onBlur={this.handleInputFirst}
                                            onChange={this.handleInput}
                                            value={this.state.medicalVisaData.CountryofOrigin}
                                            style={{
                                                paddingLeft: "20px",
                                                border: "none",
                                                width: "100%",
                                            }}
                                        >
                                            <option value="" key="please" disabled>
                                                Please Select
                                            </option>
                                            {countryOption}
                                        </select>
                                    </li>
                                    <li className="li-list">
                                        <h4>
                                            Destination Country
                                            <img
                                                id="DestinationCountry"
                                                style={{display: "none"}}
                                                src="/assets/images/danger-icon.png"
                                                alt=""
                                                width="20"
                                                height="20"
                                            />
                                        </h4>
                                        {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationCountry"
                                                value={this.state.medicalVisaData.DestinationCountry}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Country" /> */}
                                        <select
                                            name="DestinationCountry"
                                            onBlur={this.handleInputFirst}
                                            onChange={this.handleInput}
                                            value={this.state.medicalVisaData.DestinationCountry}
                                            style={{
                                                paddingLeft: "20px",
                                                border: "none",
                                                width: "100%",
                                            }}
                                        >
                                            <option value="" key="please" disabled>
                                                Please Select
                                            </option>
                                            {countryOption}
                                        </select>
                                    </li>

                                    <li className="li-list">
                                        <h4>
                                            Destination Hospital
                                            <img
                                                id="DestinationHospital"
                                                style={{display: "none"}}
                                                src="/assets/images/danger-icon.png"
                                                alt=""
                                                width="20"
                                                height="20"
                                            />
                                        </h4>
                                        {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationHospital"
                                                value={this.state.medicalVisaData.DestinationHospital}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Hospital" /> */}

                                        <select
                                            name="DestinationHospital"
                                            onBlur={this.handleInputFirst}
                                            onChange={this.handleInput}
                                            value={this.state.medicalVisaData.DestinationHospital}
                                            style={{
                                                paddingLeft: "20px",
                                                border: "none",
                                                width: "100%",
                                            }}
                                        >
                                            {this.state.hospitalList}
                                        </select>
                                    </li>
                                    <li className="li-list">
                                        <h4>
                                            Travel Date
                                            <img
                                                id="TravelDate"
                                                style={{display: "none"}}
                                                src="/assets/images/danger-icon.png"
                                                alt=""
                                                width="20"
                                                height="20"
                                            />
                                        </h4>
                                        <DatePicker
                                            style={{border: "0"}}
                                            disabledDate={disabledDate}
                                            name="TravelDate"
                                            // value={this.state.medicalVisaData.TravelDate}
                                            onBlur={this.medicalVisaHandler}
                                            onChange={this.handleTravelDate}
                                        />
                                        {/* <Input
                                            style={{border: "0"}} 
                                            name="TravelDate"
                                            onChange={this.handleInput}
                                            onBlur={this.medicalVisaHandler}
                                            placeholder="Travel Date"/> */}
                                    </li>
                                    <li className="li-list">
                                        <h4>
                                            No. of Attendents
                                            <img
                                                id="NoofAttendents"
                                                style={{display: "none"}}
                                                src="/assets/images/danger-icon.png"
                                                alt=""
                                                width="20"
                                                height="20"
                                            />
                                        </h4>
                                        <Input
                                            style={{border: "0"}}
                                            name="NoofAttendents"
                                            value={this.state.medicalVisaData.NoofAttendents}
                                            onChange={this.handleInput}
                                            onBlur={this.medicalVisaHandler}
                                            placeholder="No. of Attendents"
                                        />
                                    </li>
                                </ul>

                                <ul
                                    className="ul"
                                    style={{
                                        display: `${
                                            tabSelected === "accomodation" ? "flex" : "none"
                                        }`,
                                        flexDirection: "column",
                                    }}
                                >
                                    <li className="li-list">
                                        <h4>City</h4>
                                        <Input style={{border: "none"}} placeholder="Condition"/>
                                    </li>
                                    <li className="li-list">
                                        <h4>Hospital</h4>
                                        <Input style={{border: "none"}} placeholder="Condition"/>
                                    </li>
                                    <li className="li-list">
                                        <h4>Espected Days to Stay</h4>
                                        <Input style={{border: "none"}} placeholder="Condition"/>
                                    </li>
                                    <li className="li-list">
                                        <h4>Travel Date</h4>
                                        <Input style={{border: "none"}} placeholder="Condition"/>
                                    </li>
                                    <li className="li-list">
                                        <h4>No. Attendents</h4>
                                        <Input style={{border: "none"}} placeholder="Condition"/>
                                    </li>
                                </ul>
                            </div>
                        ) : (
                            <ul
                                className="ul"
                                style={{
                                    display: "flex",
                                    justifyContent: "space-around",
                                    width: "100%",
                                    flexWrap: "wrap",
                                }}
                            >
                                <Tab
                                    handleTab={this.handleTab1}
                                    icon={faUserMd}
                                    name="treatment"
                                    tabName="TREATMENT"
                                    tabSelected={tabSelected}
                                    searchName="search"
                                    iconImage={'Symptoms.png'}
                                />

                                <Tab
                                    handleTab={this.handleTab1}
                                    icon={faHospital}
                                    name="hospital"
                                    tabName="HOSPITAL"
                                    tabSelected={tabSelected}
                                    searchName="hos"
                                    iconImage={'Hospital.png'}
                                />

                                <Tab
                                    handleTab={this.handleTab1}
                                    icon={faBriefcaseMedical}
                                    name="doctor"
                                    tabName="DOCTOR"
                                    tabSelected={tabSelected}
                                    searchName="doct"
                                    iconImage={'Doctor.png'}
                                />

                                <Tab
                                    handleTab={this.handleTab1}
                                    icon={faGlobe}
                                    name="medicalvisa"
                                    tabName="MEDICAL VISA"
                                    tabSelected={tabSelected}
                                    searchName="visa"
                                    iconImage={'IDCard.png'}
                                />
                            </ul>
                        )}
                    </div>
                    <section className="hide-me">
                        {this.state.menuVisible ? (
                            <section className="header-new">

                                <div style={{
                                    height: '300px',
                                    display: this.state.showPromo?'block':'none'
                                }}>
                                    <div className='row'>
                                        <div style={{
                                            // margin:'auto',
                                            color: '#fff',
                                            fontSize: '4em',
                                            display: 'flex',
                                            width: '675px',
                                            /* margin: 0 auto; */
                                            top: '23%',
                                            // position: "relative",

                                            // z-index: 11;
                                            // display: "-webkit-flex",
                                            // display: "flex",
                                            // -webkit-justify-content: space-between;
                                            justifyContent: "space-between",
                                            // -webkit-align-items: center;
                                            alignItems: "center",
                                            position: "relative",
                                            // width: 750px;
                                            margin: "10px auto 0px auto",
                                            // box-shadow: 0px 4px 8px 4px rgb(0 0 0 / 15%);
                                            // border-radius: 10px;
                                            // background-color: white;
                                            // padding-right: 30px;
                                            textAlign: "center",
                                            // padding-left: 30px;
                                            fontFamily: "Avenir, MarkOT, Arial, Helvetica, sans-serif",
                                            fontWeight: '500',
                                        }}>
                                            {/*Bring a positive change to HealthCare*/}
                                            A Wonderful start <br/>to your HealthCare needs
                                        </div>
                                    </div>

                                    <div className='row d-flex justify-content-center' style={{width: '900px', margin:'10px auto'}}>
                                        <div className='row'>
                                            <div className='col' style={{width:'200px', margin:'10px'}}>
                                                <div className='row d-flex justify-content-center'>
                                                    <svg className="illustrated-icon-mo_illustrated-icon__icon-3zdhB" fill="none" width="1.15em" height=".85em" viewBox="0 0 100 44"
                                                         aria-hidden="true" style={{width:'80px', height:'60px'}}>
                                                        <path
                                                            d="M49.77 15.75a7.09 7.09 0 100-14.18 7.09 7.09 0 000 14.18zM30.45 43.983c0-13.15 8.65-21.78 19.32-21.78s19.32 8.63 19.32 21.78m9.098-28.233a7.09 7.09 0 100-14.18 7.09 7.09 0 000 14.18zm-9.817 9.213a18.49 18.49 0 019.82-2.76c10.67 0 19.32 8.63 19.32 21.78M21.348 15.75a7.09 7.09 0 100-14.18 7.09 7.09 0 000 14.18zm9.792 9.213a18.49 18.49 0 00-9.82-2.76C10.65 22.203 2 30.833 2 43.983M56.969 24.04v6.539M42.898 24.04v6.539"
                                                            stroke="#ffffff" strokeLinecap="round" strokeWidth="3"></path>
                                                        <path d="M56.97 35.574a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z" fill="#ffffff"></path>
                                                        <path d="M38.77 38.238a3.91 3.91 0 017.82 0M27.828 24.04v6.539m-14.07-6.539v6.539" stroke="#ffffff" strokeLinecap="round"
                                                              strokeWidth="3"></path>
                                                        <path d="M27.83 35.574a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z" fill="#ffffff"></path>
                                                        <path d="M9.93 38.238a3.91 3.91 0 117.82 0M86.098 24.04v6.539M72.04 24.04v6.539" stroke="#ffffff" strokeLinecap="round"
                                                              strokeWidth="3"></path>
                                                        <path d="M86.1 35.574a1.24 1.24 0 100-2.48 1.24 1.24 0 000 2.48z" fill="#ffffff"></path>
                                                        <path d="M71.82 34.328a3.91 3.91 0 013.91 3.91" stroke="#ffffff" strokeLinecap="round" strokeWidth="3"></path>
                                                    </svg>
                                                </div>
                                                <div className='row'>
                                                    <p style={{textAlign: "center", color: "#fff", fontFamily: "Avenir, MarkOT, Arial, Helvetica, sans-serif",
                                                        width:'100%',
                                                        fontWeight: '500',}}>
                                                        Profiles of Select <br/>10000+ Specialists
                                                    </p>
                                                </div>

                                            </div>
                                            <div className='col' style={{width:'200px',margin:'10px'}}>
                                                <div className='row d-flex justify-content-center'>
                                                    <svg className="illustrated-icon-mo_illustrated-icon__icon-3zdhB" fill="none" width="1em" height=".85em" viewBox="0 0 65 68"
                                                         aria-hidden="true" style={{width:'80px', height:'60px'}}>
                                                        <path
                                                            d="M29.729 28.469a6.67 6.67 0 100-13.34 6.67 6.67 0 000 13.34zM47.33 49.7c-2-9.32-9.09-15.18-17.6-15.18-8.51 0-15.66 5.88-17.62 15.23m24.378-13.492v6.13m-13.218-6.13v6.13"
                                                            stroke="#ffffff" strokeLinecap="round" strokeWidth="3"></path>
                                                        <path d="M36.488 44.758a1.16 1.16 0 101.16 1.16 1.17 1.17 0 00-1.16-1.16z" fill="#ffffff"></path>
                                                        <path d="M19.387 49.588a3.67 3.67 0 017.34 0m26.8 5.42l9.04 10.53M38.949 3.2a27.64 27.64 0 11-9.82-1.61" stroke="#ffffff"
                                                              strokeLinecap="round" strokeWidth="3"></path>
                                                    </svg>
                                                </div>
                                                <div className='row'><p style={{textAlign: "center", color: "#fff", fontFamily: "Avenir, MarkOT, Arial, Helvetica, sans-serif",
                                                    fontWeight: '500',}}>Search By Condition, Treatment, Doctor, Hospital</p></div>
                                            </div>
                                            <div className='col' style={{width:'200px',margin:'10px'}}>
                                                <div className='row d-flex justify-content-center'>
                                                    <Rate
                                                        disabled
                                                        allowHalf
                                                        value={5}
                                                        style={{width:'120px', height:'60px'}}
                                                    />
                                                </div>
                                                <div className='row'><p style={{textAlign: "center", color: "#fff", fontFamily: "Avenir, MarkOT, Arial, Helvetica, sans-serif",
                                                    fontWeight: '500',}}>Select by Ratings, Experience, Fee and more</p></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="main-menu">
                                    <ul
                                        className="ul"
                                        style={{
                                            display: "flex",
                                        }}
                                    >
                                        <Tab
                                            handleTab={this.handleTab}
                                            icon={faUserMd}
                                            name="treatment"
                                            tabName="TREATMENT"
                                            tabSelected={tabSelected}
                                            searchName="search"
                                            iconImage={'Symptoms.png'}
                                        />

                                        <Tab
                                            handleTab={this.handleTab}
                                            icon={faHospital}
                                            name="hospital"
                                            tabName="HOSPITAL"
                                            tabSelected={tabSelected}
                                            searchName="hos"
                                            iconImage={'Hospital.png'}
                                        />

                                        <Tab
                                            handleTab={this.handleTab}
                                            icon={faBriefcaseMedical}
                                            name="doctor"
                                            tabName="DOCTOR"
                                            tabSelected={tabSelected}
                                            searchName="doct"
                                            iconImage={'Doctor.png'}
                                        />

                                        <Tab
                                            handleTab={this.handleTab}
                                            icon={faHeartbeat}
                                            name="prevention"
                                            tabName="PREVENTION"
                                            tabSelected={tabSelected}
                                            searchName="prev"
                                            iconImage={'Ayurvedic.png'}
                                        />

                                        <Tab
                                            handleTab={this.handleTab}
                                            icon={faGlobe}
                                            name="medicalvisa"
                                            tabName="MEDICAL VISA"
                                            tabSelected={tabSelected}
                                            searchName="visa"
                                            iconImage={'IDCard.png'}
                                        />

                                        {/* <Tab
                                            handleTab={this.handleTab}
                                            icon={faHotel}
                                            name="accomodation"
                                            tabName="ACCOMODATION"
                                            tabSelected={tabSelected}
                                            searchName="accomodation"
                    /> */}

                                        <Tab
                                            handleTab={this.handleTab}
                                            icon={faPlane}
                                            name="airambulance"
                                            tabName="AIR AMBULANCE"
                                            tabSelected={tabSelected}
                                            searchName="ambulance"
                                            iconImage={'Helicopter.png'}
                                        />
                                    </ul>
                                </div>

                                <div className="sub-menu">
                                    <div className="deskInMenu">
                                        <ul
                                            className="ul"
                                            style={{
                                                display: `${
                                                    tabSelected === "treatment" ? "flex" : "none"
                                                }`,
                                            }}
                                        >
                                            <TreatmentList
                                                treatment={defaultTreatment}
                                                handleSpeclChange={this.handleSpeclChange}
                                                condition={defaultCondition}
                                                specialisation={defaultSpecialisation}
                                                hospital={defaultHospital}
                                                doctor={defaultDoctor}
                                            />
                                        </ul>

                                        <ul
                                            className="ul"
                                            style={{
                                                display: `${
                                                    tabSelected === "hospital" ? "flex" : "none"
                                                }`,
                                            }}
                                        >
                                            <HospitalList
                                                treatment={defaultTreatment}
                                                handleSpeclChange={this.handleSpeclChange}
                                                condition={defaultCondition}
                                                specialisation={defaultSpecialisation}
                                                hospital={defaultHospital}
                                                doctor={defaultDoctor}
                                            />
                                        </ul>

                                        <ul
                                            className="ul"
                                            style={{
                                                display: `${tabSelected === "doctor" ? "flex" : "none"}`,
                                            }}
                                        >
                                            <DoctorList
                                                treatment={defaultTreatment}
                                                handleSpeclChange={this.handleSpeclChange}
                                                condition={defaultCondition}
                                                specialisation={defaultSpecialisation}
                                                hospital={defaultHospital}
                                                doctor={defaultDoctor}
                                            />
                                        </ul>

                                        <ul
                                            className="ul"
                                            style={{
                                                display: `${
                                                    tabSelected === "prevention" ? "flex" : "none"
                                                }`,
                                            }}
                                        >
                                            <PreventionList
                                                treatment={defaultTreatment}
                                                handleSpeclChange={this.handleSpeclChange}
                                                condition={defaultCondition}
                                                specialisation={defaultSpecialisation}
                                                hospital={defaultHospital}
                                                doctor={defaultDoctor}
                                            />
                                        </ul>

                                        <ul
                                            className="ul"
                                            onClick={() => {
                                                this.state.medicalVisaData.DestinationCountry === ""
                                                    ? (document.getElementById(
                                                        "DestinationCountry"
                                                    ).style.display = "inline")
                                                    : (document.getElementById(
                                                        "DestinationCountry"
                                                    ).style.display = "none");

                                                this.state.medicalVisaData.DestinationHospital === ""
                                                    ? (document.getElementById(
                                                        "DestinationHospital"
                                                    ).style.display = "inline")
                                                    : (document.getElementById(
                                                        "DestinationHospital"
                                                    ).style.display = "none");

                                                this.state.medicalVisaData.TravelDate === ""
                                                    ? (document.getElementById("TravelDate").style.display =
                                                        "inline")
                                                    : (document.getElementById("TravelDate").style.display =
                                                        "none");

                                                this.state.medicalVisaData.NoofAttendents === ""
                                                    ? (document.getElementById(
                                                        "NoofAttendents"
                                                    ).style.display = "inline")
                                                    : (document.getElementById(
                                                        "NoofAttendents"
                                                    ).style.display = "none");
                                            }}
                                            style={{
                                                display: `${
                                                    tabSelected === "medicalvisa" ? "flex" : "none"
                                                }`,
                                            }}
                                        >
                                            <li className="li-list">
                                                <h4>
                                                    Country of Origin
                                                    <img
                                                        id="CountryofOrigin"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                {/* <Input
                                                style={{ border: "0" }}
                                                name="CountryofOrigin"
                                                onBlur={this.handleInputFirst}
                                                onChange={this.handleInput}
                                                value={this.state.medicalVisaData.CountryofOrigin}
                                                placeholder="Country of Origin" /> */}
                                                <select
                                                    name="CountryofOrigin"
                                                    onBlur={this.handleInputFirst}
                                                    onChange={this.handleInput}
                                                    value={this.state.medicalVisaData.CountryofOrigin}
                                                    style={{
                                                        paddingLeft: "20px",
                                                        border: "none",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <option value="" key="please" disabled>
                                                        Please Select
                                                    </option>
                                                    {countryOption}
                                                </select>
                                            </li>
                                            <li className="li-list">
                                                <h4>
                                                    Destination Country
                                                    <img
                                                        id="DestinationCountry"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationCountry"
                                                value={this.state.medicalVisaData.DestinationCountry}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Country" /> */}
                                                <select
                                                    name="DestinationCountry"
                                                    onBlur={this.handleInputFirst}
                                                    onChange={this.handleInput}
                                                    value={this.state.medicalVisaData.DestinationCountry}
                                                    style={{
                                                        paddingLeft: "20px",
                                                        border: "none",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <option value="" key="please" disabled>
                                                        Please Select
                                                    </option>
                                                    {countryOption}
                                                </select>
                                            </li>

                                            <li className="li-list">
                                                <h4>
                                                    Destination Hospital
                                                    <img
                                                        id="DestinationHospital"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                {/* <Input
                                                style={{ border: "0" }}
                                                name="DestinationHospital"
                                                value={this.state.medicalVisaData.DestinationHospital}
                                                onChange={this.handleInput}
                                                onBlur={this.medicalVisaHandler}
                                                placeholder="Destination Hospital" /> */}

                                                <select
                                                    name="DestinationHospital"
                                                    onBlur={this.handleInputFirst}
                                                    onChange={this.handleInput}
                                                    value={this.state.medicalVisaData.DestinationHospital}
                                                    style={{
                                                        paddingLeft: "20px",
                                                        border: "none",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {this.state.hospitalList}
                                                </select>
                                            </li>
                                            <li className="li-list">
                                                <h4>
                                                    Travel Date
                                                    <img
                                                        id="TravelDate"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                <DatePicker
                                                    style={{border: "0"}}
                                                    disabledDate={disabledDate}
                                                    name="TravelDate"
                                                    // value={this.state.medicalVisaData.TravelDate}
                                                    onBlur={this.medicalVisaHandler}
                                                    onChange={this.handleTravelDate}
                                                />
                                                {/* <Input
                                            style={{border: "0"}}
                                            name="TravelDate"
                                            onChange={this.handleInput}
                                            onBlur={this.medicalVisaHandler}
                                            placeholder="Travel Date"/> */}
                                            </li>
                                            <li className="li-list">
                                                <h4>
                                                    No. of Attendents
                                                    <img
                                                        id="NoofAttendents"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                <Input
                                                    style={{border: "0"}}
                                                    name="NoofAttendents"
                                                    value={this.state.medicalVisaData.NoofAttendents}
                                                    onChange={this.handleInput}
                                                    onBlur={this.medicalVisaHandler}
                                                    placeholder="No. of Attendents"
                                                />
                                            </li>
                                        </ul>

                                        <ul
                                            className="ul"
                                            style={{
                                                display: `${
                                                    tabSelected === "accomodation" ? "flex" : "none"
                                                }`,
                                            }}
                                        >
                                            <li className="li-list">
                                                <h4>City</h4>
                                                <Input
                                                    style={{border: "none"}}
                                                    placeholder="Condition"
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>Hospital</h4>
                                                <Input
                                                    style={{border: "none"}}
                                                    placeholder="Condition"
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>Espected Days to Stay</h4>
                                                <Input
                                                    style={{border: "none"}}
                                                    placeholder="Condition"
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>Travel Date</h4>
                                                <Input
                                                    style={{border: "none"}}
                                                    placeholder="Condition"
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>No. Attendents</h4>
                                                <Input
                                                    style={{border: "none"}}
                                                    placeholder="Condition"
                                                />
                                            </li>
                                        </ul>

                                        <ul
                                            className="ul"
                                            onClick={() => {
                                                this.state.airAmbulanceData.City === ""
                                                    ? (document.getElementById("City").style.display =
                                                        "inline")
                                                    : (document.getElementById("City").style.display =
                                                        "none");

                                                this.state.airAmbulanceData.Destination === ""
                                                    ? (document.getElementById(
                                                        "Destination"
                                                    ).style.display = "inline")
                                                    : (document.getElementById(
                                                        "Destination"
                                                    ).style.display = "none");

                                                this.state.airAmbulanceData.Hospital === ""
                                                    ? (document.getElementById("Hospital").style.display =
                                                        "inline")
                                                    : (document.getElementById("Hospital").style.display =
                                                        "none");

                                                this.state.airAmbulanceData.Date === ""
                                                    ? (document.getElementById("Date").style.display =
                                                        "inline")
                                                    : (document.getElementById("Date").style.display =
                                                        "none");
                                            }}
                                            style={{
                                                display: `${
                                                    tabSelected === "airambulance" ? "flex" : "none"
                                                }`,
                                            }}
                                        >
                                            <li className="li-list">
                                                <h4>
                                                    Country
                                                    <img
                                                        id="Country"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                <Input
                                                    style={{border: "0"}}
                                                    name="Country"
                                                    onBlur={this.handleAirAmbulanceInputFirst}
                                                    onChange={this.handleAirAmbulanceInput}
                                                    value={this.state.airAmbulanceData.Country}
                                                    placeholder="Country"
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>
                                                    City
                                                    <img
                                                        id="City"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                <Input
                                                    style={{border: "0"}}
                                                    name="City"
                                                    value={this.state.airAmbulanceData.City}
                                                    onChange={this.handleAirAmbulanceInput}
                                                    onBlur={this.airAmbulanceHandler}
                                                    placeholder="City"
                                                />
                                            </li>

                                            <li className="li-list">
                                                <h4>
                                                    Destination
                                                    <img
                                                        id="Destination"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                <Input
                                                    style={{border: "0"}}
                                                    name="Destination"
                                                    value={this.state.airAmbulanceData.Destination}
                                                    onChange={this.handleAirAmbulanceInput}
                                                    onBlur={this.airAmbulanceHandler}
                                                    placeholder="Destination"
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>
                                                    Date
                                                    <img
                                                        id="Date"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                <DatePicker
                                                    style={{border: "0"}}
                                                    disabledDate={disabledDate}
                                                    name="Date"
                                                    // value={this.state.medicalVisaData.TravelDate}
                                                    onBlur={this.airAmbulanceHandler}
                                                    onChange={this.handleAirAmbulanceDate}
                                                />
                                            </li>
                                            <li className="li-list">
                                                <h4>
                                                    Hospital
                                                    <img
                                                        id="Hospital"
                                                        style={{display: "none"}}
                                                        src="/assets/images/danger-icon.png"
                                                        alt=""
                                                        width="20"
                                                        height="20"
                                                    />
                                                </h4>
                                                {/* <Input
                                                style={{ border: "0" }}
                                                name="Hospital"
                                                value={this.state.airAmbulanceData.Hospital}
                                                onChange={this.handleAirAmbulanceInput}
                                                onBlur={this.airAmbulanceHandler}
                                                placeholder="Hospital" /> */}
                                                <select
                                                    name="Hospital"
                                                    onChange={this.handleAirAmbulanceInput}
                                                    onBlur={this.airAmbulanceHandler}
                                                    value={this.state.airAmbulanceData.Hospital}
                                                    style={{
                                                        paddingLeft: "20px",
                                                        border: "none",
                                                        width: "100%",
                                                    }}
                                                >
                                                    {this.state.hospitalList}
                                                </select>
                                            </li>
                                        </ul>
                                    </div>


                                    {/*<br/>*/}
                                    <div style={{marginTop:"5px"}}>
                                        {/*Use the Search to find the best match to your needs*/}
                                        <button
                                            className="btn btn-solid srchBtn"
                                            style={{marginLeft: "0px", marginTop: "0px"}}
                                        >Search
                                        </button>
                                    </div>

                                </div>

                            </section>
                        ) : null}
                    </section>
                </header>
                <header id="sticky" className="sticky hidden hide-me">
                    {this.state.menuVisible ? (
                        <div className="row container-fluid">
                            <div className="col-sm-12">
                                <div className="main-menu">
                                    <div className="collection-content container" style={{width: "100%"}}>
                                        <div className="headerInside" style={{width: "100%"}}>
                                            <ul
                                                className="ul row"
                                                style={{
                                                    display: `${
                                                        tabSelected === "treatment" ? "flex" : "none"
                                                    }`,
                                                }}
                                            >
                                                <TreatmentList
                                                    treatment={defaultTreatment}
                                                    handleSpeclChange={this.handleSpeclChange}
                                                    condition={defaultCondition}
                                                    specialisation={defaultSpecialisation}
                                                    hospital={defaultHospital}
                                                    doctor={defaultDoctor}
                                                />
                                            </ul>
                                            <ul
                                                className="ul row"
                                                style={{
                                                    display: `${
                                                        tabSelected === "hospital" ? "flex" : "none"
                                                    }`,
                                                }}
                                            >
                                                <HospitalList
                                                    treatment={defaultTreatment}
                                                    handleSpeclChange={this.handleSpeclChange}
                                                    condition={defaultCondition}
                                                    specialisation={defaultSpecialisation}
                                                    hospital={defaultHospital}
                                                    doctor={defaultDoctor}
                                                />
                                            </ul>
                                            <ul
                                                className="ul row"
                                                style={{
                                                    display: `${
                                                        tabSelected === "doctor" ? "flex" : "none"
                                                    }`,
                                                }}
                                            >
                                                <DoctorList
                                                    treatment={defaultTreatment}
                                                    handleSpeclChange={this.handleSpeclChange}
                                                    condition={defaultCondition}
                                                    specialisation={defaultSpecialisation}
                                                    hospital={defaultHospital}
                                                    doctor={defaultDoctor}
                                                />
                                            </ul>
                                            <ul
                                                className="ul row"
                                                style={{
                                                    display: `${
                                                        tabSelected === "medicalvisa" ? "flex" : "none"
                                                    }`,
                                                }}
                                            >
                                                <li className="li-list">
                                                    <h4>Country of Origin</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Destination Country</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Destination Hospital</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Travel Date</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>No. of Attendents</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                            </ul>
                                            <ul
                                                className="ul row"
                                                style={{
                                                    display: `${
                                                        tabSelected === "accomodation" ? "flex" : "none"
                                                    }`,
                                                }}
                                            >
                                                <li className="li-list">
                                                    <h4>City</h4>
                                                    <Input
                                                        style={{border: "none"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Hospital</h4>
                                                    <Input
                                                        style={{border: "none"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Espected Days to Stay</h4>
                                                    <Input
                                                        style={{border: "none"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Travel Date</h4>
                                                    <Input
                                                        style={{border: "none"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>No. Attendents</h4>
                                                    <Input
                                                        style={{border: "none"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                            </ul>
                                            <ul
                                                className="ul row"
                                                style={{
                                                    display: `${
                                                        tabSelected === "airambulance" ? "flex" : "none"
                                                    }`,
                                                }}
                                            >
                                                <li className="li-list">
                                                    <h4>Country</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>City</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Destination</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Hospital</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                                <li className="li-list">
                                                    <h4>Date</h4>
                                                    <Input
                                                        style={{border: "0"}}
                                                        placeholder="Condition"
                                                    />
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : null}
                </header>

                <div id="search-overlay" className="search-overlay">
                    <div>
            <span
                className="closebtn"
                onClick={this.closeSearch}
                title="Close Overlay"
            >
              ×
            </span>
                        <div className="overlay-content">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-12">
                                        <form>
                                            <div className="form-group">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    id="exampleInputPassword1"
                                                    placeholder="Search a Product"
                                                />
                                            </div>
                                            <button type="submit" className="btn btn-primary">
                                                <i className="fa fa-search"/>
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    // products: getVisibleDoctors(state.data, state.filters),
    // eslint-disable-next-line no-restricted-globals
    // products: getDoctorsForSearchParam(getParams(location.href)),
    doctors: state.doctors,
    filters: state.filters,
    symbol: state.data.symbol,

    searchString: state.filters.searchString,

    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,
});
export default connect(
    mapStateToProps,
    {
        addToCart,
        addToWishlist,
        addToCompare,
        getDoctors,
        getSuggestions,
        getSuggestionsTreatments,
        getSuggestionsConditions,
        getSuggestionsSpeciality,
        getSuggestionsHospitals,
        getSuggestionsDoctors,
        filterSearchString,
        filterClean,
    }
)(withRouter(HeaderInside));
// export default connect(null,
//     {changeCurrency}
// )(withRouter(HeaderInside));
