import React, { Component } from "react";
import { connect } from "react-redux";
import { logout } from "../../../../redux/actions/authActions";
import LogoImage from "./logo";
import { getMyLocation } from "../../../../api/apicalls";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

class TopBarDark extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loc: {
        city: "",
        country: "",
      },
      isOpen: false,
    };
    this.toggle = this.toggle.bind(this);
  }
  toggle() {
    let value = !this.state.isOpen;
    console.log("value", value);
    this.setState({ isOpen: value });
  }

  componentDidMount() {
    this.getLocation();
  }

  getDashboardlink(type) {
    let url = "";

    if (type === "user") {
      url = `${process.env.PUBLIC_URL}/pages/dashboard`;
    } else if (type === "hospital") {
      url = `${process.env.PUBLIC_URL}/pages/hospitaldashboard`;
    } else if (type === "doctor") {
      url = `${process.env.PUBLIC_URL}/pages/doctordashboard`;
    }
    //console.log("getDashboardlink ", type, url);
    return url;
  }

  getLocation() {
    getMyLocation().then((loc) => {
      // console.log("Location : ", loc);
      this.setState({ loc: loc });
    });
  }

  getBookingslink(type) {
    let url = "";

    if (type === "user") {
      url = `${process.env.PUBLIC_URL}/pages/bookings`;
    } else if (type === "hospital") {
      url = `${process.env.PUBLIC_URL}/pages/hospitalbookings`;
    } else if (type === "doctor") {
      url = `${process.env.PUBLIC_URL}/pages/doctorbookings`;
    }
    // console.log("getBookingslink ", type, url);
    return url;
  }

  getHeader = (user) => {
    // console.log("TOP HEADER : recived user ", user);

    if (user && user.isLogedIn) {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <i className="fa fa-user" aria-hidden="true" /> My Account
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <NavLink href={this.getBookingslink(user.user.user.type)}>
                {" "}
                <i className="fa fa-heart" aria-hidden="true" /> My Bookings
              </NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink href={this.getDashboardlink(user.user.user.type)}>
                {" "}
                <i className="fa fa-heart" aria-hidden="true" /> My Account
              </NavLink>
            </DropdownItem>
            <DropdownItem divider />
            <DropdownItem onClick={this.props.logout}>Logout</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    } else {
      return (
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <i className="fa fa-user" aria-hidden="true" />
            Login
          </DropdownToggle>
          <DropdownMenu right>
            <DropdownItem>
              <NavLink href={`${process.env.PUBLIC_URL}/pages/login`}>
                {" "}
                Login
              </NavLink>
            </DropdownItem>
            <DropdownItem>
              <NavLink href={`${process.env.PUBLIC_URL}/pages/register/user`}>
                {" "}
                Register
              </NavLink>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    }
  };

  render() {
    const { user } = this.props;
    // console.log("TOPHEADER: header recived user", user);

    return (
      <div style={{ backgroundColor: "#fff",  }}>
        <div className="container" style={{textTransform:'capitalize'}}>
          <Navbar color="white" light expand="lg">
            {/*<NavbarBrand href="/">*/}
            <LogoImage logo={"logo.png"} />
            {/*</NavbarBrand>*/}
            <NavbarToggler onClick={this.toggle} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                <NavItem>
                  <NavLink href="#">
                    {this.state.loc.city + ", " + this.state.loc.country}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink href={`${process.env.PUBLIC_URL}/wishlist`}>
                    <i className="fa fa-heart" aria-hidden="true" /> favorite
                  </NavLink>
                </NavItem>
                {this.getHeader(user)}
              </Nav>
            </Collapse>


            <div className='mobilMenu'>
              <Nav className="ml-auto" navbar>
                {this.getHeader(user)}
              </Nav>
            </div>

          </Navbar>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth,
});

export default connect(
  mapStateToProps,
  { logout }
)(TopBarDark);
