import React, { Component } from 'react';
import Doctor from "./doctor-card";
import Hospital from "./hospital-card";

class SearchCheck extends Component {

    constructor(props) {
        super(props);
        this.state = {
            type: this.props.type,
            data: this.props.data,
            query: this.props.query,
            index: this.props.index,
            docExp:this.props.docExp,
            section: this.props.section,
        }
    }
    componentDidMount() {
        this.setState({ type: this.props.type });
        this.setState({ data: this.props.data });
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        // console.log("props::data-old",prevProps.data);
        // console.log("props::data-new",this.props.data);
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
         
            this.setState({ type: this.props.type });
            this.setState({ data: this.props.data });
        }

    }


    render() {
        // console.log("search::state", this.state);
        return (
            <div style={{ marginBottom: '30px' }}>

                {(this.state.type.toLowerCase() === "hospital") ? <Hospital data={this.state.data}
                                                                            index={this.state.index}
                                                                            query={this.state.query}
                                                                            docExp={this.state.docExp}
                                                                            section={this.state.section} rate={this.props.rate}/> : ''}
                {(this.state.type.toLowerCase() === "doctor") ? <Doctor data={this.state.data} index={this.state.index} query={this.state.query} section={this.state.section}/> : ''}
            </div>
        )
    }
}

export default SearchCheck;
