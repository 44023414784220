import React, { Component } from "react";
// import { history } from "react-router-dom";
import Breadcrumb from "../commonAll/breadcrumb";
import { connect } from "react-redux";

import { login } from "../../redux/actions/authActions";
import { notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
// const  browserHistory = ReactRouter.browserHistory;
// const Router = require("react-router");
// import Router from "react-router";

const antIcon = (
  <LoadingOutlined style={{ fontSize: 24, color: "white" }} spin />
);
class Login extends Component {

  // static contextTypes = {
  //   router: () => true, // replace with PropTypes.object if you use them
  // }

  constructor(props) {
    super(props);

    this.state = {
      user: {
        email: "",
        password: "",
      },
      submitted: false,
      invalid: false,
      error: "",
      loading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleLogin = this.handleLogin.bind(this);
    //console.log("config ", appConfig);
  }

  handleChange(event) {
    const { name, value } = event.target;
    //console.log(name, value, event.target.value);
    const { user } = this.state;
    this.setState({
      user: {
        ...user,
        [name]: value,
      },
    });
  }
  handleLogin() {
    this.setState({ loading: true });
    const { user } = this.state;
    let data = {
      identifier: user.email,
      password: user.password,
    };

    this.props
      .login(data)
      .then((res) => this.setState({ loading: false }))
      .catch((err) => {
        this.setState({
          error: "Please Provide a valid Email or Password",
          loading: false,
        });
        notification.error({
          message: "Error",
          description: "Please Provide a valid Email or Password",
          onClick: () => {
            console.log("Notification Clicked!");
          },
        });
      });
  }

  // userLogin = () => {
  //     console.log("ole ole ole", this.state.user);
  //     const {user} = this.state;
  //     let data = {
  //         identifier: user.email,
  //         password: user.password,
  //     }
  //     //getUser();
  //     login(data);
  //     // login(data).then((response) => {
  //     //     console.log(response);
  //     // });
  // }

  // userLogin1 = () => {
  //     console.log("ole ole ole", this.state.user);
  //     this.setState({submitted: true});
  //     const {user} = this.state;
  //     if (user.email && user.password) {
  //         //this.props.register(user);
  //         console.log("i can login");
  //         axios
  //             .post(appConfig.loginUrl, {
  //                 identifier: user.email,
  //                 password: user.password,
  //             })
  //             .then(response => {
  //                 // Handle success.
  //                 console.log('Well done!');
  //                 console.log('User', response.data);
  //                 console.log('User profile', response.data.user);
  //                 console.log('User token', response.data.jwt);
  //                 localStorage.setItem('user', JSON.stringify(response.data));
  //                 window.open(`${process.env.PUBLIC_URL}/`, '_self');
  //             })
  //             .catch(error => {
  //                 // Handle error.
  //                 console.log('An error occurred:', error);
  //                 this.setState({invalid: true})
  //             });
  //     }
  // }

  render() {
    window.loginPage = this;
    // console.log("LOGINPAGE: the state is ", this.state);
    // console.log("LOGINPAGE: the props are ", this.props);
    if (this.props.user.isLogedIn) {
      // console.log("LOGINPAGE: the user is logged in");
      window.open(`${process.env.PUBLIC_URL}/pages/dashboard`, "_self");
      // console.log("login history ",this.context.router.history)
      // console.log("login history ",history)
    }

    return (
      <div>
        <Breadcrumb title={"Login"} />

        {/*Login section*/}
        <section className="login-page section-b-space">
          <div className="container">
            <div className="row">
              <div className="col-lg-6">
                <h3>Login</h3>
                <div className="theme-card">
                  <form
                    className="theme-form"
                    onKeyUp={(event) => {
                      if (event.keyCode === 13) {
                        this.handleLogin();
                      }
                    }}
                  >
                    <div className="form-group">
                      {this.state.invalid && (
                        <div className="help-block">
                          Invalid UserName or Password
                          <br />
                        </div>
                      )}
                      <label htmlFor="email">Email</label>

                      <input
                        type="text"
                        className="form-control"
                        id="email"
                        placeholder="Email"
                        name="email"
                        value={this.state.user.email}
                        onChange={this.handleChange}
                        required=""
                      />
                      {this.state.submitted && !this.state.user.email && (
                        <div className="help-block">Email is required</div>
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="review">Password</label>
                      <input
                        type="password"
                        className="form-control"
                        id="password"
                        name="password"
                        value={this.state.user.password}
                        onChange={this.handleChange}
                        placeholder="Enter your password"
                        required=""
                      />
                      {this.state.submitted && !this.state.user.password && (
                        <div className="help-block">Password is required</div>
                      )}
                    </div>
                    {this.state.error.length ? (
                      <div className="loginError">{this.state.error}</div>
                    ) : null}
                    <div
                      className="btn btn-solid cardBtn"
                      onClick={this.handleLogin}
                    >
                      Login
                      {this.state.loading ? (
                        <Spin
                          style={{ marginLeft: "5px" }}
                          indicator={antIcon}
                        />
                      ) : null}
                    </div>
                    <a
                      href={`${process.env.PUBLIC_URL}/pages/forget-password`}
                      className="btn btn-solid cardBtn"
                      style={{ marginLeft: "10px" }}
                    >
                      Forget Password
                    </a>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 right-login">
                <h3>Create New Account</h3>
                <div className="theme-card authentication-right">
                  <h6 className="title-font">Create A Account</h6>
                  <p>
                    Sign up for a free account. Registration is quick and easy.
                    It allows you to be able to make a Booking from our Website.
                    To start booking click register.
                  </p>
                  <div className="row">
                    <a
                      href={`${process.env.PUBLIC_URL}/pages/register/user`}
                      className="btn btn-solid cardBtn"
                    >
                      I'm a Patient
                    </a>
                    <a
                      href={`${process.env.PUBLIC_URL}/pages/register/doctor`}
                      className="btn btn-solid cardBtn"
                    >
                      I'm a Doctor
                    </a>
                    <a
                      href={`${process.env.PUBLIC_URL}/pages/register/hospital`}
                      className="btn btn-solid cardBtn"
                    >
                      For Hospital
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    user: state.auth,
  };
}

export default connect(
  mapStateToProps,
  { login }
)(Login);
