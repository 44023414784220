import React, { Component } from "react";
import { connect } from "react-redux";

import "react-input-range/lib/css/index.css";
import { SlideToggle } from "react-slide-toggle";
import { getDCities } from "../../../api/apicalls";

import { getDocspecial, getCity } from "../../../services";
import {
  filterDCity,
  filterDRate,
  filterPrice,
  filterDocspecial,
  filterExperience,
  filterGender,
  filterAvailableFor,
} from "../../../redux/actions";
import { getDoctors } from "../../../redux/actions/doctorActions";
import { Select, Rate, Radio, Space, Checkbox } from "antd";
const { Option } = Select;
class FilterDoctor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openFilter: false,
      spcl: [],
      cities: [],
      rateValue: 0,
      docExp: [0, 100],
      radio: "",
      docPrice: [0, 10000],
      radioPrice: "",
      availableFor: {
        videoConference: false,
        onCall: false,
        inHospital: false,
      },
    };
    //console.log("props",props);
  }

  closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  };

  componentDidMount() {
    let { selectedcity } = this.props;
    let fcity = [];
    // console.log("fiter recive ", selectedcity, specialities);

    // eslint-disable-next-line no-restricted-globals
    let url = location.href;
    let params = this.getParams(url);
    let spcl = [];
    if (params.s) {
      spcl = params.s.split(",");
      // console.log(">>>>>>>> DOCTORS QUERY : ", url);
      // console.log(">>>>>>>> DOCTORS QUERY : ", params);
      // console.log(">>>>>>>> DOCTORS QUERY : ", spcl);
      this.setState({ spcl });
    }

    if (selectedcity === "" || selectedcity.toUpperCase() === "INDIA") {
      fcity = [];
    } else {
      fcity = [selectedcity.toUpperCase()];
    }

    //   this.props.filterDCity(fcity);
    //this.updateHospitals();
    let filters = {
      ...this.props.filters,
      dcity: fcity,
      docspecial: spcl,
      rateValue: this.props.rateSelected,
    };
    // this.props.getDoctors(filters);
    this.getCities(params);
  }
  getCities = (query) => {
    getDCities(query).then((cities) => {
      // console.log("Cities are : ", cities)
      this.setState({ cities });
    });
  };

  getParams = (url) => {
    var params = {};
    var parser = document.createElement("a");
    parser.href = url;
    var query = parser.search.substring(1);
    var vars = query.split("&");
    for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split("=");
      params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
  };

  docspecialityHendle(event, docspecial) {
    var index = docspecial.indexOf(event.target.value);
    if (event.target.checked) docspecial.push(event.target.value);
    // push in array checked value
    else docspecial.splice(index, 1); // removed in array unchecked value

    this.props.filterDocspecial(docspecial);
    this.updateDoctors();
  }

  cityHendle(event, cities) {
    var index = cities.indexOf(event.target.value);
    if (event.target.checked) cities.push(event.target.value);
    // push in array checked value
    else cities.splice(index, 1); // removed in array unchecked value

    this.props.handleCheckedCity(cities);
    // this.props.filterDCity(cities);
    this.updateDoctors();
  }
  changeAvailability = (e) => {
    this.setState(
      {
        availableFor: {
          ...this.state.availableFor,
          [e.target.name]: e.target.checked,
        },
      },
      () => {
        this.props.handleAvailableChange(this.state.availableFor);
      }
    );
  };
  rateHandle(value) {
    this.props.filterDRate({ value });
    this.updateDoctors();
  }

  priceHandle(value, name) {
    this.props.handleSlideChange(name, value);
  }
  experienceHandle(value) {
    this.props.filterExperience({ value });
    this.updateDoctors();
  }
  genderMaleHandle(e) {
    alert(e.target.checked);
  }

  updateDoctors() {
    this.props.getDoctors(this.props.filters);
  }
  handleAvailableChange(value) {
    this.props.handleAvailableChange(value);
  }
  handleGenderChange(value) {
    this.props.handleGenderChange(value);
  }
  handleRadioCheck = (e) => {
    switch (e.target.value) {
      case 1:
        this.props.handleDocExp([0, 5]);
        this.setState({ docExp: [0, 5], radio: 1 });
        break;
      case 2:
        this.props.handleDocExp([5, 10]);
        this.setState({ docExp: [5, 10], radio: 2 });
        break;
      case 3:
        this.props.handleDocExp([10, 15]);
        this.setState({ docExp: [10, 15], radio: 3 });
        break;
      case 4:
        this.props.handleDocExp([15, 20]);
        this.setState({ docExp: [15, 20], radio: 4 });
        break;
      case 5:
        this.props.handleDocExp([20, 100]);
        this.setState({ docExp: [20, 100], radio: 5 });
        break;
      default:
        this.props.handleDocExp([0, 100]);
        this.setState({ docExp: [0, 100], radio: "" });
    }
  };
  handleRadioPrice = (e) => {
    switch (e.target.value) {
      case 1:
        this.props.handleDocPrice([0, 500]);
        this.setState({ docPrice: [0, 500], radioPrice: 1 });
        break;
      case 2:
        this.props.handleDocPrice([500, 1000]);
        this.setState({ docPrice: [500, 1000], radioPrice: 2 });
        break;
      case 3:
        this.props.handleDocPrice([1000, 1500]);
        this.setState({ docPrice: [1000, 1500], radioPrice: 3 });
        break;
      case 4:
        this.props.handleDocPrice([1500, 2000]);
        this.setState({ docPrice: [1500, 2000], radioPrice: 4 });
        break;
      case 5:
        this.props.handleDocPrice([2000, 15000]);
        this.setState({ docPrice: [2000, 15000], radioPrice: 5 });
        break;
      default:
        this.props.handleDocPrice([0, 15000]);
        this.setState({ docPrice: [0, 15000], radioPrice: "" });
    }
  };

  render() {
    const filterDocspecial = this.props.filters.docspecial
      ? this.props.filters.docspecial
      : [];
    const filterCities = this.props.values.dCheckedCity
      ? this.props.values.dCheckedCity
      : [];
    console.log("filter-doctor::filterCities", filterCities);
    const { dcity, docExp } = this.props.values;
    const cities = dcity;
    console.log("filter-doctor::cities", cities);
    window.dfstate = this.state;
    window.dfprops = this.props;

    // console.log("DOCTOR/FILTER-PAGE props : ", this.props);
    // console.log("DOCTOR/FILTER-PAGE state : ", this.state);

    return (
      <div className="collection-filter-block">
        {/*brand filter start*/}
        <div className="collection-mobile-back">
          <span className="filter-back" onClick={(e) => this.closeFilter(e)}>
            <i className="fa fa-angle-left" aria-hidden="true" /> back
          </span>
        </div>

        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block">
              <h3 className="collapse-block-title" onClick={onToggle}>
                Cities
              </h3>
              <div
                className="collection-collapse-block-content"
                ref={setCollapsibleElement}
              >
                <div className="collection-brand-filter">
                  {this.state.cities ? (
                    <Select
                      mode="multiple"
                      allowClear
                      size={"large"}
                      style={{ width: "100%" }}
                      placeholder="Please select"
                      className="cityDrop"
                      // dropdownClassName="cityDrop"
                      // defaultValue={['a10', 'c12']}
                      onChange={(city) => {
                        console.log("FILTER CITY : ", city);
                        // filterCities.push(city)
                        this.props.handleCheckedCity(city);
                      }}
                    >
                      {/*{this.state.cities}*/}
                      {this.state.cities.map((item, index) => {
                        // console.log("Item", speclName, item)
                        return (
                          <Option key={index} value={item} label={item}>
                            {item}
                          </Option>
                        );
                      })}
                    </Select>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </SlideToggle>

        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={onToggle}>
                Rating
              </h3>
              <div
                className="collection-collapse-block-content block-price-content"
                ref={setCollapsibleElement}
              >
                <div className="collection-brand-filter">
                  <div className="custom-control custom-checkbox collection-filter-checkbox">
                    <Rate
                      value={this.state.rateValue}
                      style={{
                        color: "#ffa201",
                        fontSize: "14px",
                        cursor: "pointer",
                      }}
                      onChange={(e) => {
                        console.log("User Clicked on ", e);
                        this.setState({ rateValue: e });
                        this.props.handleNewRate(e);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={onToggle}>
                Available For
              </h3>
              <div
                className="collection-collapse-block-content block-price-content"
                ref={setCollapsibleElement}
              >
                <div className="collection-brand-filter py-2">
                  <Space direction="vertical">
                    <Checkbox
                      name="videoConference"
                      onChange={this.changeAvailability}
                    >
                      Video Conference
                    </Checkbox>
                    <Checkbox name="onCall" onChange={this.changeAvailability}>
                      Phone Call
                    </Checkbox>
                    <Checkbox
                      name="inHospital"
                      onChange={this.changeAvailability}
                    >
                      In Hospital
                    </Checkbox>
                  </Space>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={onToggle}>
                Consultation Fees
              </h3>
              <div
                className="collection-collapse-block-content block-price-content"
                ref={setCollapsibleElement}
              >
                <div className="collection-brand-filter py-2">
                  <Radio.Group
                    onChange={this.handleRadioPrice}
                    value={this.state.radioPrice}
                  >
                    <Space direction="vertical">
                      <Radio value={1}>Under 500</Radio>
                      <Radio value={2}>500 to 1000</Radio>
                      <Radio value={3}>1000 to 1500</Radio>
                      <Radio value={4}>1500 to 2000</Radio>
                      <Radio value={5}>2000 and above</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>

        <SlideToggle>
          {({ onToggle, setCollapsibleElement }) => (
            <div className="collection-collapse-block open">
              <h3 className="collapse-block-title" onClick={onToggle}>
                Experience
              </h3>
              <div
                className="collection-collapse-block-content block-price-content"
                ref={setCollapsibleElement}
              >
                <div className="collection-brand-filter py-2">
                  <Radio.Group
                    onChange={this.handleRadioCheck}
                    value={this.state.radio}
                  >
                    <Space direction="vertical">
                      <Radio value={1}>Under 5 years</Radio>
                      <Radio value={2}>5 to 10 years</Radio>
                      <Radio value={3}>10 to 15 years</Radio>
                      <Radio value={4}>15 to 20 years</Radio>
                      <Radio value={5}>20 years and above</Radio>
                    </Space>
                  </Radio.Group>
                </div>
              </div>
            </div>
          )}
        </SlideToggle>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  //brands: getBrands(state.data.products),
  //specialities: getSpeciality(state.data.products),
  docspecial: getDocspecial(state.doctors.doctors),
  cities: getCity(state.doctors.doctors),
  rate: { min: 0, max: 5 },
  //prices: getMinMaxPrice(state.data.products),
  prices: { min: 0, max: 15000 },
  experience: { min: 0, max: 100 },
  filters: state.filters,
});

export default connect(mapStateToProps, {
  filterDCity,
  filterDRate,
  filterPrice,
  filterDocspecial,
  getDoctors,
  filterExperience,
  filterGender,
  filterAvailableFor,
})(FilterDoctor);
