import React from "react";
import Card from "./index";
import {Product5, Slider6, SliderHome1} from "../../services/script";
import Slider from 'react-slick';
// import {Slider6} from "../../../services/script";
import {Col, Row} from 'antd';
import apiConfig from "../../config";

const Conditions = () => {
    return (
        // <div className="row">
        <div style={{
            margin: '0px',
            // height: '175px',
            // overflow: 'auto hidden',
            overflow: 'hidden',
            // border: '1px solid rgb(236, 236, 236)',
            display: 'flex',
            flexDirection: 'row',
            // padding: '10px',
            // backgroundColor: 'rgb(236, 236, 236)',
            // boxShadow: 'rgb(0 0 0 / 15%) 0px 0px 10px inset'
        }}>
            <Row style={{margin: "10px", padding: "20px 0"}}>
                <Col span={24}>
                    <Slider {...SliderHome1} className="slide-6 no-arrow">
                        <div>
                            <div style={{marginRight:'25px'}}>
                                <Card
                                    to={"/search/india?Speciality=6088fe79b857632eeb7693e8"}
                                    img={`${apiConfig.imgapi+'280x150/smart/'+'https://hosplan.com'}`+"/assets/images/ivf.jpg"}
                                    title={"Infertility - Difficulty in planned conception"}
                                    description={
                                        "An infertility specialist also referred to as a reproductive endocrinologist (RE), is an expert in the field of infertility."
                                    }
                                />
                            </div>
                        </div>
                        <div>
                            <div style={{marginRight:'25px'}}>
                            <Card
                                img={`${apiConfig.imgapi+'280x150/smart/'+'https://hosplan.com'}`+"/assets/images/heart.jpg"}
                                title={"Cardio Thoracic and Vascular Surgeons"}
                                description={
                                    "Thoracic surgeons  who are often grouped with cardiac, or heart, specialists for cardiothoracic surgery. operate on the heart"
                                }
                                to={"/search/india?Speciality=60c46be72912ad448b6b8146"}
                            />
                        </div>
                        </div>
                        <div>
                            <div style={{marginRight:'25px'}}>
                            <Card
                                img={`${apiConfig.imgapi+'280x150/smart/'+'https://hosplan.com'}`+"/assets/images/gynaonco.jpeg"}
                                title={"Gynae Oncologists"}
                                description={
                                    "Treat patients who have been diagnosed with a serious precancer or a cancer of any of the areas of the gynaecological tract"
                                }
                                to={"/search/india?Speciality=609e263404e6d869dda21176"}
                            />
                        </div>
                        </div>
                        <div>
                            <div style={{marginRight:'25px'}}>
                            <Card
                                img={`${apiConfig.imgapi+'280x150/smart/'+'https://hosplan.com'}`+"/assets/images/neuri.jpg"}
                                title={"Neurologists"}
                                description={
                                    "The scientific study of biological and theoretical disease and disorders of nervous system."
                                }
                                to={"/search/india?Speciality=5fa8f1323eb89c7b98a54e77"}
                            />
                        </div>
                        </div>
                        <div>
                            <div style={{marginRight:'25px'}}>
                            <Card
                                img={`${apiConfig.imgapi+'280x150/smart/'+'https://hosplan.com'}`+"/assets/images/cataract.png"}
                                title={"Cataract Surgeons"}
                                description={
                                    "A specialist in the branch of medicine concerned with the study and treatment of disorders and diseases of the eye"
                                }
                                to={"/doct/india?Speciality=60c490f02912ad448b6b816e"}
                            />
                        </div>
                        </div>
                        <div>
                            <div style={{marginRight:'25px'}}>
                            <Card
                                img={`${apiConfig.imgapi+'280x150/smart/'+'https://hosplan.com'}`+"/assets/images/blood.jpg"}
                                title={"Blood Disorders specialists"}
                                description={
                                    "Hematology-oncology refers to the combined medical practice of hematology (the study of the blood's physiology) and oncology. "
                                }
                                to={"/doct/india?Speciality=60c717cb2912ad448b6b8178"}
                            />
                        </div>
                        </div>
                    </Slider>
                </Col>
            </Row>


        </div>
    );
};

export default Conditions;
