import React, {Component} from 'react';
// import {connect} from 'react-redux'
// import {Link} from 'react-router-dom'
// import Slider from 'react-slick';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
// import {FormCheck} from 'react-bootstrap';
import {addDays, getDay, setHours, setMinutes} from 'date-fns';
import Modal from 'react-responsive-modal';
//import {Input} from "antd";
import {Form, Input} from 'antd';
import appConfig from "../../../../config";

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not validate email!',
        number: '${label} is not a validate number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 24,
    },
};

class PriceDoctor extends Component {

    constructor(props) {
        super(props)
        this.state = {
            open: false,

            quantity: 1,

            bookingType: "",
            bookingTime: "",

            stock: 'InStock',
            nav3: null,
            onVideoConf: false,
            onPhone: false,
            onLocation: false,

            startDate: new Date(),
            selectedDate: new Date(),

            onVideoEnable: 'none',
            onPhoneEnable: 'none',
            onLocationEnable: 'none',

            bookTypeColor: "#000",
            bookTimeColor: "#000",
        }
    }

    componentDidMount() {
        this.setState({
            nav3: this.slider3
        });
    }

    onOpenModal = () => {
        this.setState({open: true});
    };

    onCloseModal = () => {
        this.setState({open: false});
    };

    minusQty = () => {
        if (this.state.quantity > 1) {
            this.setState({stock: 'InStock'})
            this.setState({quantity: this.state.quantity - 1})
        }
    }

    plusQty = () => {
        if (this.props.item.stock >= this.state.quantity) {
            this.setState({quantity: this.state.quantity + 1})
        } else {
            this.setState({stock: 'Out of Stock !'})
        }
    }
    changeQty = (e) => {
        this.setState({quantity: parseInt(e.target.value)})
    }

    setStartDate = (dt) => {
        // console.log(dt);
        this.setState({
            selectedDate: dt,
            bookTimeColor: "#000",
            bookingTime: dt
        });
    }

    render() {
        const {symbol, item, addToCartClicked, BuynowClicked} = this.props;
        window.prcDocProps = this.props;

        if(!item.Price)
        {
            // item.price=888;
            // console.log("price",item.price);
        }
        if (item.OnVideo) {
            //this.setState({onVideoEnable:'block'})
            // this.setState({onVideoEnable : 'inline'});
            this.state.onVideoEnable = 'inline';
        }
        if (item.OnPhone) {
            //this.setState({onPhoneEnable:'block'})
            // this.setState({onPhoneEnable:'inline'})
            this.state.onPhoneEnable = 'inline';
        }
        if (item.OnLocation) {
            //this.setState({onLocationEnable:'block'})
            // this.setState({onLocationEnable:'inline'})
            this.state.onLocationEnable = 'inline';
        }

        let hrs = [];
        if (item.TimeSlots) {
            item.TimeSlots.forEach(t => {
                hrs.push(setHours(setMinutes(new Date(), 0), t));
            })
            // let slots =[10,11,12,13,14,15];

            // slots.forEach(t => {
            //     hrs.push(setHours(setMinutes(new Date(), 0), t));
            // })
            // console.log("HRS : ", hrs)
        }


        let slots =[10,11,12,13,14,15];

        slots.forEach(t => {
            hrs.push(setHours(setMinutes(new Date(), 0), t));
        })
        // console.log("HRS : ", hrs)

        const isWeekday = date => {
            const day = getDay(date);
            return day !== 0;
        };

        let colorsnav = {
            slidesToShow: 6,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true
        };

        // item.OnVideo = true;

        item.Discount = item.Discount?item.Discount:0;

        return (
            <div  style={{padding: '0px'}}>
                {item.Price ?
                    <div className="product-right product-form-box" style={{minWidth: '370px'}}>
                        <h4> Consultation Fee </h4>
                        <h4>
                            <del>{symbol}{item.Price * appConfig.inr2bdt}</del>
                            <span>{item.Discount}% off</span>
                        </h4>
                        <h3>{symbol}{parseInt((item.Price - (item.Price * item.Discount / 100))*appConfig.inr2bdt, 10)} </h3>
                        <ul className="color-variant">
                            <span>Available On : </span>
                            {item.OnVideo ? <img src="/assets/images/icons/videocam.svg" alt="React Logo"
                                                 style={{
                                                     width: '30px',
                                                     height: '30px',
                                                     padding: '5px',
                                                     paddingBottom: '9px'
                                                 }}/> :
                                <img src="/assets/images/icons/videocam_off.svg" alt="React Logo"
                                     style={{width: '30px', height: '30px', padding: '5px', paddingBottom: '9px'}}/>}
                            {item.OnPhone ? <img src="/assets/images/icons/phone.svg" alt="React Logo"
                                                 style={{
                                                     width: '30px',
                                                     height: '30px',
                                                     padding: '5px',
                                                     paddingBottom: '9px'
                                                 }}/> :
                                <img src="/assets/images/icons/phone_disabled.svg" alt="React Logo"
                                     style={{width: '30px', height: '30px', padding: '5px', paddingBottom: '9px'}}/>}
                            {item.OnLocation ? <img src="/assets/images/icons/location_on.svg" alt="React Logo"
                                                    style={{
                                                        width: '30px',
                                                        height: '30px',
                                                        padding: '5px',
                                                        paddingBottom: '9px'
                                                    }}/> :
                                <img src="/assets/images/icons/location_off.svg" alt="React Logo"
                                     style={{width: '30px', height: '30px', padding: '5px', paddingBottom: '9px'}}/>}
                        </ul>
                        <div className="product-description border-product">
                            <h6 className="product-title" style={{color: this.state.bookTypeColor}}>Select Appointment
                                Type</h6>

                            {/*<FormCheck inline label="1" type={type} id={`inline-${type}-1`}/>*/}
                            {/*<Form.Check*/}
                            {/*    disabled*/}
                            {/*    type={type}*/}
                            {/*    label={`disabled ${type}`}*/}
                            {/*    id={`disabled-default-${type}`}*/}
                            {/*/>*/}
                            <table style={{width: '100%'}}>
                                <tbody>
                                <tr>
                                    <td style={{display: this.state.onVideoEnable}}>
                                        <input type="radio" name="site_name"
                                               value={this.state.onVideoConf}
                                               checked={this.state.onVideoConf}
                                               onChange={() => {
                                                   this.setState({
                                                       onVideoConf: true,
                                                       onPhone: false,
                                                       onLocation: false,
                                                       bookTypeColor: "#000"
                                                   });
                                                   console.log("onVideoConf", this.state.onVideoConf);
                                               }}/> <span> Video Conference &nbsp;</span></td>
                                    <td style={{display: this.state.onPhoneEnable}}>
                                        <input type="radio" name="site_name"
                                               value={this.state.onPhone}
                                               checked={this.state.onPhone}
                                               onChange={() => {
                                                   this.setState({
                                                       onVideoConf: false,
                                                       onPhone: true,
                                                       onLocation: false,
                                                       bookTypeColor: "#000"
                                                   });
                                                   console.log("onPhone", this.state.onPhone);
                                               }}/><span> Phone &nbsp;</span></td>
                                    <td style={{display: this.state.onLocationEnable}}>
                                        <input type="radio" name="site_name"
                                               value={this.state.onLocation}
                                               checked={this.state.onLocation}
                                               onChange={() => {
                                                   this.setState({
                                                       onVideoConf: false,
                                                       onPhone: false,
                                                       onLocation: true,
                                                       bookTypeColor: "#000"
                                                   });
                                                   console.log("onLocation", this.state.onLocation);
                                               }}/><span> In Hospital &nbsp;</span></td>
                                </tr>
                                </tbody>
                            </table>

                        </div>
                        <div className="product-description border-product">
                            <h6 className="product-title" style={{color: this.state.bookTimeColor}}>Select Prefered Appointment
                                Time {this.state.bookingTime !== "" ? " : " + this.state.bookingTime.toLocaleString() : ""}</h6>
                            <DatePicker
                                showTimeSelect
                                selected={this.state.selectedDate}
                                minDate={this.state.startDate}
                                onChange={date => {
                                    this.setStartDate(date)
                                }}
                                maxDate={addDays(new Date(), item.DaysInAdvance)}
                                filterDate={isWeekday}
                                timeIntervals={60}
                                includeTimes={hrs}
                                inline
                            />
                        </div>
                        <div className="product-description border-product">
                            <span>{item.availableNow ? <span style={{color: '#1fca90'}}>Available Now</span> : ''}</span>
                        </div>
                        <div className="product-buttons">
                            <a href="/#" className="btn btn-solid"
                               onClick={() => this.onOpenModal()}>Get Opinion</a>
                            {/*<a className="btn btn-solid" onClick={() => this.getOpinion()}>Get*/}
                            {/*    Opinion & Estimate</a>*/}
                            <div className="btn btn-solid" style={{marginLeft: "10px"}}
                                 onClick={() => {
                                     let btype = false;
                                     let btime = false;
                                     let bookingType = "";
                                     let bookingTime = "";

                                     if (this.state.onVideoConf) {
                                         bookingType = "VIDEO_CONFERENCE";
                                     }
                                     if (this.state.onPhone) {
                                         bookingType = "PHONE";
                                     }
                                     if (this.state.onLocation) {
                                         bookingType = "IN_HOSPITAL";
                                     }

                                     if (bookingType === "") {
                                         this.setState({bookTypeColor: "#ff0200"})
                                     } else {
                                         btype = true;
                                     }

                                     if (this.state.bookingTime === "") {
                                         this.setState({bookTimeColor: "#ff0200"})
                                     } else {
                                         bookingTime = this.state.bookingTime;
                                         btime = true;
                                     }

                                     // console.log("quantity ", this.state.quantity, this.state);
                                     // console.log("booking time ", this.state.bookingTime);
                                     // console.log("booking type ", bookingType);

                                     if (btime && btype) {
                                         BuynowClicked(item, this.state.quantity, bookingType, bookingTime);
                                         window.open(`${process.env.PUBLIC_URL}/checkout`, '_self');
                                     }
                                     //BuynowClicked(item, this.state.quantity)
                                 }}>Book Now
                            </div>
                        </div>
                    </div>
                    :
                    <div className="product-right product-form-box">
                        <img src={"/assets/images/not-available.jpg"} className="img-fluid image_zoom_cls-0" alt=""/>
                    </div>}



                <Modal open={this.state.open} onClose={this.onCloseModal} center>
                    <div className="modal-dialog modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title" id="exampleModalLabel">Get Opinion</h5>
                            </div>
                            <div className="modal-body" style={{width:'500px'}}>
                                {/*<img src={`${process.env.PUBLIC_URL}/assets/images/size-chart.jpg`} alt=""*/}
                                {/*     className="img-fluid"/>*/}
                                <Form {...layout}
                                      name="nest-messages"
                                      layout="vertical"
                                      size="middle"
                                      validateMessages={validateMessages}>
                                    <Form.Item
                                        name={['user', 'name']}
                                        label="Name"
                                        rules={[{required: true,},]}>
                                        <Input/>
                                    </Form.Item>
                                    <Form.Item
                                        name={['user', 'email']}
                                        label="Email"
                                        rules={[{type: 'email',required: true},]}>
                                        <Input/>
                                    </Form.Item>

                                    <Form.Item
                                        name="phone"
                                        label="Phone Number"
                                        rules={[{ required: true, message: 'Please input your phone number!' }]}
                                    >
                                        <Input style={{ width: '100%' }} />
                                    </Form.Item>

                                    <Form.Item name={['user', 'introduction']} label="Introduction" rules={[{required: true},]}>
                                        <Input.TextArea rows={7} />
                                    </Form.Item>
                                </Form>
                                <a href="/#" className="btn btn-solid"
                                   onClick={() => this.sendMail()}>Send</a>
                            </div>
                        </div>
                    </div>
                </Modal>

                {/*<Modal open={this.state.open} onClose={this.onCloseModal} center>*/}
                {/*    <div className="modal-dialog modal-lg modal-dialog-centered" role="document">*/}
                {/*        <div className="modal-content quick-view-modal">*/}
                {/*            <div className="modal-body">*/}
                {/*                <div className="row">*/}
                {/*                    <h2 style={{margin: '20px'}}>Get Opinion</h2>*/}
                {/*                    <input type="text" className="form-control" id="email" placeholder="Name"*/}
                {/*                           name="email" style={{margin: '20px'}}*/}
                {/*                           value={this.state.email} onChange={this.handleChange} required=""/>*/}
                {/*                    <input type="text" className="form-control" id="email" placeholder="Email"*/}
                {/*                           name="email" style={{margin: '20px'}}*/}
                {/*                           value={this.state.email} onChange={this.handleChange} required=""/>*/}
                {/*                    <input type="text" className="form-control" id="email" placeholder="Phone"*/}
                {/*                           name="email" style={{margin: '20px'}}*/}
                {/*                           value={this.state.email} onChange={this.handleChange} required=""/>*/}
                {/*                    <textarea type="text" className="form-control" id="email"*/}
                {/*                              placeholder="Please describe your medical condition to the Doctor"*/}
                {/*                              style={{margin: '20px'}} name="email" value={this.state.email}*/}
                {/*                              onChange={this.handleChange} required=""/>*/}

                {/*                    <a href="javascript:void(0)" data-toggle="modal"*/}
                {/*                       title="Quick View"*/}
                {/*                       className="btn btn-solid"*/}
                {/*                       style={{margin: '20px'}}*/}
                {/*                       onClick={this.onCloseModal}>Send</a>*/}

                {/*                </div>*/}
                {/*            </div>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</Modal>*/}
            </div>
        )
    }
}


export default PriceDoctor;
