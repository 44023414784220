import React from 'react'
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import SubmenuList from '../headerSubmenuList'
import {

    getSuggestionsConditions, getSuggestionsDoctors, getSuggestionsHospitals, getSuggestionsSpeciality,
    getSuggestionsTreatments
} from '../../../../redux/actions'

const DoctorList = ({
                        suggTreatments,
                        suggConditions,
                        suggSpecialisations,
                        suggHospitals,
                        suggDoctors,
                        treatment,
                        handleSpeclChange,
                        condition,
                        doctor,
                        hospital,
                        specialisation
                    }) => {
    const getFinderData = () => {

        // if (!suggTreatments.length)
        //     getSuggestionsTreatments();
        //
        // if (!suggConditions.length)
        //     getSuggestionsConditions();
        //
        // if (!suggSpecialisations.length)
        //     getSuggestionsSpeciality();
        //
        // if (!suggHospitals.length)
        //     getSuggestionsHospitals();
        //
        // if (!suggDoctors.length)
        //     getSuggestionsDoctors();
    }
    React.useEffect(() => {
        getFinderData();

    }, [])

    return (
        <React.Fragment>
            <SubmenuList name="Doctor" value={doctor} handleSpeclChange={handleSpeclChange} optionValues={suggDoctors} speclName="Doctor"/>

            <SubmenuList name="Specialisation" value={specialisation} handleSpeclChange={handleSpeclChange} optionValues={suggSpecialisations} speclName="Speciality"/>
            <SubmenuList name="Hospital" value={hospital} handleSpeclChange={handleSpeclChange} optionValues={suggHospitals} speclName="Hospital"/>
            <SubmenuList name="Treatment" value={treatment} handleSpeclChange={handleSpeclChange} optionValues={suggTreatments} speclName="Treatment"/>
            <SubmenuList name="Condition" value={condition} handleSpeclChange={handleSpeclChange} optionValues={suggConditions}
                         speclName="Condition"/>


        </React.Fragment>


    )
}


const mapStateToProps = (state) => ({


    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,
})
export default connect(
    mapStateToProps, {

        getSuggestionsTreatments,
        getSuggestionsConditions,
        getSuggestionsSpeciality,
        getSuggestionsHospitals,
        getSuggestionsDoctors,

    }
)(withRouter(DoctorList));