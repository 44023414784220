import React, {Component} from 'react';
import {Link as ReactLink} from 'react-router-dom';


class HospitalSpecialities extends Component {
    constructor(props) {
        super();
        this.state = {
            item: '',
            prop: props
        };
    }

    componentDidMount() {
        let item = this.props.data;
        this.setState({item});

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            let item = this.props.data;
            this.setState({item});
        }
    }

    getKey = (id, index) => {
        return id.toString() + "-" + index.toString();
    }

    makeid = (length) => {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() *
                charactersLength));
        }
        return result;
    }

    render() {
        const item = this.state.item;

        // console.log("Props are ", this.props, this.state);
        const {spcl, hospi, clname, styl} = this.props
        // console.log("hospitalProcedure::item ", item);
        return (
            <>
                {(spcl && spcl.length) ? spcl.slice(0, 5).map((sp, index) => {
                    return (
                        <span key={sp.id ? sp.id : this.makeid(10)} className={clname} style={styl}>{sp.name}</span>
                    )
                }) : null}
            </>
        )
    }

}

export default HospitalSpecialities;