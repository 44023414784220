import React, {Component} from 'react';
import Slider from 'react-slick';
import {Link} from 'react-router-dom';

import {Slider3} from "../../../services/script"
import {getCategoryData} from "../../../api/apicalls";

class Article extends Component {

    constructor(props) {
        super(props);
        this.state = {
            params: {},
            articleBody: {},
            isLoading: true,
            invalidPage: false,
            category: '',
            slug: ''
        }
    }

    componentDidMount() {
        if (this.props.match.params.category !== '' && this.props.match.params.slug !== '') {
            console.log(this.props.match.params)
            this.getArticleBody(this.props.match.params.category, this.props.match.params.slug)
            this.setState({
                category: this.props.match.params.category,
                slug: this.props.match.params.slug
            })
        } else {
            this.setState({invalidPage: true})
        }
    }

    getArticleBody = (cat, slug) => {
        getCategoryData(cat, slug).then(articleBody => {
            console.log("SEARCH :: Category Data : ", articleBody)
            this.setState({articleBody, isLoading: false})
        })
    }

    render() {
        const {articleBody} = this.state;
        window.articleProps = this.props;
        window.articleState = this.state;

        return (
            <div>
                <section className="section-b-space" style={{backgroundColor: '#ECECEC'}}>
                    <div className="collection-wrapper">
                        <div className="container inframe">
                            <div className="row">
                                <div style={{width: '100%'}}>
                                    <div className="container">
                                        {articleBody.id ?
                                            <div className="row section-b-space">
                                                <div className="col-lg-9 map">
                                                    <h6 style={{textTransform: 'uppercase'}}>{this.state.category}</h6>
                                                    <h1 style={{fontSize: '40px'}}>{articleBody.name}</h1>
                                                    <h5>Also Known As: {articleBody.keywords}</h5>

                                                    {(articleBody.specialities && articleBody.specialities.length) ?
                                                        // <h6 style={{textTransform:'uppercase'}}>{articleBody.specialities}</h6>
                                                        <div>
                                                            Specialisation:
                                                            {articleBody.specialities.map((con, index) => {
                                                                return (<Link key={index} to={`${process.env.PUBLIC_URL}/search/india?Speciality=${con.id}`}>{con.name}</Link>)
                                                            })}
                                                        </div>
                                                        : null}

                                                    {(articleBody.speciality) ?
                                                        // <h6 style={{textTransform:'uppercase'}}>{articleBody.specialities}</h6>
                                                        <div>
                                                            Specialities:
                                                            <Link key={articleBody.speciality.id}
                                                                  to={`${process.env.PUBLIC_URL}/search/india?Speciality=${articleBody.speciality.id}`}>
                                                                {articleBody.speciality.name}
                                                            </Link>

                                                        </div>
                                                        : null}


                                                    {articleBody.description && articleBody.description.length ?
                                                        articleBody.description.map((para, index) => {
                                                            return (<div key={index}>
                                                                <h3>{para.Title}</h3>
                                                                <p style={{color: "#000", width: '100%'}} className="about-doctor">
                                                                    {para.body}
                                                                </p>
                                                            </div>)
                                                        })
                                                        : null}

                                                </div>
                                                <div className="col-lg-3">
                                                    <div className="contact-right">
                                                        {/*{articleBody.superspeciality.id ?*/}
                                                        {/*    // <h6 style={{textTransform:'uppercase'}}>{articleBody.specialities}</h6>*/}
                                                        {/*    <div>*/}
                                                        {/*        Super Speciality:*/}
                                                        {/*        <Link to={`${process.env.PUBLIC_URL}/search/india?Condition=${articleBody.superspeciality.id}`}>*/}
                                                        {/*            {articleBody.articleBody.name}*/}
                                                        {/*        </Link>*/}

                                                        {/*    </div>*/}
                                                        {/*    : null}*/}

                                                        {articleBody.conditions && articleBody.conditions.length ?
                                                            <div>
                                                                <h4 style={{color: '#1a458a', fontWeight: '700'}}>Related Conditions</h4>
                                                                <ul>
                                                                    {articleBody.conditions.map((con, index) => {
                                                                        return (<li key={index} style={{paddingLeft: '10px'}}>
                                                                            {/*https://hosplan.com/search/india?Treatment=60a3a02b1f88480aba281119*/}
                                                                            <Link to={`${process.env.PUBLIC_URL}/search/india?Condition=${con.id}`}>{con.name}</Link>
                                                                        </li>)
                                                                    })}
                                                                </ul>

                                                            </div>
                                                            : null}
                                                    </div>
                                                </div>
                                            </div>
                                            : null}


                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
            //
            //<div className="container">
            // <div className="row">
            // <div className="col-md-12">
            // <Slider {...Slider3} className="slide-3 no-arrow ">
            // <div>
            // <div className="col-md-12">
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <div className="classic-effect">
            // <img src={`${process.env.PUBLIC_URL}/assets/images/blog/1.jpg`} className="img-fluid" alt=""/>
            // <span></span>
            // </div>
            // </Link>
            // <div className="blog-details">
            // <h4>25 March 2019</h4>
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <p>Got best care possible </p></Link>
            // <hr className="style1"/>
            // <h6>by: John Doug , 2 Comment</h6>
            // </div>
            // </div>
            // </div>
            // <div>
            // <div className="col-md-12">
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <div className="classic-effect">
            // <img src={`${process.env.PUBLIC_URL}/assets/images/blog/2.jpg`} className="img-fluid" alt=""/>
            // <span></span>
            // </div>
            // </Link>
            // <div className="blog-details">
            // <h4>16 November 2019</h4>
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <p>Saved life when everyone said No </p></Link>
            // <hr className="style1"/>
            // <h6>by: Liam Nason , 1 Comment</h6>
            // </div>
            // </div>
            // </div>
            // <div>
            // <div className="col-md-12">
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <div className="classic-effect">
            // <img src={`${process.env.PUBLIC_URL}/assets/images/blog/3.jpg`} className="img-fluid" alt=""/>
            // <span></span>
            // </div>
            // </Link>
            // <div className="blog-details">
            // <h4>10 January 2020</h4>
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <p>Need better care come to Treatment Traveller </p></Link>
            // <hr className="style1"/>
            // <h6>by: Tom elies , 7 Comment</h6>
            // </div>
            // </div>
            // </div>
            // <div>
            // <div className="col-md-12">
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <div className="classic-effect">
            // <img src={`${process.env.PUBLIC_URL}/assets/images/blog/4.jpg`} className="img-fluid" alt=""/>
            // <span></span>
            // </div>
            // </Link>
            // <div className="blog-details">
            // <h4>18 May 2019</h4>
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}><p>Attention to patients is unbeatable </p></Link>
            // <hr className="style1"/>
            // <h6>by: Mark Walburg , 2 Comment</h6>
            // </div>
            // </div>
            // </div>
            // <div>
            // <div className="col-md-12">
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}>
            // <div className="classic-effect">
            // <img src={`${process.env.PUBLIC_URL}/assets/images/blog/5.jpg`} className="img-fluid" alt=""/>
            // <span></span>
            // </div>
            // </Link>
            // <div className="blog-details">
            // <h4>25 July 2019</h4>
            // <Link to={`${process.env.PUBLIC_URL}/blog/details`}><p>Best Care Possible </p></Link>
            // <hr className="style1"/>
            // <h6>by: Ethen Hunt , 2 Comment</h6>
            // </div>
            // </div>
            // </div>
            // </Slider>
            // </div>
            // </div>
            // </div>
        )
    }
}

export default Article;