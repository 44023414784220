import React, {Component} from 'react';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {connect} from "react-redux";
import {getTrendingTagCollection, getTrendingSpecialicationDoctors} from "../../../services";

import SideImageItem from "../common/side-image-item"
import DoctorCardSmall from "../../search/doctor-card-small";

class Trending extends Component {

    render() {
        const {symbol, home} = this.props;
        // console.log("TOP DOCTORS : ",home);

        let categories = ['Heart', 'onco', 'ortho', 'neuro']

        let cardio = home.featuredDoctors.doctors;
        let onco = home.featuredDoctors.doctors;
        let ortho = home.featuredDoctors.doctors;
        let neuro = home.featuredDoctors.doctors;


        return (
            <div>
                {/*Paragraph*/}
                <section className="p-0">
                    <div className="tab-bg">
                        <div className="container-fluid">
                            <div className="row">
                                <div className="col">
                                    <div className="title4">
                                        <h2 className="title-inner4" style={{color: '#000'}}>Top Doctors</h2>
                                        <div className="line"><span></span></div>
                                    </div>
                                    <Tabs className="theme-tab">
                                        <TabList className="tabs tab-title" style={{textTransform: 'capitalize'}}>
                                            <Tab>{categories[0]}</Tab>
                                            <Tab>{categories[1]}</Tab>
                                            <Tab>{categories[2]}</Tab>
                                            <Tab>{categories[3]}</Tab>
                                        </TabList>
                                        <div className="tab-content-cls">
                                            <TabPanel className="tab-content">
                                                <div className="row product-tab">
                                                    {cardio.map((item, i) =>
                                                        <div key={i} className="">
                                                            <DoctorCardSmall data={item} index={i}/>
                                                        </div>
                                                        // <div className="tab-box" key={i}>
                                                        //     {/*<SideImageItem product={item} symbol={symbol}/>*/}

                                                        // </div>
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel className="tab-content">
                                                <div className="row product-tab">
                                                    {onco.map((item, i) =>
                                                            <div key={i} className="">
                                                                <DoctorCardSmall data={item} index={i}/>
                                                            </div>
                                                        // <div className="tab-box" key={i}>
                                                        //     <SideImageItem product={item} symbol={symbol}/>
                                                        // </div>
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel className="tab-content">
                                                <div className="row product-tab">
                                                    {ortho.map((item, i) =>
                                                            <div key={i} className="">
                                                                <DoctorCardSmall data={item} index={i}/>
                                                            </div>
                                                        // <div className="tab-box" key={i}>
                                                        //     <SideImageItem product={item} symbol={symbol}/>
                                                        // </div>
                                                    )}
                                                </div>
                                            </TabPanel>
                                            <TabPanel className="tab-content">
                                                <div className="row product-tab">
                                                    {neuro.map((item, i) =>
                                                            <div key={i} className="">
                                                                <DoctorCardSmall data={item} index={i}/>
                                                            </div>
                                                        // <div className="tab-box" key={i}>
                                                        //     <SideImageItem product={item} symbol={symbol}/>
                                                        // </div>
                                                    )}
                                                </div>
                                            </TabPanel>
                                        </div>
                                    </Tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}


const mapStateToProps = (state, ownProps) => ({
    cardio: getTrendingSpecialicationDoctors(state.data.products, ownProps.type, 'Cardiology'),
    onco: getTrendingSpecialicationDoctors(state.data.products, ownProps.type, 'Oncology'),
    ortho: getTrendingSpecialicationDoctors(state.data.products, ownProps.type, 'Orthopedics'),
    neuro: getTrendingSpecialicationDoctors(state.data.products, ownProps.type, 'Neurosurgery'),
    symbol: state.data.symbol
})
export default connect(mapStateToProps)(Trending);