import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../commonAll/breadcrumb";
import FilterHospital from "./common/filter-hospital";
import FilterBar from "./common/filter-bar";
import HospitalListing from "./common/hospital-listing_backup";
import StickyBox from "react-sticky-box";

class Hospitals extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openFilter: false,
            layoutColumns: 3,
            //city: (this.props.params.city)?this.props.params.city:"INDIA",
            city: "INDIA",
            spcl: [],
            topic: '',
            sticky: false,
            rate: [0, 5],
            drate: [0, 5],
            dcity: [],
            dCheckedCity: [],
            dprices: [0, 15000],
            gender: {male: false, female: false},
            experience: [0, 100],
            availableFor: {videoConference: false, onCall: false, inHospital: false},
            flag: false,
            cat: '',
            slug: '',
            introData: {},
            rateSelected: 0,
            docExp: [0, 100],
        }
        //console.log("props",props);
    }

    // state = {
    //
    // }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums
        })
    }

    componentDidMount() {
        const {match: {params}} = this.props;
        console.log("HOSPITALS : ", params);
        this.setState({city: params.city});
    }

    handleRate = (value) => {
        console.log("handleRate", value)
        this.setState({rateSelected: value})
    }

    handleCheckedCity = (value) => {

        this.setState({dCheckedCity: value})
        this.setState({flag: !this.state.flag})
        console.log("handleCheckedCity", value)
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    render() {
        const {match: {params}} = this.props;
        window.srcHpageProps = this.props;
        window.srcHpageState = this.state;

        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Treatment Excellence</title>
                    <meta name="description" content="Hospitals"/>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={'Hospitals'}/>

                <section className="section-b-space">
                    <div className="collection-wrapper">
                        <div className="container">
                            <div className="row">
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            {/*<Filter/>*/}
                                            <FilterHospital handleCheckedCity={this.handleCheckedCity} handleNewRate={this.handleRate} selectedcity={params.city}/>
                                            {/*<NewProduct/>*/}
                                            {/*<div className="collection-sidebar-banner">*/}
                                            {/*    <a href="#">*/}
                                            {/*        <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                        </div>
                                    </StickyBox>
                                    {/*side-bar banner end here*/}
                                </div>
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    <div className="top-banner-wrapper">
                                                        {/*<a href="#"><img src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`} className="img-fluid" alt=""/></a>*/}
                                                        {/*<div className="top-banner-content small-section">*/}
                                                        {/*    <h4>fashion</h4>*/}
                                                        {/*    <h5>Lorem Ipsum is simply dummy text of the printing and typesetting industry.</h5>*/}
                                                        {/*    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. </p>*/}
                                                        {/*</div>*/}
                                                    </div>
                                                    <div className="collection-product-wrapper">
                                                        {/*<div className="product-top-filter">*/}
                                                        {/*    <div className="container-fluid p-0">*/}
                                                        {/*        <div className="row">*/}
                                                        {/*            <div className="col-xl-12">*/}
                                                        {/*                <div className="filter-main-btn">*/}
                                                        {/*                    <span onClick={this.openFilter}*/}
                                                        {/*                          className="filter-btn btn btn-theme">*/}
                                                        {/*                        <i className="fa fa-filter"*/}
                                                        {/*                           aria-hidden="true"/> Filter</span>*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        /!*<div className="row">*!/*/}
                                                        {/*        /!*    /!*<div className="col-12">*!/*!/*/}
                                                        {/*        /!*    /!*    <FilterBar onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>*!/*!/*/}
                                                        {/*        /!*    /!*</div>*!/*!/*/}
                                                        {/*        /!*</div>*!/*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}
                                                        {/*Products Listing Component*/}
                                                        {/*<ProductListing colSize={this.state.layoutColumns}/>*/}
                                                        <HospitalListing colSize={this.state.layoutColumns}/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        )
    }
}

export default Hospitals;