import React, {Component} from "react";
import "../commonAll/index.scss";
import {connect} from "react-redux";
import ImageZoom from "../products/common/product/image-zoom";
import SmallImages from "../products/common/product/small-image";
import {addToCart, addToCartUnsafe, addToWishlist} from "../../redux/actions";
import Slider from "react-slick";
import DoctorBooking from "../products/common/product/Doctor-Booking";
import {Rate, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {Typography} from "antd";
import HospitalSpecialities from "../products/common/product/hospital-specialitis";
import {CommentOutlined, FileDoneOutlined, PhoneOutlined, ScheduleOutlined} from "@ant-design/icons";

const {Text, Title} = Typography;

class DoctorCardSmall extends Component {
    constructor(props) {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            doctors: [],
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        let doctor = this.props.data;
        // console.log("hospital", this.props.data);

        this.setState({doctor});
        // console.log("doctor-card::doctor", doctor);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            let doctor = this.props.data;

            this.setState({doctor});
        }
    }

    render() {
        const {
            symbol,
            addToCart,
            addToCartUnsafe,
            addToWishlist,
            auth,
        } = this.props;
        let products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true,
        };
        let productsnav = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        };


        const item = this.state.doctor;
        // const placeholder = `${process.env.PUBLIC_URL}/assets/images/doctors/Doctor-Male-Placeholder.jpg`;
        const placeholder = `https://hosplan.com/assets/images/doctors/Doctor-Male-Placeholder.jpg`;

        // console.log("Doctor Card : ", item)

        return (
            <div>
                {item ? (

                    <div className="col dSmallCard"
                         style={{
                             // paddingLeft: '25px'
                             // borderRight: "1px solid #d4d4d4",
                             // borderTop: "3px solid #14a3dd",
                             // borderBottom: "2px solid #d4d4d4",
                         }}>

                        {/* className="col-lg-3 col-md-3" */}
                        <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}>
                            <div className="row" style={{width: '280px'}}>
                                <div style={{padding: "0px"}}>

                                    <ImageZoom image={(item.Pictures && item.Pictures.length) ? item.Pictures[0].url : placeholder}
                                               style={{height: "70px", width: "55px", objectFit: 'cover', borderBottomLeftRadius: '3px'}}
                                               dimensions={{width: '55',height:'70'}}
                                    />

                                </div>
                                {/* className="col-lg-9 col-md-9" */}
                                <div style={{marginLeft: "10px", width: "210px"}}>

                                    <h3 style={{marginBottom: "-5px", fontSize: '14px'}}>
                                        {" "}
                                        {item.Name}{" "}
                                        {item.Verified ? (
                                            <img
                                                src={`${
                                                    process.env.PUBLIC_URL
                                                }/assets/images/icons/verified.svg`}
                                                style={{width: "20px", paddingBottom: "10px"}}
                                                alt={"verified"}
                                            />
                                        ) : (
                                            ""
                                        )}
                                    </h3>


                                    {item.Designation ? (
                                        <h2 style={{fontSize: '12px', marginTop: "5px", textTransform: "capitalize"}}>
                                            {item.Designation}
                                            {/*<Text>{item.Department}</Text>*/}
                                        </h2>
                                    ) : null}
                                    {item.Department ? (
                                        <h2 style={{fontSize: '12px', marginTop: "5px", textTransform: "capitalize"}}>
                                            {item.Department}
                                            {/*<Text>{item.Department}</Text>*/}
                                        </h2>
                                    ) : null}
                                    <div className="product-description border-product mobHide">
                                        <div className="rating">

                                            {/*<Text style={{fontSize: '12px'}}>*/}
                                            {/*    Rating on Google :*/}
                                            {/*</Text>*/}

                                            <Rate
                                                disabled
                                                allowHalf
                                                value={item.googleRating ? item.googleRating : item.Rating}
                                                style={{color: "#ffa201", fontSize: '12px'}}
                                            />{" "}
                                            <br/>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </Link>

                        <div className="row mobHide" style={{width: '270px', justifyContent: 'space-between'}}>
                            <div>
                                <Tooltip title="Book Appointment" placement={"topRight"}>
                                    <button className="emptyBtn">
                                        <ScheduleOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '7px'}}/>
                                        {/*<CommentOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '7px'}}/>*/}
                                        Appointment
                                    </button>
                                </Tooltip>
                            </div>
                            <div>
                                <Tooltip title="Book Tele Consultation session" placement={"topRight"}>
                                    <button className="emptyBtn" style={{paddingRight: '10px'}}>
                                        <PhoneOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '7px'}}/>
                                        {/*<CommentOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '7px'}}/>*/}
                                        Tele Consult
                                    </button>
                                </Tooltip>
                            </div>
                        </div>


                    </div>


                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.data.slug;
    return {
        item: state.data.products.find((el) => el.id == productId),
        symbol: state.data.symbol,
        doctors: state.doctors,
        did: productId,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
    {addToCart, addToCartUnsafe, addToWishlist}
)(DoctorCardSmall);
//export default Doctor;
