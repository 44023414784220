import React, {Component} from 'react'
import {connect} from "react-redux";
import {withRouter} from 'react-router-dom';
import SubmenuList from '../headerSubmenuList'
import {
    getSuggestionsConditions, getSuggestionsDoctors, getSuggestionsHospitals, getSuggestionsSpeciality,
    getSuggestionsTreatments, populateLocal
} from '../../../../redux/actions'
import {getLimitedSearchData, getParams, getSearchParamID} from "../../../../api/apicalls";
import _ from "lodash";


class TreatmentList extends Component {

    constructor(props) {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            doctors: [],

            sgTreatments: [],
            sgConditions: [],
            sgSpeciality: [],
            sgSuperSpeciality: [],
            sgHospitals: [],
            sgDoctors: [],

        };
    }

    componentDidMount() {
        // this.defaultParams()
        // this.getFinderData()
    }

    getFinderData = () => {

        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href)
        // let prms = [];
        //
        // if (Object.keys(params).length && Object.keys(params)[0] !== '') {
        //     Object.keys(params).map((param, idx) => {
        //         console.log("SEARCH :: The individual Param is : ", param, ' - ', params[param]);
        //         prms.push(getSearchParamID(param, params[param].replaceAll('-', ' ')))
        //     })
        // }
        // console.log("Suggestiions : ", params);

        if (Object.keys(params).length && Object.keys(params)[0] !== '') {
            console.log("SUGG : I will need to filter the result")
            // getSearchParamID(Object.keys(params)[0], Object.values(params)[0]).then(cat => {
            //     console.log("SUGG : fond category for filter : ", cat)
            //     getLimitedSearchData(Object.keys(params)[0], Object.values(params)[0]).then(resp => {
            //         console.log("SUGG : limited Search : ", resp)
            //         // suggConditions = resp.condition
            //         this.setState({
            //             sgTreatments: [...resp.treatment, resp.param],
            //             sgConditions: resp.condition,
            //             sgSpeciality: resp.speciality,
            //             sgSuperSpeciality: resp.superspeciality,
            //             sgHospitals: resp.hospitals,
            //             sgDoctors: resp.doctors,
            //         })
            //     })
            // })
        } else {
            // console.log("SUGG : Do Not Run Filter")
        }

        // populateLocal("data","type")

        if (!this.props.suggTreatments.length)
            getSuggestionsTreatments();

        if (!this.props.suggConditions.length)
            getSuggestionsConditions();

        if (!this.props.suggSpecialisations.length)
            getSuggestionsSpeciality();

        if (!this.props.suggHospitals.length)
            getSuggestionsHospitals();

        if (!this.props.suggDoctors.length)
            getSuggestionsDoctors();
    }
    // React.useEffect(() => {
    //     getFinderData();
    //
    // }, [])

    populateAll(options, data) {
        // let filtered = [...this.props.optionValues, ...data]

        let uniqueUsersByID = _.uniqBy([...options, ...data], 'id');
        this.props.populateLocal(uniqueUsersByID, this.props.speclName)
    }

    defaultParams = () => {
        this.setState({
            sgTreatments: this.props.suggTreatments,
            sgConditions: this.props.suggConditions,
            sgSpeciality: this.props.suggSpecialisations,

            sgHospitals: this.props.suggHospitals,
            sgDoctors: this.props.suggDoctors,
        })

    }

    render() {
        const {
            handleSpeclChange,
            treatment,
            suggTreatments,
            condition,
            suggConditions,
            specialisation,
            suggSpecialisations,
            hospital,
            suggHospitals,
            doctor,
            suggDoctors
        } = this.props;

        // const {sgTreatments, sgConditions, sgSpeciality, sgSuperSpeciality, sgHospitals, sgDoctors} = this.state;

        return (
            <React.Fragment>

                <SubmenuList name="Condition" value={condition} handleSpeclChange={handleSpeclChange}
                             optionValues={suggConditions}
                             speclName="Condition" example={"eg. Breast Cancer"}/>

                <SubmenuList name="Treatment" value={treatment} handleSpeclChange={handleSpeclChange}
                             optionValues={suggTreatments} speclName="Treatment" example={"eg. Bypass"}/>

                <SubmenuList name="Specialisation" value={specialisation} handleSpeclChange={handleSpeclChange}
                             optionValues={suggSpecialisations} speclName="Speciality" example={"eg. Cardiology"}/>

                <SubmenuList name="Hospital" value={hospital} handleSpeclChange={handleSpeclChange}
                             optionValues={suggHospitals} speclName="Hospital" example={"eg. Apollo"}/>

                <SubmenuList name="Doctor" value={doctor} handleSpeclChange={handleSpeclChange}
                             optionValues={suggDoctors}
                             speclName="Doctor" example={"eg. Naresh Trehan"}/>
            </React.Fragment>


        );
    }
}


const mapStateToProps = (state) => ({
    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,
})
export default connect(
    mapStateToProps, {
        getSuggestionsTreatments,
        getSuggestionsConditions,
        getSuggestionsSpeciality,
        getSuggestionsHospitals,
        getSuggestionsDoctors,
        populateLocal
    }
)(withRouter(TreatmentList));