import React, {Component} from "react";
import {Select, Spin} from "antd";
import _ from "lodash";
import {getSearchParamID} from "../../../api/apicalls";
import {connect} from "react-redux";
import {populateLocal} from "../../../redux/actions";
import {withRouter} from "react-router-dom";
import {
    HomeOutlined,
    SettingFilled,
    SmileOutlined,
    SyncOutlined,
    LoadingOutlined,
} from '@ant-design/icons';
import {Empty} from 'antd';
import {Tooltip} from "antd";

const {Option} = Select;

// const submenuList = ({name, value, handleSpeclChange, optionValues, speclName}) => {}
class submenuList extends Component {
    constructor(props) {
        super();
        this.state = {
            // optionsList: (this.props && this.props.optionValues) ? [...this.props.optionValues] : []
            optionsList: [],
            loading: false,
        };
    }

    componentDidMount() {
        if (this.props.optionValues && this.props.optionValues.length) {
            this.setState({optionsList: [...this.props.optionValues]});
            // console.log("Found in : optionValues", this.props.speclName);
        } else {
            // console.log("Did not find anything in : optionValues",this.props.speclName);
        }
        // if (this.props.optionValues && this.props.optionValues.length) {
        //     this.setState({optionsList: [...this.props.optionValues]})
        //     console.log("Found in : optionValues", this.props)
        // } else {
        //     // console.log("Did not find anything in : optionValues", this.props)
        //     getSearchParamID(this.props.speclName, '', 5).then(res => {
        //         // console.log("For :", speclName, ", Value ", val)
        //         // console.log("POP search responce is :", res);
        //         console.log("POP Type : ", this.props.speclName, 'Data :', res);
        //         // optionValues=[]
        //         this.setState({optionsList: [...res]})
        //         // this.populateAll([],res)
        //         this.props.populateLocal(res, this.props.speclName)
        //     })
        // }
    }

    populateAll(options, data) {
        // let filtered = [...this.props.optionValues, ...data]
        // console.log("Populate :", this.props.speclName)
        let uniqueUsersByID = _.uniqBy([...options, ...data], "id");
        this.props.populateLocal(uniqueUsersByID, this.props.speclName);
    }

    getText(val, example, opration = "cut") {
        let txt = val ? val : example;
        if (val) {
            let t = _.find(this.state.optionsList, {slug: val})
            // console.log("t is :", t, this.state.optionsList)
            if (opration === 'cut') {
                if (t && t.label) {
                    // txt = t.label.substring(15)
                    if (t.label.indexOf(" ") === -1) {
                        txt = ''
                    } else {
                        txt = t.label.substr(t.label.indexOf(" ") + 1);
                    }
                }
            } else {
                if (t && t.label) {
                    // txt = t.label.substring(15)
                    txt = t.label;
                }
            }


        }
        return txt
    }

    render() {
        const {
            name,
            value,
            handleSpeclChange,
            optionValues,
            speclName,
            example,
        } = this.props;

        // console.log("Render for ", name, value, handleSpeclChange, optionValues, speclName)

        const {optionsList} = this.state;

        // console.log(speclName, optionsList)

        return (
            <li className="li-list">
                <h4 style={{fontSize: '15px'}}>{name}</h4>
                <Select
                    // mode="multiple"
                    virtual={true}

                    allowClear
                    showSearch
                    showArrow={false}
                    bordered={false}
                    notFoundContent={
                        this.state.loading ? (
                            <div style={{textAlign: "center", padding: '40px'}}>
                                <Spin size="large"/>
                                {/*<SyncOutlined spin size="middle"/>*/}
                            </div>
                        ) : null
                        // <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}
                        //        description={<span>Searched Parameter not Found</span>}/>
                    }
                    //border:'1px solid #777'
                    style={{
                        width: "100%", textTransform: "capitalize", fontSize: value ? '14px' : '24px',
                        whiteSpace: "nowrap",
                        overflowWrap: "break-word",
                        display: 'inline-block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                    }}
                    dropdownStyle={{width: "100%"}}
                    placeholder="Search"
                    defaultValue={value ? value : []}
                    onChange={(e, opt) => {
                        // console.log("User Selected : ", e, opt);
                        handleSpeclChange(e, "", speclName)
                    }}
                    className={"headerSearch"}
                    dropdownClassName="headerDrop"
                    // labelInValue={true}
                    // filterSort={(optionA, optionB) =>
                    //     optionA.children.toLowerCase().localeCompare(optionB.children.toLowerCase())
                    // }
                    // optionFilterProp={"label"}
                    optionFilterProp={"content"}
                    onSearch={(val) => {
                        // console.log("Searching Val :", val);
                        if (val.length > 1) {
                            this.setState({loading: true});
                            getSearchParamID(speclName, val, 50).then((res) => {
                                // console.log("POP For :", speclName, ", Value ", val)
                                // console.log("POP search responce is :", res);
                                // optionValues=[]

                                if (this.state.optionsList && this.state.optionsList.length) {
                                    let uniqueUsersByID = _.orderBy(_.uniqBy([...this.state.optionsList, ...res], "id"), ['label'], ['asc']);
                                    this.setState({optionsList: [...uniqueUsersByID], loading: false});
                                } else {
                                    let r2 = _.orderBy(res, ['label'], ['asc']);
                                    this.setState({optionsList: [...r2], loading: false});
                                }

                                this.populateAll(optionValues ? optionValues : [], res);
                            });
                        }
                    }}
                >
                    {optionsList.map((item, index) => {
                        // console.log("Item", speclName, item)
                        return (
                            <Option key={index} value={item.slug} label={item.label} content={`${item.label} ${item.keywords} ${item.meta}`}>
                                <div>
                                    <span style={{
                                        textTransform: 'capitalize',
                                        whiteSpace: "nowrap",
                                        overflowWrap: "break-word",
                                        display: 'inline-block',
                                        textOverflow: 'ellipsis',
                                        overflow: 'hidden',
                                        width: '100%'
                                    }}>{item.label}</span>
                                    <br/>
                                    {item.keywords ? (
                                        <div>
                                            {/*<span style={{fontSize: "12px", fontWeight: "700", textTransform:'capitalize'}}>*/}
                                            {/*  AKA :{" "}*/}
                                            {/*</span>*/}
                                            <span style={{fontSize: "12px", fontWeight: "500", textTransform: 'capitalize'}}>
                                              {item.keywords}
                                            </span>
                                        </div>
                                    ) : null}
                                </div>
                            </Option>
                        );
                    })}
                </Select>
                <Tooltip title={this.getText(value, example, 'full')} placement={"bottom"}>
                <span style={{
                    color: '#777', fontSize: '14px', marginTop: '10px',
                    whiteSpace: "nowrap",
                    overflowWrap: "break-word",
                    display: 'inline-block',
                    // textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    // width:'100%'
                    maxWidth: '121px',
                }}>{this.getText(value, example)}</span>
                </Tooltip>

            </li>
        );
    }
}

const mapStateToProps = (state) => ({
    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,
});
export default connect(
    mapStateToProps,
    {
        populateLocal,
    }
)(withRouter(submenuList));
// export default submenuList;
