import React, {Component} from "react";
import "../commonAll/index.scss";
import {connect} from "react-redux";
import ImageZoom from "../products/common/product/image-zoom";
import SmallImages from "../products/common/product/small-image";
import {addToCart, addToCartUnsafe, addToWishlist} from "../../redux/actions";
import Slider from "react-slick";
import DoctorBooking from "../products/common/product/Doctor-Booking";
import {Rate, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {Typography} from "antd";
import HospitalSpecialities from "../products/common/product/hospital-specialitis";
import {CommentOutlined, FileDoneOutlined} from "@ant-design/icons";
import DoctorCardSmall from "./doctor-card-small";

const {Text, Title} = Typography;

class Doctor extends Component {
    constructor(props) {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            doctors: [],
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        let doctor = this.props.data;
        // console.log("hospital", this.props.data);

        this.setState({doctor});
        // console.log("doctor-card::doctor", doctor);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            let doctor = this.props.data;

            this.setState({doctor});
        }
    }

    render() {
        const {
            symbol,
            addToCart,
            addToCartUnsafe,
            addToWishlist,
            auth,
        } = this.props;
        let products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true,
        };
        let productsnav = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        };


        const item = this.state.doctor;
        // const placeholder = `${process.env.PUBLIC_URL}/assets/images/doctors/Doctor-Male-Placeholder.jpg`;
        const placeholder = `https://hosplan.com/assets/images/doctors/Doctor-Male-Placeholder.jpg`;

        // console.log("Doctor Card : ", item)

        return (
            <div>
                {item ? (
                    <div className="row searchCard"
                         style={{
                             borderRight: "1px solid #d4d4d4",
                             borderTop: "3px solid #14a3dd",
                             borderBottom: "2px solid #d4d4d4",
                             padding: "0px",
                             marginLeft: '0px',
                             marginRight: '0px'
                         }}>
                        <div className="mobOnly"><DoctorCardSmall data={item} index={item.id}/></div>
                        <div className="col-lg-3 col-md-4 mobHide" style={{padding: "0px"}}>
                            <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}>
                                <ImageZoom image={(item.Pictures && item.Pictures.length) ? item.Pictures[0].url : placeholder}
                                           style={{height: "220px", width: "100%", objectFit: 'cover', borderBottomLeftRadius: '3px'}}/>
                                {/* {item.Pictures.length ?
                                        <Slider {...products} asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                            className="product-right-slick">
                                            {item.Pictures.map((vari, index) =>
                                                <div key={index}>
                                                    <ImageZoom image={vari.url} style={{
                                                      height:"220px",
                                                      width:"100%"
                                                    }}
                                                        className="img-fluid image_zoom_cls-0" />
                                                </div>
                                            )}
                                        </Slider>
                                        : <Slider {...products} asNavFor={this.state.nav2}
                                            ref={slider => (this.slider1 = slider)}
                                            className="product-right-slick">
                                            <div>
                                                <ImageZoom
                                                    image={"/assets/images/doctors/Doctor-Male-Placeholder.jpg"}
                                                   style={{ height:"220px",
                                                      width:"100%"
                                                    }}
                                                    className="img-fluid image_zoom_cls-0" />
                                            </div>
                                        </Slider>} */}
                            </Link>
                        </div>
                        <div className="col-lg-6 col-md-8 mobHide" style={{marginTop: '5px'}}>
                            <Link to={`${process.env.PUBLIC_URL}/doctor/${item.slug}`}>
                                <h3 style={{marginBottom: "-5px", fontSize: '20px'}}>
                                    {" "}
                                    {item.Name}{" "}
                                    {item.Verified ? (
                                        <img
                                            src={`${
                                                process.env.PUBLIC_URL
                                            }/assets/images/icons/verified.svg`}
                                            style={{width: "20px", paddingBottom: "10px"}}
                                            alt={"verified"}
                                        />
                                    ) : (
                                        ""
                                    )}
                                </h3>
                            </Link>
                            <Title style={{margin: "2px 0 0 0", fontSize: '15px', color: '#595959'}} level={4}>
                                {item.Designation}
                            </Title>

                            {/*<Text>*/}
                            {/*    Department:<Text strong>{item.Department}</Text>*/}
                            {/*</Text>*/}
                            {/*<Text>*/}
                            {/*    Department:<Text strong>{item.Department}</Text>*/}
                            {/*</Text>*/}
                            {item.Department ? (
                                <Text style={{fontSize: '12px'}}>
                                    Department:<Text strong>{item.Department}</Text>
                                </Text>
                            ) : null}
                            <br/>
                            {item.hospital ?
                                <Text style={{fontSize: '12px'}}>
                                    Hospital : <Text strong><Link to={`${process.env.PUBLIC_URL}/hospital/${item.hospital.Alias}`}
                                                                  style={{color: '#0e509f'}}>{' ' + item.hospital.Name}</Link></Text>
                                </Text>
                                : null}

                            <br/>
                            <div className="product-description border-product">
                                <div className="rating">

                                    <Text style={{fontSize: '12px'}}>
                                        Rating on Google :
                                    </Text>

                                    <Rate
                                        disabled
                                        allowHalf
                                        value={item.googleRating ? item.googleRating : item.Rating}
                                        style={{color: "#ffa201", fontSize: '12px'}}
                                    />{" "}
                                    <br/>
                                </div>
                            </div>
                            {item.specialities ? (
                                <React.Fragment>
                                    <Text>Specialisations:</Text>
                                    <div>
                                        <HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{
                                            color: '#9a9a9a',
                                            fontSize: '10px',
                                            textTransform: 'none',
                                            padding: '0.1rem 0.4rem',
                                            background: '#ececec',
                                        }}/>
                                    </div>
                                </React.Fragment>
                            ) : null}

                            {/* <Text>Designation:<Text strong>{item.Designation}</Text></Text><br /> */}


                            {/*<Text>Specialities:</Text>*/}
                            {/*<br/>*/}
                            {/*<Text strong>{item.Specialization?item.Specialization.toString():"N/A"}</Text>*/}
                            {/*<br/>*/}
                            {/*<Rate*/}
                            {/*    disabled*/}
                            {/*    value={item.Rating}*/}
                            {/*    style={{fontSize: 17, color: "#ffa201"}}*/}
                            {/*/>*/}

                            <div
                                className="row"
                                style={{
                                    // display: "flex",
                                    justifyContent: "space-between",
                                    marginTop: "5px",
                                    borderTop: '1px solid #d4d4d4'
                                }}
                            >
                                <div>
                                    <Tooltip title="Add to Favorite">
                                        <button className="emptyBtn">
                                            <i className="fa fa-heart" style={{fontSize: '20px', color: '#a0a0a0', padding: '12px'}}></i>
                                        </button>
                                    </Tooltip>
                                </div>
                                <div>

                                </div>
                                <div>
                                    <Tooltip title="Get Opinion">
                                        <button className="emptyBtn">
                                            <FileDoneOutlined style={{fontSize: '20px', color: '#a0a0a0', padding: '12px'}}/>
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Start Live Chat">
                                        <button className="emptyBtn">
                                            <CommentOutlined style={{fontSize: '20px', color: '#a0a0a0', padding: '12px'}}/>
                                        </button>
                                    </Tooltip>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-3 mobHide" style={{borderLeft: "1px solid #d4d4d4"}}>
                            <div style={{width: "100%", textAlign: "center"}}>
                                <span style={{textAlign: "center"}}>EXPERIENCE</span>
                            </div>

                            {/*<br/>*/}
                            <Title level={4} style={{textAlign: "center"}}>
                                {item.Experience} Years
                            </Title>
                            <DoctorBooking symbol={symbol} item={item}/>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    let productId = ownProps.data.slug;
    return {
        item: state.data.products.find((el) => el.id == productId),
        symbol: state.data.symbol,
        doctors: state.doctors,
        did: productId,
        auth: state.auth,
    };
};

export default connect(
    mapStateToProps,
    {addToCart, addToCartUnsafe, addToWishlist}
)(Doctor);
//export default Doctor;
