import React from "react";

import {GoogleApiWrapper, Map, Marker} from "google-maps-react";

class MapContainer extends React.Component {


    render() {
        const {item} = this.props;
        const mapStyles = {
            width: "100%",
            height: "100%",
            top: "0px",
        };
        return(
            <div
                style={{ position: "relative", paddingBottom: "56.25%" }}
            >
                {item.Geo ? (
                    <Map
                        google={this.props.google}
                        zoom={14}
                        style={mapStyles}
                        initialCenter={{
                            lat: item.Geo.Latitude,
                            lng: item.Geo.Longitude,
                        }}
                    >
                        <Marker name={item.name} />
                    </Map>
                ) : null}
            </div>
        )
    }
}

export default GoogleApiWrapper({
    apiKey: "AIzaSyClT2rSG4_sAQr3HPFxxenREGLsr__Pk9c",
})(MapContainer);