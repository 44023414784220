import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Text from "antd/lib/typography/Text";
import {Empty, Popover} from "antd";
import HospitalSpecialities from "../../products/common/product/hospital-specialitis";
import DoctorCardSmall from "../doctor-card-small";
import _ from 'lodash';

class SpclDoctors extends Component {

    getDoc = (doctor, spcl, indx) => {
        return (
            <div key={indx} className="">
                <DoctorCardSmall data={doctor} index={indx}/>
            </div>
        )
    }

    render() {
        const {item, spcl} = this.props;
        return (
            <div className="container-fluid">
                <div className=""
                     style={{
                         margin: "0px", height: "175px", overflowX: "auto", overflowY: "hidden", border: "1px solid #ececec", display: 'flex',
                         flexDirection: 'row',
                         padding: "10px",
                         backgroundColor: '#ececec',
                         boxShadow: 'inset 0 0 10px #00000026'
                     }}>
                    {item.doctors.length ? item.doctors.map((doctor, indx) => {
                            // this.getDoc(doctor, spcl, indx)
                            // if()
                            let found = _.intersection(doctor.specialities, spcl);

                            if(found.length){
                                return (
                                    <div key={indx} className="">
                                        <DoctorCardSmall data={doctor} index={indx}/>
                                    </div>
                                )
                            }else{
                                return null
                            }

                        })
                        : <div><Empty description={
                            <span>
                            No Doctors Found
                        </span>
                        }/></div>}
                </div>
            </div>
        )
    }
}

export default SpclDoctors;