import React, {Component} from "react";
import "../commonAll/index.scss";
import ImageZoom from "../products/common/product/image-zoom";
// import hospitalPlaceholder from "../products/common/product/image-zoom";
// import SmallImages from "../products/common/product/small-image";

import Slider from "react-slick";
import {Rate, Tooltip} from "antd";
import {Link} from "react-router-dom";
import {Typography} from "antd";
import HospitalSpecialities from "../products/common/product/hospital-specialitis";
import {
    CalculatorOutlined,
    CalculatorFilled,
    SolutionOutlined,
    MessageOutlined,
    CommentOutlined,
    // CommentFilled,
    FileDoneOutlined,
    // FileDoneFilled,
} from '@ant-design/icons';
import HospitalSpclType from "./common/hospitalSpclType";
import Accredetion from "./common/accredetion";
import {getDoctors as searchDoctors, getParams, getURLq} from "../../api/apicalls";
import DoctorCardSmall from "./doctor-card-small";
import _ from "lodash";

const {Text} = Typography;

class Hospital extends Component {
    constructor(props) {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            doctors: [],
            urlQ: '',
        };
    }

    componentDidMount() {
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        let hospital = this.props.data;
        // console.log("hospital-card", this.props.data);

        this.setState({hospital});
        if (this.props.section === "all") {
            this.findDoctors(hospital, this.props.query);
        }
        // eslint-disable-next-line no-restricted-globals
        let urlQ = getURLq(location.href);
        if (urlQ) {
            this.setState({urlQ: "?" + urlQ})
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (JSON.stringify(prevProps.data) !== JSON.stringify(this.props.data)) {
            let hospital = this.props.data;
            this.setState({hospital});
        }
    }

    findDoctors = (hos, params) => {
        let docs = [];
        let docExp = [0, 100];
        if (this.props.docExp && this.props.docExp.length) {
            docExp = [...this.props.docExp]
        }
        if (hos.doctors.length) {
            hos.doctors.map(doc => {
                // console.log(doc, params)
                params.map(p => {
                    if (p.category === "condition") {
                        if (doc.conditions.includes(p.id)) {
                            if (doc.Experience >= docExp[0] && doc.Experience <= docExp[1]) {
                                docs.push(doc)
                            }
                        }
                    }
                    if (p.category === "treatment") {
                        if (doc.treatments.includes(p.id)) {
                            if (doc.Experience >= docExp[0] && doc.Experience <= docExp[1]) {
                                docs.push(doc)
                            }
                        }
                    }
                    if (p.category === "specialisation") {
                        if (doc.superspecialities.includes(p.id)) {
                            if (doc.Experience >= docExp[0] && doc.Experience <= docExp[1]) {
                                docs.push(doc)
                            }
                        }
                    }
                    if (p.category === "speciality") {
                        if (doc.specialities.includes(p.id)) {
                            if (doc.Experience >= docExp[0] && doc.Experience <= docExp[1]) {
                                docs.push(doc)
                            }
                        }
                    }

                })
            })
        }
        // console.log("filterDoc in card:", this.props.docExp)
        docs = _.filter(docs,item=>{return item.googleRating > this.props.rate})
        this.setState({doctors: _.uniq([...docs])})
        // qerd = qerd + "&hospital=" + hid;
        // searchDoctors('?' + qerd, 3, 1).then(doctors => {
        //     // console.log("Hospital Card : ", hid, qerd, doctors)
        //     this.setState({doctors})
        // })
    }


    render() {
        console.log("Rate is : ", this.props.rate)
        console.log("Docs are : ", this.state.doctors)
        const {
            symbol,
            addToCart,
            addToCartUnsafe,
            addToWishlist,
            auth,
        } = this.props;
        let products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true,
        };
        let productsnav = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        };
        let data = this.props.data;
        const item = this.state.hospital;
        const index = this.props.index;
        const doctors = this.state.doctors;

        // console.log("iteeeeem", item);
        // const placeholder = `${process.env.PUBLIC_URL}/assets/images/building.png`;
        const placeholder = `https://hosplan.com/assets/images/building.png`;
        return (
            <div>
                {item ? (
                    <div>
                        <div className="searchCard">
                            <div className="row mobOnly" style={{borderBottom: "1px solid #d4d4d4", paddingBottom: '0px', marginLeft: '0px'}}>
                                <div className="" style={{padding: '0px', maxWidth: '110px'}}>
                                    <Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias + this.state.urlQ}`}>
                                        <Slider
                                            {...products}
                                            asNavFor={this.state.nav2}
                                            ref={(slider) => (this.slider1 = slider)}
                                            style={{maxWidth: "200px"}}
                                            className="product-slick"
                                        >
                                            <div>
                                                <ImageZoom
                                                    image={(item.Pictures && item.Pictures.length) ? item.Pictures[0].url : placeholder}
                                                    className="img-fluid image_zoom_cls-0 hospital-card-image"
                                                    imgclass={'hospital-card-image-mob'}
                                                    dimensions={{width: '110',height:'100'}}
                                                />
                                            </div>
                                        </Slider>
                                    </Link>
                                </div>
                                <div className="" style={{marginLeft: "5px", width: "200px"}}>
                                    <Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias + this.state.urlQ}`}>
                                        {/*<Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias}`}>*/}
                                        <h5 className="hcard" style={{marginTop: '5px'}}>
                                            {" "}
                                            {item.Name}
                                            {item.Verified ? (
                                                <Tooltip title="Hosplan Verified" placement={"top"}>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icons/verified.svg`}
                                                         style={{width: "20px", paddingBottom: "4px"}} alt={"verified"}/>
                                                </Tooltip>
                                            ) : null}
                                        </h5>
                                        {/*</Link>*/}
                                        <Text style={{fontSize: '12px'}}>
                                            Established In:{" "}
                                            <Text strong>
                                                {item.EstablishedYear + " "}
                                            </Text>
                                            | Number of Beds: <Text strong>{item.Beds}</Text>
                                        </Text>
                                        <br/>
                                        {/*<div className="product-description border-product">*/}
                                        {/*    <div className="rating">*/}

                                        {/*        <Text style={{fontSize: '12px'}}>*/}
                                        {/*            Rating on Google :*/}
                                        {/*        </Text>*/}

                                        {/*        <Rate*/}
                                        {/*            disabled*/}
                                        {/*            allowHalf*/}
                                        {/*            value={item.googleRating ? item.googleRating : item.Rating}*/}
                                        {/*            style={{color: "#ffa201", fontSize: '12px'}}*/}
                                        {/*        />{" "}*/}
                                        {/*        <br/>*/}
                                        {/*    </div>*/}
                                        {/*    <HospitalSpclType item={item}/>*/}
                                        {/*    <Accredetion item={item}/>*/}


                                        {/*    /!*{item.type ? <div>*!/*/}
                                        {/*    /!*    <Text style={{fontSize: '12px'}}>*!/*/}
                                        {/*    /!*        {item.type}*!/*/}
                                        {/*    /!*    </Text>*!/*/}
                                        {/*    /!*</div> : null}*!/*/}
                                        {/*</div>*/}
                                        {/*<Text>*/}
                                        {/*  Times Rank:<Text strong> {item.TOIRank}</Text>*/}
                                        {/*</Text>*/}
                                        {/*<br/>*/}
                                        {/*<Text>*/}
                                        {/*    /!*<Text strong>{item.doctors.length} </Text> Doctors in Panel*!/*/}
                                        {/*</Text>*/}
                                        {/*<br/>*/}
                                        {/*<Text>*/}
                                        {/*  <Text strong>73 </Text>Specalisations,<Text strong>98</Text>{" "}*/}
                                        {/*  Listed Treatments*/}
                                        {/*</Text>*/}
                                        {/*{item.specialities ? (*/}
                                        {/*    <React.Fragment>*/}
                                        {/*        <Text style={{fontSize: '12px'}}>Specialisations:</Text>*/}
                                        {/*        <HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{*/}
                                        {/*            color: '#9a9a9a',*/}
                                        {/*            fontSize: '10px',*/}
                                        {/*            textTransform: 'none',*/}
                                        {/*            padding: '0.1rem 0.4rem',*/}
                                        {/*            background: '#ececec',*/}
                                        {/*        }}/>*/}
                                        {/*    </React.Fragment>*/}
                                        {/*) : null}*/}
                                        <br/>

                                        {/*<div className="product-description border-product">*/}
                                        {/*  <div className="rating">*/}
                                        {/*    <Rate*/}
                                        {/*      disabled*/}
                                        {/*      value={item.Rating}*/}
                                        {/*      style={{ color: "#ffa201" }}*/}
                                        {/*    />{" "}*/}
                                        {/*    Rating on Google*/}
                                        {/*    <br />*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                    </Link>
                                </div>
                                {/*</Link>*/}
                            </div>
                            <div className="row mobHide" style={{borderBottom: "1px solid #d4d4d4", paddingBottom: '0px', marginLeft: '0px'}}>
                                {/*<Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias}`}>*/}
                                <div className="col-lg-4 " style={{padding: '0px'}}>
                                    <Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias + this.state.urlQ}`}>
                                        <Slider
                                            {...products}
                                            asNavFor={this.state.nav2}
                                            ref={(slider) => (this.slider1 = slider)}
                                            style={{maxWidth: "200px"}}
                                            className="product-slick"
                                        >
                                            <div>
                                                {/*<img*/}
                                                {/*     // src={`${image}`}*/}
                                                {/*     src={(item.Pictures && item.Pictures.length) ? item.Pictures[0].url : placeholder}*/}
                                                {/*     className="img-fluid image_zoom_cls-0 hospital-card-image img-fluid image_zoom_cls-0 hospital-card-image"*/}
                                                {/*     style={this.props.style?this.props.style:{}}/>*/}
                                                <ImageZoom
                                                    image={(item.Pictures && item.Pictures.length) ? item.Pictures[0].url : placeholder}
                                                    className="img-fluid image_zoom_cls-0 hospital-card-image"
                                                    imgclass={'hospital-card-image'}
                                                    dimensions={{width: '300',height:'235'}}
                                                />
                                            </div>
                                            {/*{item.Pictures.map((vari, index) =>*/}
                                            {/*    <div key={index}>*/}
                                            {/*        <ImageZoom image={vari.url} style={{maxWidth:'250px'}}*/}
                                            {/*                   className="img-fluid image_zoom_cls-0"/>*/}
                                            {/*    </div>*/}
                                            {/*)}*/}
                                        </Slider>
                                        {/*{item.Pictures.length > 1 ?*/}
                                        {/*    <SmallImages item={item} settings={productsnav}*/}
                                        {/*                 navOne={this.state.nav1}/> : ""}*/}
                                    </Link>
                                </div>
                                <div className="col-lg-8 pad">
                                    <Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias + this.state.urlQ}`}>
                                        {/*<Link to={`${process.env.PUBLIC_URL}/hospital/${item.Alias}`}>*/}
                                        <h3 className="hcard" style={{marginTop: '5px'}}>
                                            {" "}
                                            {item.Name}
                                            {item.Verified ? (
                                                <Tooltip title="Hosplan Verified" placement={"top"}>
                                                    <img src={`${process.env.PUBLIC_URL}/assets/images/icons/verified.svg`}
                                                         style={{width: "20px", paddingBottom: "4px"}} alt={"verified"}/>
                                                </Tooltip>
                                            ) : null}
                                        </h3>
                                        {/*</Link>*/}
                                        <Text style={{fontSize: '12px'}}>
                                            Established In:{" "}
                                            <Text strong>
                                                {item.EstablishedYear + " "}
                                            </Text>
                                            | Number of Beds: <Text strong>{item.Beds}</Text>
                                        </Text>
                                        <br/>
                                        <div className="product-description border-product">
                                            <div className="rating">

                                                <Text style={{fontSize: '12px'}}>
                                                    Rating on Google :
                                                </Text>

                                                <Rate
                                                    disabled
                                                    allowHalf
                                                    value={item.googleRating ? item.googleRating : item.Rating}
                                                    style={{color: "#ffa201", fontSize: '12px'}}
                                                />{" "}
                                                <br/>
                                            </div>
                                            <HospitalSpclType item={item}/>
                                            <Accredetion item={item}/>


                                            {/*{item.type ? <div>*/}
                                            {/*    <Text style={{fontSize: '12px'}}>*/}
                                            {/*        {item.type}*/}
                                            {/*    </Text>*/}
                                            {/*</div> : null}*/}
                                        </div>
                                        {/*<Text>*/}
                                        {/*  Times Rank:<Text strong> {item.TOIRank}</Text>*/}
                                        {/*</Text>*/}
                                        <br/>
                                        <Text>
                                            {/*<Text strong>{item.doctors.length} </Text> Doctors in Panel*/}
                                        </Text>
                                        <br/>
                                        {/*<Text>*/}
                                        {/*  <Text strong>73 </Text>Specalisations,<Text strong>98</Text>{" "}*/}
                                        {/*  Listed Treatments*/}
                                        {/*</Text>*/}
                                        {/*{item.specialities ? (*/}
                                        {/*    <React.Fragment>*/}
                                        {/*        <Text style={{fontSize: '12px'}}>Specialisations:</Text>*/}
                                        {/*        <HospitalSpecialities spcl={item.specialities} hospi={item} clname={'tag'} styl={{*/}
                                        {/*            color: '#9a9a9a',*/}
                                        {/*            fontSize: '10px',*/}
                                        {/*            textTransform: 'none',*/}
                                        {/*            padding: '0.1rem 0.4rem',*/}
                                        {/*            background: '#ececec',*/}
                                        {/*        }}/>*/}
                                        {/*    </React.Fragment>*/}
                                        {/*) : null}*/}
                                        <br/>

                                        {/*<div className="product-description border-product">*/}
                                        {/*  <div className="rating">*/}
                                        {/*    <Rate*/}
                                        {/*      disabled*/}
                                        {/*      value={item.Rating}*/}
                                        {/*      style={{ color: "#ffa201" }}*/}
                                        {/*    />{" "}*/}
                                        {/*    Rating on Google*/}
                                        {/*    <br />*/}
                                        {/*  </div>*/}
                                        {/*</div>*/}
                                    </Link>
                                </div>
                                {/*</Link>*/}
                            </div>
                            <div className="row padd mobHide" style={{justifyContent: "space-between"}}>
                                <div className="row">
                                    <div style={{width: "100px", fontWeight: "300", borderRight: "1px solid #d4d4d4",}}>
                                        Times of India Rank:{" "}
                                        <span style={{fontSize: "14px"}}>{item.TOIRank ? item.TOIRank : 'N/A'}/10</span>
                                    </div>
                                    <div>
                                        <Tooltip title="Find Available Doctors" placement={"top"}>
                                            <button className="emptyBtn">
                                                <i className="fa fa-user-md" style={{fontSize: '14px', color: '#a0a0a0', padding: '12px'}}></i>
                                                Doctors
                                            </button>
                                        </Tooltip>
                                        <Tooltip title="Add to Favorite" placement={"bottom"}>
                                            <button className="emptyBtn">
                                                <i className="fa fa-heart" style={{fontSize: '14px', color: '#a0a0a0', padding: '12px'}}></i>
                                                Favorite
                                            </button>
                                        </Tooltip>
                                    </div>
                                </div>

                                <div>
                                    <Tooltip title="Get Opinion" placement={"top"}>
                                        <button className="emptyBtn">
                                            <FileDoneOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '12px'}}/>
                                            Get Opinion
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Get Estimate" placement={"bottom"}>
                                        <button className="emptyBtn">
                                            <CalculatorOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '12px'}}/>
                                            Get Estimate
                                        </button>
                                    </Tooltip>
                                    <Tooltip title="Start Live Chat" placement={"topRight"}>
                                        <button className="emptyBtn">
                                            <CommentOutlined style={{fontSize: '14px', color: '#a0a0a0', padding: '12px'}}/>
                                            Live Chat
                                        </button>
                                    </Tooltip>
                                </div>

                                {/*<button className="btn btn-solid cardBtn">*/}
                                {/*    Doctors Available*/}
                                {/*</button>*/}

                                {/*<button className="btn btn-solid cardBtn">Favroute</button>*/}
                                {/*<button className="btn btn-solid cardBtn">Get Opinion</button>*/}
                                {/*<button className="btn btn-solid cardBtn">Get Estimate</button>*/}

                                {/*<button className="btn btn-solid cardBtn">Live Chat</button>*/}
                            </div>


                        </div>
                        {/* col-sm-auto offset-sm-1 */}
                        {doctors.length ?
                            <div className="row dCardContainer" style={{margin: "0px", maxHeight: '520px', overflowX: 'hidden', overflowY: 'auto'}}>
                                {doctors.map((doctor, indx) => {
                                    return (
                                        <div key={indx} className="">
                                            <DoctorCardSmall data={doctor} index={indx}/>
                                        </div>
                                    )
                                })}
                            </div>
                            : null}


                    </div>

                ) : null}
            </div>
        );
    }

}

export default Hospital;
