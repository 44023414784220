import React from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import {ScrollContext} from "react-router-scroll-4";
import {IntlReducer as Intl, IntlProvider} from "react-redux-multilingual";
import "./index.scss";
import "antd/dist/antd.css";
import _ from "lodash";

// Import custom components
import store from "./redux/store";
import translations from "./constants/translations";
import {getAllProducts} from "./redux/actions";
import Landing from "./components/landing";

// Layouts
import Fashion from "./components/_old/fashion/main";
import Vegetables from "./components/_old/vegetables/main";
import Kids from "./components/_old/kids/main";
import Pets from "./components/_old/pets/main";
import Furniture from "./components/_old/furniture/main";
import Watch from "./components/layouts/home/main";
import Cancer from "./components/layouts/category/cancer";
import Electronic from "./components/_old/electronic/main";

//Collection Pages
import CollectionLeftSidebar from "./components/listing/_old/collection-left-sidebar";
import CollectionNoSidebar from "./components/listing/_old/collection-no-sidebar";
import CollectionRightSidebar from "./components/listing/_old/collection-right-sidebar";
import CollectionFullWidth from "./components/listing/_old/collection-full-width";
import CollectionMetro from "./components/listing/_old/collection-metro";
import Hospitals from "./components/listing/hospitals";
import Doctors from "./components/listing/doctors";
import visa from "./components/listing/visa";
import accomodation from "./components/listing/accomodation";
import ambulance from "./components/listing/ambulance";

// Product Pages
import LeftSideBar from "./components/products/_old/left-sidebar";
import RightSideBar from "./components/products/_old/right-sidebar";
import Hospital from "./components/products/hospital";
import Doctor from "./components/products/doctor";
import NoSideBar from "./components/products/_old/no-sidebar";
import LeftImage from "./components/products/_old/left-image";
import RightImage from "./components/products/_old/right-image";
import Accordian from "./components/products/_old/accordian";
import ColumnLeft from "./components/products/_old/column-left";
import ColumnRight from "./components/products/_old/column-right";
import Column from "./components/products/_old/column";
import Vertical from "./components/products/_old/vertical";

import GetTreatment from "./components/listing/treatment";
import GetHospital from "./components/listing/hospital";
import GetDoctor from "./components/listing/doctor";
import Search from "./components/listing/search";
import Find from "./components/listing/find";
// import history from "./services/history";
// Features
import Layout from "./components/app";
import Cart from "./components/cart";
import Compare from "./components/compare/index";
import wishList from "./components/wishlist";
import checkOut from "./components/checkout";
import orderSuccess from "./components/checkout/success-page";

// Extra Pages
import aboutUs from "./components/staticPages/about-us";
import PageNotFound from "./components/staticPages/404";
import lookbook from "./components/staticPages/lookbook";
import Login from "./components/auth/login";
import Register from "./components/auth/register";
// import Search from './components/staticPages/search'
import Collection from "./components/staticPages/collection";
import ForgetPassword from "./components/auth/forget-password";
import Contact from "./components/staticPages/contact";
import Dashboard from "./components/auth/dashboard";
import doctorDashboard from "./components/auth/doctorDashboard";
import doctorProfile from "./components/auth/doctorProfile";
import doctorProfileEdit from "./components/auth/doctorProfileEdit";
import hospitalProfile from "./components/auth/hospitalProfile";
import HospitalDashboard from "./components/auth/hospitalDashboard";
import Bookings from "./components/auth/bookings";
import DoctorBookings from "./components/auth/doctorBookings";
import HospitalBookings from "./components/auth/hospitalBookings";
import Faq from "./components/staticPages/faq";
import Conference from "./components/auth/conference";
import Reports from "./components/auth/reports";
import PrescriptionList from "./components/auth/prescriptionList";

import MedicalVisa from "./components/visa/main"
import Accomodation from "./components/accomodation/main"
import Ambulance from "./components/ambulance/main"


// Blog Pages
import RightSide from "./components/staticPages/blogs/right-sidebar";
import Details from "./components/staticPages/blogs/details";
import BlogPage from "./components/staticPages/blogs/blog-page";
import Article from "./components/layouts/common/article";

// Theme Element
import ElementTitle from "./components/_old/features/theme/element-title";
import ElementBanner from "./components/_old/features/theme/element-banner";
import ElementSlider from "./components/_old/features/theme/element-slider";
import ElementCategory from "./components/_old/features/theme/element-category";
import ElementService from "./components/_old/features/theme/element-service";
import ElementRatio from "./components/_old/features/theme/element-ratio";

// Product Elements
import ElementProductBox from "./components/_old/features/product/element-product-box";
import ElementProductSlider from "./components/_old/features/product/element-product-slider";
import ElementProductNoSlider from "./components/_old/features/product/element-product-no-slider";
import ElementMultipleSlider from "./components/_old/features/product/element-multiple-slider";
import ElementProductTab from "./components/_old/features/product/element-product-tab";

// Portfolio Features
import GridCols from "./components/_old/features/portfolio/grid-cols";
import MasonaryGridCols from "./components/_old/features/portfolio/masonary-grid-cols";

// Other Libs
import ReactGA from "react-ga";
import * as firebase from "firebase/app";
import hospitalDashboard from "./components/auth/hospitalDashboard";
import ScrollToTop from "./components/commonAll/scrollToTop";

var firebaseConfig = {
    apiKey: "AIzaSyDLpoRY60McWpk2IrNdQo_ebn_JbV39l60",
    authDomain: "treatment-excellence.firebaseapp.com",
    databaseURL: "https://treatment-excellence.firebaseio.com",
    projectId: "treatment-excellence",
    storageBucket: "treatment-excellence.appspot.com",
    messagingSenderId: "1067984300509",
    appId: "1:1067984300509:web:fd7b911ad5ec6d5e23ee34",
    measurementId: "G-XB3V6E9CWG",
};
firebase.initializeApp(firebaseConfig);

//firebase.analytics();

ReactGA.initialize("UA-164810881-1", {
    debug: false,
    titleCase: false,
    gaOptions: {
        siteSpeedSampleRate: 100,
    },
});

class Root extends React.Component {
    componentDidMount() {
        ReactGA.pageview("/");
    }

    render() {
        // store.dispatch(getAllProducts());

        return (
            <Provider store={store}>
                <IntlProvider translations={translations} locale="en">
                    <BrowserRouter basename={"/"}>
                        {/*<ScrollToTop>*/}
                        <ScrollContext>
                            <Switch>
                                <Route
                                    exact
                                    path={`${process.env.PUBLIC_URL}/`}
                                    component={Watch}
                                />
                                {/*<Route path={`${process.env.PUBLIC_URL}/vegetables`} component={Vegetables}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/electronic`} component={Electronic}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/furniture`} component={Furniture}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/pets`} component={Pets}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/watch`} component={Watch}/>*/}
                                {/*<Route path={`${process.env.PUBLIC_URL}/kids`} component={Kids}/>*/}
                                <Route
                                    path={`${process.env.PUBLIC_URL}/allabout/:category`}
                                    component={Cancer}
                                />

                                <Route
                                    path={`${process.env.PUBLIC_URL}/conference/:id`}
                                    component={Conference}
                                />
                                <Layout>
                                    {/*Routes For Layouts*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/fashion`} component={Fashion}/>*/}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/medicalvisa/city`}
                                        component={MedicalVisa}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/accomodation/city`}
                                        component={Accomodation}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/airambulance/city`}
                                        component={Ambulance}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/doctors/:city`}
                                        component={Doctors}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/hospitals/:city`}
                                        component={Hospitals}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/treatment/:city`}
                                        component={GetTreatment}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/search/:city`}
                                        component={Search}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/info/:category/:slug`}
                                        component={Article}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/find`}
                                        component={Find}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/hos/:city`}
                                        component={GetHospital}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/doct/:city`}
                                        component={GetDoctor}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/visa/:city`}
                                        component={visa}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/accomodation/:city`}
                                        component={accomodation}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/ambulance/:city`}
                                        component={ambulance}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/hospital/:id`}
                                        component={Hospital}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/doctor/:id`}
                                        component={Doctor}
                                    />

                                    {/*Routes For custom Features*/}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/cart`}
                                        component={Cart}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/wishlist`}
                                        component={wishList}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/compare`}
                                        component={Compare}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/checkout`}
                                        component={checkOut}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/order-success`}
                                        component={orderSuccess}
                                    />

                                    <Route
                                        path={`${process.env.PUBLIC_URL}/sales/orders`}
                                        component={aboutUs}
                                    />

                                    {/*Routes For Extra Pages*/}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/about-us`}
                                        component={aboutUs}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/404`}
                                        component={PageNotFound}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/lookbook`}
                                        component={lookbook}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/login`}
                                        component={Login}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/register/:userType`}
                                        component={Register}
                                    />
                                    {/*<Route path={`${process.env.PUBLIC_URL}/pages/search`} component={Search}/>*/}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/collection`}
                                        component={Collection}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/forget-password`}
                                        component={ForgetPassword}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/contact`}
                                        component={Contact}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/dashboard`}
                                        component={Dashboard}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/doctordashboard`}
                                        component={doctorDashboard}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/doctorprofile`}
                                        component={doctorProfile}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/doctorprofileedit`}
                                        component={doctorProfileEdit}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/hospitalprofile`}
                                        component={hospitalProfile}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/hospitaldashboard`}
                                        component={hospitalDashboard}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/bookings`}
                                        component={Bookings}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/doctorbookings`}
                                        component={DoctorBookings}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/hospitalbookings`}
                                        component={HospitalBookings}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/faq`}
                                        component={Faq}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/reports`}
                                        component={Reports}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/pages/prescriptions`}
                                        component={PrescriptionList}
                                    />

                                    {/*Features*/}
                                    {/*Theme Elements*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-title`}*/}
                                    {/*       component={ElementTitle}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-banner`}*/}
                                    {/*       component={ElementBanner}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-slider`}*/}
                                    {/*       component={ElementSlider}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-category`}*/}
                                    {/*       component={ElementCategory}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-service`}*/}
                                    {/*       component={ElementService}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-ratio`}*/}
                                    {/*       component={ElementRatio}/>*/}

                                    {/*Product Elements*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-product-box`}*/}
                                    {/*       component={ElementProductBox}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-product-slider`}*/}
                                    {/*       component={ElementProductSlider}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-product-no-slider`}*/}
                                    {/*       component={ElementProductNoSlider}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-product-multiple-slider`}*/}
                                    {/*       component={ElementMultipleSlider}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/element-product-tab`}*/}
                                    {/*       component={ElementProductTab}/>*/}

                                    {/*Portfolios*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/portfolio-grid/:columns`}*/}
                                    {/*       component={GridCols}/>*/}
                                    {/*<Route path={`${process.env.PUBLIC_URL}/features/portfolio-masonary/:columns`}*/}
                                    {/*       component={MasonaryGridCols}/>*/}

                                    {/*Blog Pages*/}
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/blog/right-sidebar`}
                                        component={RightSide}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/blog/details`}
                                        component={Details}
                                    />
                                    <Route
                                        path={`${process.env.PUBLIC_URL}/blog/blog-page`}
                                        component={BlogPage}
                                    />

                                    {/* <Route exact path="*" component={PageNotFound} /> */}
                                </Layout>
                            </Switch>
                        </ScrollContext>
                        {/*</ScrollToTop>*/}
                    </BrowserRouter>
                </IntlProvider>
            </Provider>
        );
    }
}

ReactDOM.render(<Root/>, document.getElementById("root"));
