import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import LogoImage from "./logo";
import {connect} from "react-redux";
import {switchPrecriptionOn, switchPrecriptionOff} from "../../../../redux/actions";
import {logout} from "../../../../redux/actions/authActions";

class TopbarIframe extends Component {

    constructor(props) {
        super(props);
        this.state = {
            auth: this.props.auth,
            booking: this.props.booking
        };
    }

    getHeaderControls = (booking, auth) => {
        if (auth.user.user.type === "doctor") {
            return (<li style={{textTransform: 'capitalize'}}>
                <span>Patient : {booking.user.name}</span>
            </li>)
        } else {
            return (<li style={{textTransform: 'capitalize'}}>
                <span>Doctor : {booking.Bookings[0].DrName}</span>
            </li>)
        }
    }

    switchPrescription = () => {
        // console.log("switching")
        if (this.props.helpers.prescription) {
            this.props.switchPrecriptionOff()
        } else {
            this.props.switchPrecriptionOn()
        }
    }


    render() {
        const {booking, auth} = this.props;
        // console.log("TOP-HEADER IFRAME booking : ", booking);
        // console.log("TOP-HEADER IFRAME auth : ", auth);

        return (
            <div className="top-header top-header-dark3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown">
                                <li style={{float: 'left', padding: '5px'}}>
                                    <Link to={`${process.env.PUBLIC_URL}/`}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/icon/logo.png`} alt=""
                                             style={{height: 39, objectFit: 'contain'}}/>
                                    </Link>
                                </li>
                                {/*{this.getHeader()}*/}
                                {booking.id ? this.getHeaderControls(booking, auth) : ""}
                                {auth.user.user.type === "doctor" ?
                                    <li><span onClick={this.switchPrescription}>Prescription</span></li> : ""}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    auth: state.auth,
    helpers: state.helpers
})

export default connect(
    mapStateToProps,
    {switchPrecriptionOn, switchPrecriptionOff}
)(TopbarIframe)

// export default TopbarIframe;