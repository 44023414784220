import React from "react";
import { Link } from "react-router-dom";
import "./index.scss";
class CardHeader extends React.Component {
  render() {
    const { image } = this.props;
    var style = {
      backgroundImage: "url(" + image + ")",
    };
    return (
      <header style={style} id={image} className="card-header">
        {/* <h4 className="card-header--title">News</h4> */}
      </header>
    );
  }
}

class Button extends React.Component {
  render() {
    return (
      <Link to={this.props.link} className="button button-primary">
        <i className="fa fa-chevron-right"></i> Find out more
      </Link>
    );
  }
}

class CardBody extends React.Component {
  render() {
    return (
      <div className="card-body">
        {/* <p className="date">March 20 2015</p> */}

        <h2>{this.props.title}</h2>

        <p className="body-content">{this.props.text}</p>

        <Button link={this.props.link} />
      </div>
    );
  }
}

class Card extends React.Component {
  render() {
    return (
      <article className="card">
        <CardHeader image={this.props.img} />
        <CardBody
          title={this.props.title}
          text={this.props.description}
          link={this.props.to}
        />
      </article>
    );
  }
}

export default Card;
