import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import Breadcrumb from "../commonAll/breadcrumb";
import NewProduct from "../commonAll/new-product";
import Filter from "./common/filter";
import FilterBar from "./common/filter-bar";
import ProductListing from "./common/_old/product-listing";
import StickyBox from "react-sticky-box";
import FilterTreatment from "./common/filter-treatment";
import DoctorListing from "./common/doctor-listing";
import {getCategoryData, getParams, getSearchParamID} from "../../api/apicalls";
import SearchListing from "./common/search-listing";
import {addToWishlistUnsafe, populateLocal} from '../../redux/actions';
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import _ from 'lodash';

class Search extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openFilter: false,
            layoutColumns: 3,
            //city: (this.props.params.city)?this.props.params.city:"INDIA",
            city: "INDIA",
            spcl: [],
            topic: '',
            sticky: false,
            rate: [0, 5],
            drate: [0, 5],
            dcity: [],
            dCheckedCity: [],
            dprices: [0, 15000],
            gender: {male: false, female: false},
            experience: [0, 100],
            availableFor: {videoConference: false, onCall: false, inHospital: false},
            flag: false,
            cat: '',
            slug: '',
            introData: {},
            rateSelected: 0,
            docExp: [0, 100],
        }
        //console.log("props",props);
    }

    // state = {
    //     layoutColumns: 3
    // }
    handleFlag = (value) => {
        this.setState({flag: value})

    }
    handleCity = (value) => {
        this.setState({dcity: value})


    }
    handleRate = (value) => {
        console.log("handleRate", value)
        this.setState({rateSelected: value})
    }

    handleDocExp = (value)=>{
        // this.setState({dCheckedCity: value})
        this.setState({docExp: value})
        console.log("handleDocExp", value)
    }

    handleCheckedCity = (value) => {

        this.setState({dCheckedCity: value})
        this.setState({flag: !this.state.flag})
        console.log("handleCheckedCity", value)
    }
    handleSlideChange = (name, value) => {
        this.setState({[name]: value})
    }
    handleAvailableChange = (value) => {
        this.setState({availableFor: value})
    }
    handleGenderChange = (value) => {
        this.setState({gender: value})
    }

    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        // let url = location.href;
        // let params = this.getParams(url)
        // let spcl = params.s.split(',');
        // console.log(">>>>>>>> DOCTORS QUERY : ", url);
        // console.log(">>>>>>>> DOCTORS QUERY : ", params);
        // console.log(">>>>>>>> DOCTORS QUERY : ", spcl);
        // this.setState({spcl});
        // this.getSearchParam();


        if (process.browser) {
            window.addEventListener('scroll', this.handleScroll);
        }
        this.getSearchParam();

        // document.documentElement.scrollTop = 335
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.handleScroll);
    }

    handleScroll = () => {
        // console.log("window.pageYOffset", window.pageYOffset)


        let header = document.querySelector(".sticky");

        if (!header.classList.contains("hidden")) {
            document.querySelector(".collection-filter").style = "margin-top: 50px";

        } else {
            document.querySelector(".collection-filter").style = "margin-top: 0px";
        }

    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            this.getSearchParam();
        }
    }

    getSearchParam = () => {
        // console.log("SEARCH :: Get Search Header Parameter : ")
        // eslint-disable-next-line no-restricted-globals
        let url = location.href;
        let params = getParams(url);

        let cat, slug = "";

        // console.log("SEARCH :: Get Search Header Parameter : ", params)
        if (params['Treatment']) {
            cat = 'treatment';
            slug = params['Treatment'].replaceAll('-', ' ');
            this.setState({
                topic: params['Treatment'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Condition']) {
            cat = 'condition';
            slug = params['Condition'].replaceAll('-', ' ');
            this.setState({
                topic: params['Condition'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Specialisation']) {
            cat = 'specialisation';
            slug = params['Specialisation'].replaceAll('-', ' ');
            this.setState({
                topic: params['Specialisation'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Speciality']) {
            cat = 'speciality';
            slug = params['Speciality'].replaceAll('-', ' ');
            this.setState({
                topic: params['Speciality'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Hospital']) {
            cat = 'hospital';
            slug = params['Hospital'].replaceAll('-', ' ');
            this.setState({
                topic: params['Hospital'].replaceAll('-', ' '),
            })
        } else if (params['Doctor']) {
            cat = 'doctor';
            slug = params['Doctor'].replaceAll('-', ' ');
            this.setState({
                topic: params['Doctor'].replaceAll('-', ' '),
            })
        }
        if (cat !== '' && slug !== '') {
            // eslint-disable-next-line no-restricted-globals
            let searchItem = this.getSearchItem(cat, slug);
            if (searchItem && searchItem.category && searchItem.slug) {
                getCategoryData(searchItem.category, searchItem.slug).then(introData => {
                    // console.log("SEARCH :: Category Data : ", introData)
                    document.documentElement.scrollTop = 335
                    this.setState({introData})
                })
                this.setState({
                    cat: searchItem.category,
                    slug: searchItem.slug
                })
            } else {
                getSearchParamID(cat, slug).then(r => {
                    // console.log("Docemnet data Derom : ", r)
                    getCategoryData(r[0].category, r[0].slug).then(introData => {
                        // console.log("SEARCH :: Category Data : ", introData)
                        document.documentElement.scrollTop = 335
                        this.setState({introData})
                    })
                    this.setState({
                        cat: r[0].category,
                        slug: r[0].slug
                    })
                })
            }
        } else {
            this.setState({introData: {}})
        }
    }

    getSearchItem = (cat, slug) => {
        let item = {};
        switch (cat) {
            case 'treatment':
                item = _.find(this.props.suggTreatments, {value: slug})
                break;
            case 'condition':
                item = _.find(this.props.suggConditions, {value: slug})
                break;
            case 'specialisation':
                item = _.find(this.props.suggSpecialisations, {value: slug})
                break;
            case 'speciality':
                item = _.find(this.props.suggSpecialisations, {value: slug})
                break;
            case 'hospital':
                item = _.find(this.props.suggHospitals, {value: slug})
                break;
            case 'doctor':
                item = _.find(this.props.suggDoctors, {value: slug})
                break;
            default:
                console.log("Match Nothing")
        }
        // console.log("Slug is:", item)
        return item;
    }

    LayoutViewClicked(colums) {
        this.setState({
            layoutColumns: colums
        })
    }

    openFilter = () => {
        document.querySelector(".collection-filter").style = "left: -15px";
    }

    render() {
        const {match: {params}} = this.props;
        const {
            spcl,
            rate,
            drate,
            dprices,
            gender,
            experience,
            availableFor,
            dcity,
            dCheckedCity,
            flag,
            introData,
            rateSelected,
            docExp
        } = this.state;
        window.srcpageProps = this.props;
        window.srcpageState = this.state;
        // console.log(rate, drate, dprices, gender, experience, availableFor)
        // console.log("search::dCheckedCity", dCheckedCity);
        return (
            <div>
                {/*SEO Support*/}
                <Helmet>
                    <title>Treatment Excellence</title>
                    <meta name="description" content="Hospitals"/>
                </Helmet>
                {/*SEO Support End */}

                {/*<Breadcrumb title={'Doctors'}/>*/}

                <section className="section-b-space" style={{backgroundColor: '#ECECEC'}}>
                    <div className="collection-wrapper">
                        <div className="container inframe">
                            <div className="row">
                                <div className="col-sm-3 collection-filter">

                                    <StickyBox offsetTop={20} offsetBottom={20}>
                                        <div>
                                            <FilterTreatment handleCheckedCity={this.handleCheckedCity}
                                                             handleSlideChange={this.handleSlideChange}
                                                             handleAvailableChange={this.handleAvailableChange}
                                                             handleNewRate={this.handleRate}
                                                             handleDocExp={this.handleDocExp}
                                                             values={{
                                                                 rate,
                                                                 drate,
                                                                 dprices,
                                                                 gender,
                                                                 experience,
                                                                 availableFor,
                                                                 dcity,
                                                                 dCheckedCity,
                                                                 rateSelected,
                                                                 docExp,
                                                             }} handleGenderChange={this.handleGenderChange}
                                                             selectedcity={params.city}/>
                                            {/*<NewProduct/>*/}
                                            {/*<div className="collection-sidebar-banner">*/}
                                            {/*    <a href="#">*/}
                                            {/*        <img src={`${process.env.PUBLIC_URL}/assets/images/side-banner.png`} className="img-fluid" alt="" />*/}
                                            {/*    </a>*/}
                                            {/*</div>*/}
                                        </div>
                                    </StickyBox>
                                    {/*side-bar banner end here*/}
                                </div>
                                <div className="collection-content col">
                                    <div className="page-main-content ">
                                        <div className="">
                                            <div className="row">
                                                <div className="col-sm-12">
                                                    {introData.id ?
                                                        <div className="top-banner-wrapper">
                                                            {/*<a href="#"><img*/}
                                                            {/*    src={`${process.env.PUBLIC_URL}/assets/images/mega-menu/2.jpg`}*/}
                                                            {/*    className="img-fluid" alt=""/></a>*/}
                                                            <div className="top-banner-content small-section">
                                                                <h1 style={{fontSize: '25px'}}>{introData.name}</h1>
                                                                {introData.keywords ?
                                                                    <h5 style={{textTransform: 'capitalize'}}>
                                                                        Also Known As: {introData.keywords}</h5> : null}
                                                                {introData.Description ?
                                                                    <div>
                                                                        <h3 style={{fontSize: '25px'}}>Overview</h3>
                                                                        <p style={{color: "#000"}} className="about-doctor">
                                                                            {introData.Description}
                                                                        </p>
                                                                    </div> : null}


                                                                {(!introData.Description && introData.description.length) ?
                                                                    <div>
                                                                        {/*<h3 style={{fontSize: '25px'}}>Overview</h3>*/}
                                                                        <p style={{color: "#000"}}
                                                                           className="about-doctor">
                                                                            {/*{introData.description[0].body}*/}
                                                                            {/*{introData.description[0].body}*/}
                                                                            {introData.Description}
                                                                        </p>
                                                                        <Link
                                                                            className="readMore btn btn-light"
                                                                            style={{
                                                                                marginTop: '20px',
                                                                                backgroundColor: '#404040eb',
                                                                                color: '#fff',
                                                                                borderRadius: '2px',
                                                                                fontWeight: '100',
                                                                                borderTop: '2px solid #1fa5de'
                                                                            }}
                                                                            to={`${process.env.PUBLIC_URL}/info/${this.state.cat}/${this.state.slug}`}
                                                                            // onClick={() => {
                                                                            //     this.setState({show: !this.state.show});
                                                                            //     let path = `${process.env.PUBLIC_URL}/info/${this.state.cat}/${this.state.slug}`;
                                                                            //     // eslint-disable-next-line no-restricted-globals
                                                                            //     location.href = path;
                                                                            // }}
                                                                        >
                                                                            Read More
                                                                        </Link>
                                                                    </div> : null}

                                                            </div>

                                                        </div>
                                                        : null}


                                                    <div className="collection-product-wrapper">
                                                        {/*<div className="product-top-filter">*/}
                                                        {/*    <div className="container-fluid p-0">*/}
                                                        {/*        <div className="row">*/}
                                                        {/*            <div className="col-xl-12">*/}
                                                        {/*                <div className="filter-main-btn">*/}
                                                        {/*                    /!*<span onClick={this.openFilter}*!/*/}
                                                        {/*                    /!*      className="filter-btn btn btn-theme"><i*!/*/}
                                                        {/*                    /!*    className="fa fa-filter"*!/*/}
                                                        {/*                    /!*    aria-hidden="true"></i> Filter</span>*!/*/}
                                                        {/*                </div>*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*        <div className="row">*/}
                                                        {/*            <div className="col-12">*/}
                                                        {/*                <span>Loading ...</span>*/}
                                                        {/*                /!*<FilterBar*!/*/}
                                                        {/*                /!*    onLayoutViewClicked={(colmuns) => this.LayoutViewClicked(colmuns)}/>*!/*/}
                                                        {/*            </div>*/}
                                                        {/*        </div>*/}
                                                        {/*    </div>*/}
                                                        {/*</div>*/}

                                                        {/*Products Listing Component*/}
                                                        <SearchListing handleFlag={this.handleFlag}
                                                                       handleCity={this.handleCity}
                                                                       filterDoc={{
                                                                           rate,
                                                                           drate,
                                                                           dprices,
                                                                           gender,
                                                                           experience,
                                                                           availableFor,
                                                                           dcity,
                                                                           dCheckedCity,
                                                                           flag,
                                                                           rateSelected,
                                                                           docExp,
                                                                       }} colSize={this.state.layoutColumns}/>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,
});
export default connect(
    mapStateToProps,
    {
        populateLocal,
    }
)(withRouter(Search));
// export default Search;