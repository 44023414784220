import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import HeaderFive from "../commonAll/headers/header-five";
import TopBarDark from "../commonAll/headers/common/topbar-dark";
import HeaderIframe from "../commonAll/headers/header-iframe";
import {getCartTotal} from "../../services";
import {connect} from "react-redux";
import {decrementQty, incrementQty, removeFromCart} from "../../redux/actions";
import {logout} from "../../redux/actions/authActions";
import axios from "axios";
import appConfig from "../../config";
import PrescriptionForm from "./common/prescriptionForm";
import Jitsi from 'react-jitsi';

class Conference extends Component {

    constructor(props) {
        super(props)
        this.state = {
            url: "https://conf.html5.run/pages/r.html?room=Varun%27s&p=eyJsc1JlcFVybCI6Imh0dHBzOi8vY29uZi5odG1sNS5ydW4vIiwibmFtZXMiOiJWYXJ1biIsInBhc3MiOiJhZG1pbiIsImlzQWRtaW4iOjF9&isAdmin=1",
            booking: {},
            rightBar: true,
            rightBarConfig: {
                frameWidth: "calc(100% - 450px)",
                prescriptionWidth: "450px"
            },
            prescription: this.props.prescription,
            roomId:'test',

        }
        this.switchRightBar = this.switchRightBar.bind(this);
    };

    config = appConfig.config;
    interfaceConfig = appConfig.interfaceConfig;

    componentDidMount() {
        document.getElementById("color").setAttribute("href", `${process.env.PUBLIC_URL}/assets/css/color2.css`);
        const {auth, bookingID} = this.props;
        this.setState({
            roomId:bookingID,
        })
        this.getBooking(bookingID, auth);
        console.log(bookingID)
    };

    getBooking(bookingID, auth) {
        let param = "/" + bookingID;
        axios
            .get(appConfig.bookingOrder + param, {
                headers: {
                    Authorization: 'Bearer ' + auth.user.jwt
                }
            })
            .then(response => {
                // Handle success.
                console.log('CONFERENCE/BOOKING: Data Received', response.data);
                let booking = response.data;
                let url = booking.Bookings[0].BookingURL;
                // TODO : uncomment this
                if(auth.user.user.type==="doctor"){
                    url+="&isAdmin=1"
                }
                this.setState({
                    booking,
                    url
                });
            })
    }

    switchRightBar(prescription) {
        //console.log("switch")
        if (prescription) {
            this.setState({
                rightBarConfig: {
                    frameWidth: "calc(100% - 450px)",
                    prescriptionWidth: "450px"
                }
            })
        } else {
            this.setState({
                rightBarConfig: {
                    frameWidth: "100%",
                    prescriptionWidth: "0px"
                }
            })
        }
    }

    handleAPI = (JitsiMeetAPI) => {
        let api = JitsiMeetAPI;
        window.jitapi = JitsiMeetAPI;
        // console.info("==================================> ", JitsiMeetAPI);


        // const isAuthTokenValid = isUserAuthenticated();
        // if (isAuthTokenValid) {
        //     if (this.props.user.user.image && this.props.user.user.image.url) {
        //         api.executeCommand('avatarUrl', this.props.user.user.image.url);
        //     }
        // }
        //
        // api.addEventListener("videoConferenceJoined", ev => {
        //     // console.log(">>>>>>>>>>>>>>>>>> videoConferenceJoined : ", ev);
        //     // console.log("Meeting Subject :", this.state.meeting.name);
        //     if (this.state.meeting.name) {
        //         api.executeCommand('subject', this.state.meeting.name);
        //     }
        //     // api.executeCommand('subject', 'New Conference Subject');
        // });
        //
        // // api.executeCommand('toggleFilmStrip');
        //
        // // api.isAudioMuted().then(muted => {
        // //     console.log("I am muted ", muted);
        // // });
        //
        // api.addEventListener("readyToClose", ev => {
        //     this.updateDBForStatus("end", () => {
        //         api.removeEventListeners([
        //             'readyToClose',
        //             'participantJoined',
        //             'participantKickedOut',
        //             'participantLeft',
        //         ]);
        //         // console.log(">>>>>>>>>>>>>>>>>> Hang Up : ", ev);
        //         api.dispose();
        //         if (isAuthTokenValid) {
        //             // window.location = `/`;
        //             this.props.history.push(`/`);
        //         } else {
        //             this.setState({
        //                 ended: true,
        //             });
        //         }
        //     });
        // });
        //
        // api.addEventListener("participantJoined", ev => {
        //     // api.executeCommand('subject', 'New Conference Subject');
        //     // console.log(">>>>>>>>>>>>>>>>>> participantJoined : ", ev);
        //     if (!this.state.hostReceived) {
        //         this.checkMeetingStatus();
        //     }
        //     // console.log("Meeting Subject :", this.state.meeting.name);
        //     if (this.state.meeting.name) {
        //         api.executeCommand('subject', this.state.meeting.name);
        //     }
        //
        //     // if (this.state.meeting){
        //     //     getMeeting()
        //     // }
        // });
        //
        // api.addEventListener("participantKickedOut", ev => {
        //     // console.log(">>>>>>>>>>>>>>>>>> participantKickedOut : ", ev);
        // });
        //
        // api.addEventListener("participantLeft", ev => {
        //     // console.log(">>>>>>>>>>>>>>>>>> participantLeft : ", ev);
        //     setTimeout(this.checkMeetingStatus, 600);
        //     // this.checkMeetingStatus();
        // });


    }

    getBody = (booking, auth) => {
        if (auth.user.user.type === "doctor") {
            // Showing Precribtion Bar
            return (<div style={{width: "100%", backgroundColor: '#000', color: "#fff", height: '100%'}}>
                <div style={{
                    width: this.props.rightBarConfig.frameWidth,
                    backgroundColor: '#000',
                    color: "#fff",
                    height: '100%',
                    float: 'left'
                }}>
                    {/*<iframe*/}
                    {/*    src={this.state.url}*/}
                    {/*    allowFullScreen*/}
                    {/*    style={{width: '100%', height: '100%', border: 'none'}}*/}
                    {/*    allow="geolocation; microphone; camera *;"/>*/}
                    <Jitsi
                        containerStyle={{width: '100%', height: '100%'}}
                        // domain={"dev3.lauk.in"}
                        domain={"vc.hosplan.com"}
                        // domain={"devcon2.html5.run"}
                        roomName={this.state.roomId}
                        displayName={this.state.displayName}

                        config={this.config}
                        interfaceConfig={this.interfaceConfig}
                        onAPILoad={this.handleAPI}
                    />
                </div>
                <div style={{
                    width: this.props.rightBarConfig.prescriptionWidth,
                    height: '100%',
                    backgroundColor: '#133973',
                    float: 'right'
                }}>
                    {/*<span onClick={this.switchRightBar}>switch</span>*/}
                    <PrescriptionForm patient={this.state.booking.user} booking={this.state.booking}/>
                </div>
            </div>)
        } else {
            return (<div style={{width: "100%", backgroundColor: '#000', color: "#fff", height: '100%'}}>
                {/*<iframe //src="https://conf.html5.run/pages/r.html?room=Varun%27s&p=eyJsc1JlcFVybCI6Imh0dHBzOi8vY29uZi5odG1sNS5ydW4vIiwibmFtZXMiOiJWYXJ1biIsInBhc3MiOiJhZG1pbiIsImlzQWRtaW4iOjF9&isAdmin=1"*/}
                {/*    src={this.state.url}*/}
                {/*    allowFullScreen*/}
                {/*    style={{width: '100%', height: '100%', border: 'none'}}*/}
                {/*    allow="geolocation; microphone; camera *;"/>*/}
                <Jitsi
                    containerStyle={{width: '100%', height: '100%'}}
                    // domain={"dev3.lauk.in"}
                    domain={"vc.hosplan.com"}
                    // domain={"devcon2.html5.run"}
                    roomName={this.state.roomId}
                    displayName={this.state.displayName}

                    config={this.config}
                    interfaceConfig={this.interfaceConfig}
                    onAPILoad={this.handleAPI}
                />
            </div>)
        }
    }

    render() {
        const {auth, bookingID, prescription} = this.props;
        const {booking} = this.state;
        // console.log("CONFERENCE state : ", this.state);
        // console.log("CONFERENCE props : ", this.props);

        return (

            <div>
                <Helmet>
                    <title>Conference</title>
                </Helmet>

                <HeaderIframe logoName={'/assets/images/icon/logo.png'} booking={booking}/>
                <div style={{height: 'calc(100vh - 55px)'}}>
                    {/*<Breadcrumb title={'404 Page'}/>*/}
                    {booking ? this.getBody(booking, auth) : <span>Booking Not Found</span>}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state, ownProps) => ({
    auth: state.auth,
    bookingID: ownProps.match.params.id,
    prescription: state.helpers.prescription,
    rightBarConfig: state.helpers.rightBarConfig,
})

export default connect(
    mapStateToProps,
    {removeFromCart, incrementQty, decrementQty, logout}
)(Conference)