import appConfig from "../config";
import axios from "axios";
import _ from 'lodash';
import store from '../redux/store/index'
import {searchKeywords} from "../services";

// import {getLoggedInUser} from "./authUtils";
// import {isUserAuthenticated} from "./authUtils";
// import qs from "qs";

const editUser = async (id, data, jwt) => {
    return await axios
        .put(appConfig.users + "/" + id, data, {
            headers: {
                Authorization: "Bearer " + jwt,
            },
        })
        .then((response) => {
            // Handle success.
            axios
                .get(appConfig.users + "/" + id, {
                    headers: {
                        Authorization: "Bearer " + jwt,
                    },
                })
                .then((res) => {
                    let data = {
                        jwt: jwt,
                        user: res.data,
                    };
                    localStorage.setItem("user", JSON.stringify(data));
                    alert("UPDATED");
                    return res.data;
                });
        });
};

const editHospital = async (id, data, jwt) => {
    return await axios
        .put(appConfig.hospitals + "/" + data.id, data, {
            headers: {
                Authorization: "Bearer " + jwt,
            },
        })
        .then((response) => {
            // Handle success.
            axios
                .get(appConfig.users + "/" + id, {
                    headers: {
                        Authorization: "Bearer " + jwt,
                    },
                })
                .then((res) => {
                    let data = {
                        jwt: jwt,
                        user: res.data,
                    };
                    localStorage.setItem("user", JSON.stringify(data));
                    alert("UPDATED");
                    return res.data;
                });
        });
};

const getMyLocation = async () => {
    return await axios.get(appConfig.locationApi).then((response) => {
        // Handle success.
        // console.log("Location Received", response.data);
        return response.data;
    });
};

const getSearchData = async (cat) => {
    return await axios
        .get(appConfig.home + "/search?cat=" + cat + "&limit=10")
        .then((response) => {
            // Handle success.
            // console.log(cat, ': Data Received', response.data);
            return response.data;
        });
};

const getHospitalList = async () => {

    return await axios
        .get(appConfig.hospitals)
        .then((response) => {
            // Handle success.
            // console.log(': getHospitalList Received', response.data);
            return response.data;
        });
};

const getSearchParamID = async (cat, q, limit = 10) => {
    let query = "?q=" + q.split("(")[0] + "&cat=" + cat + "&limit=" + limit;

    return await axios
        .get(appConfig.home + "/search" + query)
        .then((response) => {
            // Handle success.
            // console.log(cat, ': Data Received', response.data);
            return response.data;
        });
};

const getHomeContent = async () => {
    return await axios.get(appConfig.home).then((response) => {
        // Handle success.
        // console.log('HOME-PAGE: Data Received', response.data);
        return response.data;
    });
};

const getParams = (url) => {
    let params = {};
    let parser = document.createElement("a");
    parser.href = url;
    let query = parser.search.substring(1);
    let vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        let pair = vars[i].split("=");
        params[pair[0]] = decodeURIComponent(pair[1]);
    }
    return params;
};

const getURLq = (url) => {
    let parser = document.createElement("a");
    parser.href = url;
    let query = parser.search.substring(1);
    // console.log("URL Query is : ", query);
    return query;
};

const getDoctors = async (query, limit = 10, start = 0) => {
    // let query = "?q=" + q + "&cat=" + cat;
    // console.log("SEARCH :: Query is : ", query)
    query += '&_limit=' + limit;
    query += '&_start=' + start;
    let newQuery = query.replace("doctor", "id");
    //   //////////////////////////////////////////// iam going to change error here in the query
    return await axios.get(appConfig.doctors + newQuery).then((response) => {
        // Handle success.
        // console.log('SEARCH :: Doctors Received', query, response.data);
        return response.data;
    });
};

const getHospitals = async (query, limit = 10, start = 0) => {
    // let query = "?q=" + q + "&cat=" + cat;
    // console.log("Query is : ", query);
    query += '&_limit=' + limit;
    query += '&_start=' + start;

    // console.log("Query is : ", query)
    let newQuery = query.replace("hospital", "id");
    //   //////////////////////////////////////////// iam going to change error here in the query
    return await axios.get(appConfig.hospitals + newQuery).then((response) => {
        // Handle success.
        // console.log(': Data Received', query, response.data);
        return response.data;
    });
};

const getCategoryData = async (cat, slug) => {
    // let query = "?q=" + q + "&cat=" + cat;
    // console.log("Query is : ", query);
    let newQuery = "/" + slug;
    //   //////////////////////////////////////////// iam going to change error here in the query
    return await axios.get(appConfig[cat] + newQuery).then((response) => {
        // Handle success.
        // console.log(': Data Received', query, response.data);
        return response.data;
    });
};

const getLimitedSearchData = async (cat, q) => {
    let query = "?q=" + q.split("(")[0] + "&cat=" + cat;
    return await axios
        .get(appConfig.limitedSearch + "" + query)
        .then((response) => {
            // Handle success.
            // console.log(cat, ': Data Received', response.data);
            return response.data;
        });
};


const findinState = async (cat, q) => {
    let query = "?q=" + q.split("(")[0] + "&cat=" + cat;
    return await axios
        .get(appConfig.limitedSearch + "" + query)
        .then((response) => {
            // Handle success.
            // console.log(cat, ': Data Received', response.data);
            return response.data;
        });
};

const storeTest = async () => {
    let state = store.getState();
    // return state;

    let data = state.data;
    return data;

}



const getCount = async (query) => {
    // console.log("Query is : ", query)
    return await axios.get(appConfig.hospitalCount + query).then((response) => {
        // Handle success.
        // console.log(': Data Received', query, response.data);
        return response.data;
    });
}

const getHCities = async (prms) => {
    let query = createHosQuery(parms2Array(prms))
    // console.log("Query is : ", query)

    return await axios.get(appConfig.getHCities + '?' + query).then((response) => {
        // Handle success.
        // console.log(': Data Received', query, response.data);
        return response.data;
    });
}

const getDCities = async (prms) => {
    let query = createHosQuery(parms2Array(prms))
    // console.log("Query is : ", query)

    return await axios.get(appConfig.getDCities + '?' + query).then((response) => {
        // Handle success.
        // console.log(': Data Received', query, response.data);
        return response.data;
    });
}

// const serialize = function(obj) {
//     var str = [];
//     for (var p in obj)
//         if (obj.hasOwnProperty(p)) {
//             str.push(encodeURIComponent(p.toLowerCase()) + "=" + encodeURIComponent(obj[p]));
//         }
//     return str.join("&");
// }

const parms2Array = (params) => {
    let prms = [];

    let qerh, qerd = '';

    // console.log("The params are : ", params)
    if (params['Treatment']) {
        prms.push({'treatment': params['Treatment']})
    }
    if (params['Condition']) {
        prms.push({'condition': params['Condition']})
    }
    if (params['Specialisation']) {
        prms.push({'specialisation': params['Specialisation']})
    }
    if (params['Speciality']) {
        prms.push({'speciality': params['Speciality']})
    }
    if (params['Hospital']) {
        prms.push({'hospital': params['Hospital']})
    }
    if (params['Doctor']) {
        prms.push({'doctor': params['Doctor']})
    }
    // console.log("params ", params);
    // console.log("prms ", prms);
    return prms;
}

const createHosQuery = (prms) => {
    // console.log("prms ", prms);
    let qerh = "";
    prms.map(query => {
        if (query) {
            if (query.category === "hospital") {
                qerh = qerh.length
                    ? qerh + "&" + "id=" + query.id
                    : "id=" + query.id;
            } else if (query.category === "doctor") {
                qerh = qerh.length
                    ? qerh + "&" + "doctors.id=" + query.id
                    : "doctors.id=" + query.id;
            } else {
                qerh = qerh.length
                    ? qerh +
                    "&" +
                    searchKeywords[Object.keys(query)[0]] +
                    "=" +
                    Object.values(query)[0]
                    : searchKeywords[Object.keys(query)[0]] + "=" + Object.values(query)[0];
            }
        }
    })
    // console.log("SEARCH qerh = ", qerh);
    return qerh;
}

const createDocQuery = (prms) => {
    let qerd = "";
    prms.map(query => {
        if (query) {
            if (query.category === "doctor") {
                qerd = qerd.length
                    ? qerd + "&" + "id=" + query.id
                    : "id=" + query.id;
            } else {
                qerd = qerd.length
                    ? qerd +
                    "&" +
                    searchKeywords[query.category] +
                    "=" +
                    query.id
                    : searchKeywords[query.category] + "=" + query.id;
            }
        }
    })
    // console.log("SEARCH qerd = ", qerd);
    return qerd;
}

const cleanTags = (input, allowed="<b><i><u><p><ol><ul><li>") =>{
    allowed = (((allowed || '') + '')
        .toLowerCase()
        .match(/<[a-z][a-z0-9]*>/g) || [])
        .join(''); // making sure the allowed arg is a string containing only tags in lowercase (<a><b><c>)
    let tags = /<\/?([a-z][a-z0-9]*)\b[^>]*>/gi,
        commentsAndPhpTags = /<!--[\s\S]*?-->|<\?(?:php)?[\s\S]*?\?>/gi;
    return input.replace(commentsAndPhpTags, '')
        .replace(tags, function ($0, $1) {
            return allowed.indexOf('<' + $1.toLowerCase() + '>') > -1 ? $0 : '';
        });
}

export {
    getURLq,
    getHospitalList,
    getMyLocation,
    editUser,
    getSearchData,
    getHomeContent,
    getParams,
    getSearchParamID,
    getDoctors,
    getHospitals,
    editHospital,
    getCategoryData,
    getLimitedSearchData,

    createDocQuery,
    parms2Array,

    storeTest,
    getCount,
    getHCities,
    getDCities,

    cleanTags,
};
