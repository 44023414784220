import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const headerTab = ({
                       handleTab,
                       icon,
                       name,
                       tabName,
                       tabSelected,
                       searchName,
                       iconImage,
                   }) => {
    const handleClick = () => {
        handleTab(name, searchName);
    };
    return (
        <div onClick={handleClick}>
            <li
                //borderBottom: tabSelected === name ? "5px solid #607da9" : "none",
                //{tabSelected === name?{filter: grayscale(91%)}:null}

                className={`li ${tabSelected === name ? null : 'tabUnSelected'}`}
                style={{

                    cursor: "pointer",

                }}
            >
                {/*<FontAwesomeIcon*/}
                {/*  icon={icon}*/}
                {/*  className="font"*/}
                {/*  style={{*/}
                {/*    color: `${tabSelected === name ? "#607da9" : "#000"}`,*/}
                {/*  }}*/}
                {/*/>*/}
                <img
                    src={`${process.env.PUBLIC_URL}/assets/images/icons/${iconImage}`}
                    style={{width: '50px', height: '50px'}}
                />
                <p style={{color: `${tabSelected === name ? "#607da9" : "#000"}`, fontSize: '10px'}}>
                    {tabName}
                </p>
            </li>
        </div>
    );
};

export default headerTab;
