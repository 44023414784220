import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import Text from "antd/lib/typography/Text";
import {Popover, Tooltip} from "antd";


class Accredetion extends Component {

    nabhLogo = `${process.env.PUBLIC_URL}/assets/images/accred/nabh.jpg`;
    jciLogo = `${process.env.PUBLIC_URL}/assets/images/accred/jci.png`;

    getNABH() {
        return(
            <Tooltip title="NABH Accredited"  placement={"top"}>
                <img src={this.nabhLogo} style={{width: "30px", paddingBottom: "4px", margin:'10px'}} alt={"verified"}/>
            </Tooltip>
        )
    }

    getJCI(item) {
        return(
            <Tooltip title="JCI Accredited"  placement={"top"}>
                <img src={this.jciLogo} style={{width: "30px", paddingBottom: "4px", margin:'10px'}} alt={"verified"}/>
            </Tooltip>
        )
    }


    render() {
        const {item} = this.props;
        return (
            <div style={{marginLeft:'-10px'}}>
                {item.JCI ? this.getJCI() : null}
                {item.NABH ? this.getNABH() : null}
            </div>
        )
    }
}

export default Accredetion;