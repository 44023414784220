import React, {Component} from 'react';
import {connect} from 'react-redux'
import {Link} from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import {getHospitals, getHospital} from '../../../redux/actions/hospitalActions'


// import {getTotal, getCartProducts} from '../../../redux/reducers'
import {addToCart, addToWishlist, addToCompare} from '../../../redux/actions'
import {getVisibleHospitals} from '../../../services';
//import ProductListItem from "./_old/product-list-item";
import HospitalListItem from "./hospital-list-item";

class HospitalListing extends Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 12,
            hasMoreItems: true
        };
        this.fetchMoreItems = this.fetchMoreItems.bind(this);
    }

    componentWillMount() {
        //this.fetchMoreItems();
        //this.props.getHospitals(this.props.filters);
    }

    fetchMoreItems = (hospitals) => {
        //let limit = 6
        console.log("reached ", this.state.limit, this.props.hospitals.hospitals.length, hospitals)
        if (this.state.limit >= hospitals.hospitals.length) {
            this.setState({hasMoreItems: false});
            return;
        }
        // a fake async api call
        setTimeout(() => {
            this.setState({
                limit: this.state.limit + 8
            });
        }, 1000);
    }

    testState = () => {
        console.log("HOSPITAL/LISTING State Test", this);
    }

    render() {
        const {products, hospitals, addToCart, symbol, addToWishlist, addToCompare} = this.props;

        window.hlprop = this.props;
        window.hlstate = this.state;

        //console.log("HOSPITAL/LISTING props", this.props);
        //console.log("HOSPITAL/LISTING hospitals", this.props.hospitals);

        return (
            <div>
                <div className="product-wrapper-grid" >
                    <div className="container-fluid">
                        {hospitals.hospitals.length > 0 ?
                            <InfiniteScroll
                                dataLength={this.state.limit} //This is important field to render the next data
                                next={()=>this.fetchMoreItems(hospitals)}
                                hasMore={this.state.hasMoreItems}
                                loader={<div className="loading-cls"></div>}
                                endMessage={
                                    <p className="seen-cls seen-it-cls">
                                        <b>Yay! You have seen it all</b>
                                    </p>
                                }
                            >
                                <div className="row" >
                                {/*    style={{background: '#ececec'}} */}
                                {/*<div className="site-card-border-less-wrapper row">*/}
                                    {hospitals.hospitals.slice(0, this.state.limit).map((product, index) =>
                                        <div
                                            className={`${this.props.colSize === 3 ? 'col-xl-3 col-md-6 col-grid-box' : 'col-lg-' + this.props.colSize}`}
                                            key={index}>
                                            {/*<div className={`${this.props.colSize===3?'col-xl-3 col-md-6 col-grid-box':'col-lg-'+this.props.colSize}`} key={index}>*/}
                                            <HospitalListItem product={product} symbol={symbol}
                                                              filters={this.props.filters}
                                                              onAddToCompareClicked={() => addToCompare(product)}
                                                              onAddToWishlistClicked={() => addToWishlist(product)}
                                                              onAddToCartClicked={addToCart} key={index}/>
                                        </div>)
                                    }
                                </div>
                            </InfiniteScroll>
                            :
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`}
                                         className="img-fluid mb-4"/>
                                    <h3>Sorry! Couldn't find the product you were looking For!!! </h3>
                                    <p>Please check if you have misspelt something or try searching with other
                                        words.</p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue
                                        shopping</Link>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    //products: getVisibleHospitals(state.data, state.filters),
    //hospitals: [],
    hospitals: state.hospital,
    filters: state.filters,
    symbol: state.data.symbol,
})

export default connect(
    mapStateToProps, {addToCart, addToWishlist, addToCompare, getHospitals}
)(HospitalListing)