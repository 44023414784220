import React from "react";
import { Link } from "react-router-dom";
import { getDoctors } from "../../api/apicalls";
import "./index.scss";
import { Product5, Slider5, Slider6, SliderHome1 } from "../../services/script";
import Slider from "react-slick";


class CardHeader extends React.Component {
  render() {
    const { image } = this.props;
    var style = {
      //   backgroundImage: "url(" + image + ")",
      padding: "0",
      borderRadius: "5px",
      width:'143px', height:'143px'
    };
    return (
      <div
        style={{ display: "flex", justifyContent: "center", padding: "0 10px" , }}
      >
        <img
          style={style}
          alt="imagecard"
          src={image}
          className="card-header"
        />
      </div>
    );
  }
}

class Button extends React.Component {
  render() {
    return (
      <Link to={this.props.link} className="button button-primary">
        <i className="fa fa-chevron-right"></i> Find out more
      </Link>
    );
  }
}

class CardBody extends React.Component {
  render() {
    return (
      <div className="card-body">
        {/* <p className="date">March 20 2015</p> */}

        <span style={{fontWeight:'700'}}>{this.props.title}</span>

        {/* <p className="body-content">{this.props.text}</p>

        <Button link={this.props.link} /> */}
      </div>
    );
  }
}

const NewCard1 = ({ type }) => {
  const [doctors, setDoctors] = React.useState([]);

  React.useState(() => {
    console.log("hiiiiiiiiiiiiiii");
    let query = "?specialities=" + type + "&_sort=Rating:DESC";
    getDoctors(query).then((res) => {
      console.log(type, res);
      setDoctors(res);
    });
  }, []);
  return (
    <Slider
      style={{ width: "100%" }}
      {...Slider5}
      className="top_hospitals no-arrow"
    >
      {doctors.map((item, idx) => (
        <div key={idx} style={{width:'170px'}}>
          <article className="card shadow-sm" style={{ height: "220px",width:'150px' }}>
            <CardHeader
              image={
                (item.Pictures &&
                  item.Pictures.length &&
                  item.Pictures[0].url) ||
                "/assets/images/2.jpg"
              }
            />
            <CardBody
              title={item.Name}
              //   text="Lorem10  hi hi hih ihi h ih ii hih i hi h ih ih ih ih ih ih ihi hi "
              //   link={"/"}
            />
          </article>
        </div>
      ))}
    </Slider>
  );
};

export default NewCard1;
