import {
    ADD_TO_CART,
    REMOVE_FROM_CART,
    INCREMENT_QTY,
    DECREMENT_QTY
} from "../../constants/ActionTypes";


export default function cartReducer(state = {
    cart: []
}, action) {
    //console.log("performing action", action, state);
    switch (action.type) {
        case ADD_TO_CART:
            const productId = action.product.id;
            console.log("performing action ", state);
            if (state.cart.findIndex(product => product.id === productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    product.Discount = product.Discount ? product.Discount : 0;

                    if (product.id === productId) {
                        cartAcc=[{
                            ...product,
                            qty: 1,
                            bType: action.bType,
                            bTime: action.bTime,
                            sum: (product.Price - (product.Price * product.Discount / 100)) * (1)
                        }]
                        // cartAcc.push({
                        //     ...product,
                        //     qty: 1,
                        //     bType: action.bType,
                        //     bTime: action.bTime,
                        //     sum: (product.Price - (product.Price * product.Discount / 100)) * (1)
                        // }) // Increment qty
                    } else {
                        // cartAcc.push(product)
                        cartAcc = [product]
                    }

                    return cartAcc
                }, [])

                return {...state, cart}
            }

            return {
                ...state,
                cart: [{
                    ...action.product,
                    qty: 1,
                    bType: action.bType,
                    bTime: action.bTime,
                    sum: (action.product.Price - (action.product.Price * action.product.Discount / 100)) * action.qty
                }]
            }

        case DECREMENT_QTY:

            if (state.cart.findIndex(product => product.id === action.productId) !== -1) {
                const cart = state.cart.reduce((cartAcc, product) => {
                    if (product.id === action.productId && product.qty > 1) {
                        //console.log('price: '+product.price+'Qty: '+product.qty)
                        cartAcc.push({
                            ...product,
                            qty: product.qty - 1,
                            sum: (product.Price * product.Discount / 100) * (product.qty - 1)
                        }) // Decrement qty
                    } else {
                        cartAcc.push(product)
                    }

                    return cartAcc
                }, [])

                return {...state, cart}
            }

            return {
                ...state,
                cart: [...state.cart, {...action.product, qty: action.qty, sum: action.product.price * action.qty}]
            }

        case REMOVE_FROM_CART:
            return {
                cart: state.cart.filter(item => item.id !== action.product_id.id)
            }

        default:
    }
    return state;
}
