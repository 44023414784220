import React, {Component} from "react";
// import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import "react-tabs/style/react-tabs.scss";
// import {Link} from 'react-router-dom';
import {GoogleApiWrapper} from "google-maps-react";
import ReactStarsRating from "react-awesome-stars-rating";
import axios from "axios";
import appConfig from "../../../../config";
// import * as types from "../../../../constants/ActionTypes";
import CommentsListHosital from "./comments-list-hosital";
import _ from "lodash";

class CommentsHosital extends Component {
    constructor(props) {
        super(props);
        this.state = {
            item: this.props.item ? this.props.item : [],
            auth: this.props.auth,
            type: this.props.type,
            comments: [],
            cleanliness: 0,
            medicalCare: 0,
            waitTime: 0,
            infrastructure: 0,
            staffAttitude: 0,
            totalRank: 0,

            name: '',
            email: "",
            title: "",
            review: "",
            errorMessage:"",
            overall: {
                cleanliness: 5,
                medicalCare: 5,
                waitTime: 5,
                infrastructure: 5,
                staffAttitude: 5,
                totalRank: 5,
            },
        };
        this.handleChange = this.handleChange.bind(this);
        this.submitReview = this.submitReview.bind(this);
        this.updateRating = this.updateRating.bind(this);
    }

    componentDidMount() {
        this.getComments(this.state.item);
        if (this.state.auth.isLogedIn) {
            console.log(">>>>>>>>>>>>>>>", this.state.auth.user.user.name);
            this.setState({
                name: this.state.auth.user.user.name?this.state.auth.user.user.name:'',
                email: this.state.auth.user.user.email,
            });
        }
        this.setTotal();
    }

    handleChange(event) {
        //console.log('handleChange', this);
        const {name, value} = event.target;
        // console.log(name, value, event.target.value);
        // console.log('handleChange', this);
        // const {user} = this.state;
        this.setState({
            [name]: value,
        });
    }

    onChange = (value, type) => {
        // console.log(`React Stars Rating value is ${value}`);
        if (type === "cleanliness") {
            this.setState({cleanliness: value}, this.setTotal);
        }
        if (type === "medicalCare") {
            this.setState({medicalCare: value}, this.setTotal);
        }
        if (type === "waitTime") {
            this.setState({waitTime: value}, this.setTotal);
        }
        if (type === "infrastructure") {
            this.setState({infrastructure: value}, this.setTotal);
        }
        if (type === "staffAttitude") {
            this.setState({staffAttitude: value}, this.setTotal);
        }

        if (type === "name") {
            this.setState({name: value}, this.setTotal);
        }
        if (type === "email") {
            this.setState({email: value}, this.setTotal);
        }
        if (type === "title") {
            this.setState({title: value}, this.setTotal);
        }
        if (type === "review") {
            this.setState({review: value}, this.setTotal);
        }
        //this.setTotal();
    };

    setTotal() {
        let overall = {
            cleanliness: 5,
            medicalCare: 5,
            waitTime: 5,
            infrastructure: 5,
            staffAttitude: 5,
            totalRank: 5,
        };
        let total =
            (this.state.staffAttitude +
                this.state.infrastructure +
                this.state.waitTime +
                this.state.medicalCare +
                this.state.cleanliness) /
            5;
        const comm = this.state.comments;

        overall.cleanliness = (
            (_.sumBy(comm, "Cleanliness") + this.state.cleanliness) /
            (comm.length + 1)
        ).toFixed(2);
        overall.medicalCare = (
            (_.sumBy(comm, "MedicalCare") + this.state.medicalCare) /
            (comm.length + 1)
        ).toFixed(2);
        overall.waitTime = (
            (_.sumBy(comm, "WaitTime") + this.state.waitTime) /
            (comm.length + 1)
        ).toFixed(2);
        overall.infrastructure = (
            (_.sumBy(comm, "Infrastructure") + this.state.infrastructure) /
            (comm.length + 1)
        ).toFixed(2);
        overall.staffAttitude = (
            (_.sumBy(comm, "StaffAttitude") + this.state.staffAttitude) /
            (comm.length + 1)
        ).toFixed(2);

        overall.totalRank = (
            (parseFloat(overall.cleanliness) +
                parseFloat(overall.medicalCare) +
                parseFloat(overall.waitTime) +
                parseFloat(overall.infrastructure) +
                parseFloat(overall.staffAttitude)) /
            (comm.length + 1)
        ).toFixed(2);
        if (this.props.type === "doctor") {
            this.setState({
                overall,
                totalRank: this.state.cleanliness,
            });
            return;
        }
        this.setState({
            overall,
            totalRank: total,
        });

        // console.log("Total rank is ", total);
        // console.log("Total rank is ", overall);
    }

    getComments(item) {
        let param =
            "?Type=" + this.state.type + "&" + this.state.type + "=" + item.id;
        axios.get(appConfig.comments + param).then((response) => {
            // Handle success.
            // console.log('COMMENTS/ACTIONS: Data Received : ', response);
            this.setState({
                comments: response.data,
                cleanliness: 0,
                medicalCare: 0,
                waitTime: 0,
                infrastructure: 0,
                staffAttitude: 0,
                name: "",
                email: "",
                title: "",
                review: "",
            });
        });
    }

    updateRating() {
        let auth = this.props.auth;
        // console.log("auth is : ", auth);

        if (auth.isLogedIn && this.state.type === "hospital") {
            let data = {
                Rating: this.state.overall.totalRank,
                Cleanliness: this.state.overall.cleanliness,
                MedicalCare: this.state.overall.medicalCare,
                WaitTime: this.state.overall.waitTime,
                Infrastructure: this.state.overall.infrastructure,
                StaffAttitude: this.state.overall.staffAttitude,
            };
            let url = appConfig.hospitals + "/" + this.state.item.id;

            axios
                .put(url, data, {
                    headers: {
                        Authorization: "Bearer " + auth.user.jwt,
                    },
                })
                .then((response) => {
                    // console.log('Rating updated', response.data);
                })
                .catch((error, resp) => {
                    // Handle error.
                    console.log("An error occurred:", error, resp);
                });
        }
    }

    submitReview() {
        if(!this.state.title ){
            this.setState({errorMessage:"Title is Required"})
            return false
        }
        if(!this.state.review ){
            this.setState({errorMessage:"Review is Required"})
            return false
        }
        this.setState({errorMessage:''})
        let review = {
            Cleanliness: this.state.cleanliness,
            MedicalCare: this.state.medicalCare,
            WaitTime: this.state.waitTime,
            Infrastructure: this.state.infrastructure,
            StaffAttitude: this.state.staffAttitude,

            Name: this.state.name? this.state.name : "Anonymous",
            Email: this.state.email,
            Title: this.state.title?this.state.title:'',
            Review: this.state.review,
            Type: this.state.type,
            //hospital: this.state.item.id,
        };
        if (this.state.type === "hospital") {
            review.hospital = this.state.item.id;
        }
        if (this.state.type === "doctor") {
            review.doctor = this.state.item.id;
        }
        if (this.state.auth.isLogedIn) {
            review.user = this.state.auth.user.user.id;
        }
        console.log("Submit review ", review);
        this.postReview(review);
    }

    postReview(review) {
        axios
            .post(appConfig.comments, review)
            .then((response) => {
                // Handle success.
                // console.log('Well done!');
                // console.log('Comments', response.data);
                // this.setState({comments: response.data});
                this.getComments(this.state.item);
                this.updateRating();
            })
            .catch((error, resp) => {
                // Handle error.
                console.log("An error occurred:", error, resp);
            });
    }

    render() {
        const {item} = this.props;
        //const {auth} = this.state;
        let {comments} = this.state;
        // console.log(this.state, "STATE");
        // console.log("HOSPITAL/COMMENTS item recived : ", item, comments);
        // console.log("HOSPITAL/COMMENTS props : ", this.props);
        // console.log("HOSPITAL/COMMENTS sate : ", this.state);

        window.comprop = this.props;
        window.comstate = this.state;

        // const mapStyles = {
        //     width: '100%',
        //     height: '100%',
        //     top: '0px',
        // };

        return (
            <div>
                <div>
                    {comments.length ? (
                        <CommentsListHosital comments={comments} type={this.state.type}/>
                    ) : (
                        ""
                    )}
                </div>
                <form
                    className="theme-form mt-4 width-75"
                    style={{borderTop: "1px solid #dee2e6"}}
                    onSubmit={this.submitReview}
                >
                    <div>
                        <h2 style={{paddingTop: "20px"}}>Add Your Review</h2>
                    </div>
                    <div className="row justify-content-between" style={{padding: 0}}>
                        <div className="col-md-9 col-lg-6 ">
                            {this.props.type === "doctor" ? (
                                <div
                                    className="media-body ml-3 ratingContainer"
                                    style={{display: "flex", justifyContent: "space-between"}}
                                >
                                    <label
                                        style={{
                                            fontSize: "17px",
                                            color: "#7e7e7e",
                                        }}
                                    >
                                        Will you recommend this Doctor
                                    </label>
                                    <ReactStarsRating
                                        onChange={(value) => {
                                            this.onChange(value, "cleanliness");
                                        }}
                                        value={this.state.cleanliness}
                                        size={20}
                                        starGap={5}
                                        isHalf={false}
                                    />
                                </div>
                            ) : (
                                <div>
                                    <div
                                        className="media-body ml-3"
                                        style={{display: "flex", justifyContent: "space-between"}}
                                    >
                                        <label
                                            style={{
                                                fontSize: "17px",
                                                color: "#7e7e7e",
                                            }}
                                        >
                                            Cleanliness
                                        </label>
                                        <ReactStarsRating
                                            onChange={(value) => {
                                                this.onChange(value, "cleanliness");
                                            }}
                                            value={this.state.cleanliness}
                                            size={20}
                                            starGap={5}
                                            isHalf={false}
                                        />
                                    </div>

                                    <div
                                        className="media-body ml-3"
                                        style={{display: "flex", justifyContent: "space-between"}}
                                    >
                                        <label
                                            style={{
                                                fontSize: "17px",
                                                color: "#7e7e7e",
                                            }}
                                        >
                                            Medical Care
                                        </label>
                                        <ReactStarsRating
                                            onChange={(value) => {
                                                this.onChange(value, "medicalCare");
                                            }}
                                            value={this.state.medicalCare}
                                            size={20}
                                            starGap={5}
                                            isHalf={false}
                                        />
                                    </div>

                                    <div
                                        className="media-body ml-3"
                                        style={{display: "flex", justifyContent: "space-between"}}
                                    >
                                        <label
                                            style={{
                                                fontSize: "17px",
                                                color: "#7e7e7e",
                                            }}
                                        >
                                            Infrastructure
                                        </label>
                                        <ReactStarsRating
                                            onChange={(value) => {
                                                this.onChange(value, "infrastructure");
                                            }}
                                            value={this.state.infrastructure}
                                            size={20}
                                            starGap={5}
                                            isHalf={false}
                                        />
                                    </div>

                                    <div
                                        className="media-body ml-3"
                                        style={{display: "flex", justifyContent: "space-between"}}
                                    >
                                        <label
                                            style={{
                                                fontSize: "17px",
                                                color: "#7e7e7e",
                                            }}
                                        >
                                            Staff Attitude
                                        </label>
                                        <ReactStarsRating
                                            onChange={(value) => {
                                                this.onChange(value, "staffAttitude");
                                            }}
                                            value={this.state.staffAttitude}
                                            size={20}
                                            starGap={5}
                                            isHalf={false}
                                        />
                                    </div>

                                    <div
                                        className="media-body ml-3"
                                        style={{display: "flex", justifyContent: "space-between"}}
                                    >
                                        <label
                                            style={{
                                                fontSize: "17px",
                                                color: "#7e7e7e",
                                            }}
                                        >
                                            Actual cost Vs Initial Estimate
                                        </label>
                                        <ReactStarsRating
                                            onChange={(value) => {
                                                this.onChange(value, "waitTime");
                                            }}
                                            value={this.state.waitTime}
                                            size={20}
                                            starGap={5}
                                            isHalf={false}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>

                        <div
                            className="col-md-3"
                            style={{
                                background: "#f2f2f2",
                                border: "1px solid #dadada",
                                borderRadius: "10px",
                            }}
                        >
                            <div
                                style={{margin: "auto", width: "50%", textAlign: "center"}}
                            >
                                <h2 style={{color: "#848181", paddingTop: "40%"}}>
                                    {this.state.totalRank}
                                </h2>
                                <span style={{color: "#848181"}}>Overall Rating</span>
                            </div>
                        </div>
                    </div>

                    <div className="form-row">
                        {/*<div className="col-md-12 ">*/}
                        {/*    <div className="media m-0">*/}
                        {/*        <label>Rating</label>*/}
                        {/*        <div className="media-body ml-3">*/}
                        {/*            <ReactStarsRating onChange={this.onChange} value={5} size={20}/>*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}

                        <div className="col-md-6">
                            <label htmlFor="name" style={{marginBottom: '0px', marginTop: '10px'}}>Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={this.state.name}
                                onChange={this.handleChange}
                                placeholder="Enter Your name"
                                required
                            />
                        </div>
                        <div className="col-md-6">
                            <label htmlFor="email" style={{marginBottom: '0px', marginTop: '10px'}}>Email</label>
                            <input
                                type="text"
                                className="form-control"
                                id="email"
                                name="email"
                                value={this.state.email}
                                onChange={this.handleChange}
                                placeholder="Email"
                                required
                            />
                        </div>

                        <div className="col-md-12">
                            <label htmlFor="review" style={{marginBottom: '0px', marginTop: '10px'}}>Title of Review (Required)</label>
                            <input
                                type="text"
                                className="form-control"
                                id="review"
                                name="title"
                                value={this.state.title}
                                onChange={this.handleChange}
                                placeholder="Enter your Review Subjects"
                                required
                            />
                        </div>
                        <div className="col-md-12">
                            <label htmlFor="review" style={{marginBottom: '0px', marginTop: '10px'}}>Your Review (Required)</label>
                            <textarea
                                className="form-control"
                                placeholder="Wrire Your Review Here"
                                name="review"
                                value={this.state.review}
                                onChange={this.handleChange}
                                id="exampleFormControlTextarea1"
                                rows="6"
                                required
                            />
                        </div>

                        <div
                            className="col-md-12"
                            style={{marginBottom: "25px", marginTop: "15px"}}
                        >
                            <span style={{color:'#d02121'}}>{this.state.errorMessage}</span><br/>
                            <div
                                style={{padding: "13px 10px"}}
                                className="btn btn-solid"
                                type="submit"
                                onClick={this.submitReview}
                            >
                                Submit Your Review
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

//export default DetailsTopTabs;
export default GoogleApiWrapper({
    apiKey: "AIzaSyClT2rSG4_sAQr3HPFxxenREGLsr__Pk9c",
})(CommentsHosital);
