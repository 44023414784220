import React, {Component} from "react";
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import {getDoctors} from "../../../redux/actions/doctorActions";
import {Skeleton} from "antd";
import _ from "lodash";

import {
    addToCart,
    addToWishlist,
    addToCompare,
    filterAddCities,
    filterDCity,
    filterAddHospitalCities,
} from "../../../redux/actions";

import {searchKeywords} from "../../../services";
import SearchCheck from "../../search/search";
import qs from 'qs';
import {
    getParams,
    getSearchParamID,
    getDoctors as searchDoctors,
    getHospitals as searchHospitals, storeTest, getCount,
} from "../../../api/apicalls";

class SearchListing extends Component {
    constructor(props) {
        super(props);

        this.state = {
            limit: 10,
            page: 0,
            hasMoreItems: true,
            searchResult: [],
            filteredResults: [],
            isLoading: true,
            qerd: '',
            qerh: '',
            searchParams: [],
            count: 0,
            filterq: '',
        };
    }

    componentWillMount() {
        if (this.props.location.search) {
            this.getListingData();
        } else {
            console.log("This is not right")
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.location.search !== prevProps.location.search) {
            // this.getSearchParam();
        }
        if (prevProps.searchString !== this.props.searchString) {
            // console.log("SEARCH-LISTING :: searchString Updated")
            this.setState(
                {
                    searchResult: [],
                    filteredResults: [],
                    limit: 10,
                    page: 0,
                    hasMoreItems: true,
                    isLoading: true,
                },
                () => {
                    this.getListingData();
                }
            );
        }
        // console.log("prevProps.filterDoc", prevProps.rateSelected);
        // console.log("this.props.filterDoc", this.props.rateSelected);
        if (JSON.stringify(prevProps.filterDoc.dCheckedCity) !== JSON.stringify(this.props.filterDoc.dCheckedCity)) {
            this.filterData();
        }
        if (JSON.stringify(prevProps.filterDoc.rateSelected) !== JSON.stringify(this.props.filterDoc.rateSelected)) {
            this.filterData();
        }
        if (JSON.stringify(prevProps.filterDoc.docExp) !== JSON.stringify(this.props.filterDoc.docExp)) {
            this.filterData();
        }

    }

    filterData() {
        let {filterDoc} = this.props;
        let qobj = {};

        let cities = filterDoc.dCheckedCity;
        let rateSelected = filterDoc.rateSelected;

        let cq = [];
        if (cities && cities.length) {

            cities.map((c, indx) => {
                let q = {City: c};
                if (rateSelected) {
                    q.googleRating_gte = rateSelected
                }
                cq.push(q)
            })
            // console.log("Cities Query ", cq)
            qobj = {
                _where: {
                    _or: cq
                }
            }
        } else {
            if (rateSelected) {
                qobj = {
                    _where: [
                        {googleRating_gte: rateSelected}
                    ]
                }
            }
        }

        let q = qs.stringify(qobj, {encode: false});

        this.setState(
            {
                searchResult: [],
                filteredResults: [],
                limit: 10,
                page: 0,
                hasMoreItems: true,
                isLoading: true,
                filterq: q,
                docExp: this.props.filterDoc.docExp,
            },
            () => {
                this.getListingData();
            }
        );

    }


    getSearchCount = (query) => {
        getCount(query).then(count => {
            // console.log("Count is : ", count);
            this.setState({count})
        })
    }

    getListingData = (qer) => {
        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href);
        let prms = [];

        let qerh, qerd = '';

        // console.log("The params are : ", params)
        if (params['Specialisation']) {
            prms.push(this.getSearchItem('specialisation', params['Specialisation']))
        }
        if (params['Speciality']) {
            prms.push(this.getSearchItem('speciality', params['Speciality']))
        }

        if (params['Treatment']) {
            if(!params['Speciality']){
                prms.push(this.getSearchItem('treatment', params['Treatment']))
            }

        }
        if (params['Condition']) {
            if(!params['Speciality']){
                prms.push(this.getSearchItem('condition', params['Condition']))
            }
        }

        if (params['Hospital']) {
            prms.push(this.getSearchItem('hospital', params['Hospital']))
        }
        if (params['Doctor']) {
            prms.push(this.getSearchItem('doctor', params['Doctor']))
        }
        // console.log("SEARCH FOR ", prms);
        qerh = this.createHosQuery(prms);
        qerd = this.createDocQuery(prms);

        this.setState({qerh, qerd, searchParams: prms})

        if (this.state.filterq) {
            qerh = this.state.filterq + "&" + qerh
        } else {
            // qerh="?"+qerh
        }
        this.getSearchCount("?" + qerh)

        // console.log("Search Query is : ", qerh);

        searchHospitals("?" + qerh, this.state.limit, this.state.page).then(src => {
            // console.log("SEARCH RESULT : ", src);
            if (src.length) {
                let hos = src;

                let arr = _.sortBy(src, "id");

                this.setState({
                    searchResult: [...this.state.searchResult, ...arr],
                    filteredResults: [...this.state.filteredResults, ...arr],
                    isLoading: false,
                    page: this.state.page + this.state.limit,
                });

                let cities = [];
                arr.forEach((element) => {
                    if (cities.indexOf(element.City) === -1)
                        cities.push(element.City);
                });

                this.props.handleCity(cities);

                this.props.filterAddCities(cities);
                this.props.filterDCity(cities);
                if (this.state.searchResult.length >= this.state.count) {
                    this.setState({hasMoreItems: false})
                }
            } else {
                this.setState({isLoading: false})
            }
        })
    }

    createHosQuery = (prms) => {
        let qerh = "";
        prms.map(query => {
            if (query) {
                if (query.category === "hospital") {
                    qerh = qerh.length
                        ? qerh + "&" + "id=" + query.id
                        : "id=" + query.id;
                } else if (query.category === "doctor") {
                    qerh = qerh.length
                        ? qerh + "&" + "doctors.id=" + query.id
                        : "doctors.id=" + query.id;
                } else {
                    qerh = qerh.length
                        ? qerh +
                        "&" +
                        searchKeywords[query.category] +
                        "=" +
                        query.id
                        : searchKeywords[query.category] + "=" + query.id;
                }
            }
        })
        // console.log("SEARCH qerh = ", qerh);
        return qerh;
    }

    createDocQuery = (prms) => {
        let qerd = "";
        prms.map(query => {
            if (query) {
                if (query.category === "doctor") {
                    qerd = qerd.length
                        ? qerd + "&" + "id=" + query.id
                        : "id=" + query.id;
                } else {
                    qerd = qerd.length
                        ? qerd +
                        "&" +
                        searchKeywords[query.category] +
                        "=" +
                        query.id
                        : searchKeywords[query.category] + "=" + query.id;
                }
            }
        })
        // console.log("SEARCH qerd = ", qerd);
        return qerd;
    }

    fetchMoreItems = () => {
        console.log("reached ", this.state.limit, this.props.doctors.doctors.length)
        this.getListingData()
    };

    getSearchItem = (cat, slug) => {
        let baseItem = {
            category: cat,
            id: slug
        };
        let item = {};
        // return item;

        switch (cat) {
            case 'treatment':
                item = _.find(this.props.suggTreatments, {value: slug})
                break;
            case 'condition':
                item = _.find(this.props.suggConditions, {value: slug})
                break;
            case 'specialisation':
                item = _.find(this.props.suggSpecialisations, {value: slug})
                break;
            case 'speciality':
                item = _.find(this.props.suggSpecialisations, {value: slug})
                break;
            case 'hospital':
                item = _.find(this.props.suggHospitals, {value: slug})
                break;
            case 'doctor':
                item = _.find(this.props.suggDoctors, {value: slug})
                break;
            default:
                console.log("Match Nothing")
        }
        return item ? item : baseItem;
    }

    getSkeleton = () => {
        if (!this.state.isLoading) {
            return null;
        } else {
            return <Skeleton active avatar paragraph={{rows: 7}}/>
        }
    }

    render() {
        const {searchResult, filteredResults, docExp} = this.state;
        window.dlstate = this.state;
        window.srclistingprops = this.props;

        return (
            <div>
                <div className="product-wrapper-grid">
                    <div className="product-top-filter">
                        <div className="container-fluid p-0">
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="filter-main-btn">
                                        {/*<span onClick={this.openFilter}*/}
                                        {/*      className="filter-btn btn btn-theme"><i*/}
                                        {/*    className="fa fa-filter"*/}
                                        {/*    aria-hidden="true"></i> Filter</span>*/}
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    {this.state.isLoading ? (<span>Searching ...</span>) : (<span>Found {this.state.count} Hospitals(s)</span>)}
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="container-fluid">
                        <div className="scrollableDiv row" style={{paddingTop: '10px', height: 'fit-content'}}>
                            {searchResult.length && searchResult.length > 0 ? (
                                <InfiniteScroll
                                    dataLength={filteredResults.length} //This is important field to render the next data
                                    next={this.fetchMoreItems}
                                    hasMore={this.state.hasMoreItems}
                                    loader={<div className="loading-cls"/>}
                                    useWindow={false}
                                    scrollableTarget="scrollableDiv"
                                    scrollThreshold={0.4}
                                    endMessage={
                                        <p className="seen-cls seen-it-cls">
                                            {/*<b>End of Search result</b>*/}
                                        </p>
                                    }
                                >
                                    {filteredResults.map((product, index) => {
                                        return (
                                            <div className={`${"col-lg-12"}`} key={index}>
                                                <SearchCheck type={product.Category}
                                                             data={product}
                                                             index={index}
                                                             query={this.state.searchParams}
                                                             docExp={docExp}
                                                             section={"all"}
                                                             rate={this.props.filterDoc.rateSelected}/>
                                                {/*<SearchCheck type="Doctor" data={product}/>*/}
                                            </div>
                                        );
                                    })}
                                </InfiniteScroll>
                            ) : (
                                this.getSkeleton()
                            )}
                        </div>
                        {!this.state.isLoading && searchResult.length === 0 ? (
                            <div className="row">
                                <div className="col-sm-12 text-center section-b-space mt-5 no-found">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/empty-search.jpg`} className="img-fluid mb-4" alt="Empty"/>
                                    <h3>Sorry! Couldn't find the result you were looking For!!!{" "}</h3>
                                    <p>
                                        Please check if you have misspelt something or try searching
                                        with other words.
                                    </p>
                                    <Link to={`${process.env.PUBLIC_URL}/`} className="btn btn-solid">continue</Link>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,

    searchString: state.filters.searchString,

    doctors: state.doctors,
    filters: state.filters,
    symbol: state.data.symbol,
});

export default connect(
    mapStateToProps,
    {
        addToCart,
        addToWishlist,
        addToCompare,
        getDoctors,
        filterAddCities,
        filterDCity,
        filterAddHospitalCities,
    }
)(withRouter(SearchListing));
