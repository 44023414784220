import * as types from "../../constants/ActionTypes";
import axios from "axios";
import appConfig from "../../config";

// Hospital Service
export const getHospitals = data => dispatch => {
    console.log("HOSPITAL/ACTIONS: getHospitals ", data);
    let param = getFilterHospitalParams(data);
    //console.log("HOSPITAL/ACTIONS: PARAMS ARE : ", param);
    axios
        .get(appConfig.hospitals + param)
        .then(response => {
            // Handle success.
            console.log('HOSPITAL/ACTIONS: Data Received');
            let hospitals = response.data;
            dispatch({
                type: types.RECEIVE_HOSPITALS,
                hospitals
            })
        })
}

export const getHospital = () => dispatch => {
    //console.log('ACTIONS/HOSPITAL: logout');
    //localStorage.removeItem('user');
    dispatch({
        type: types.LOGOUT
    })
}

export const getFilterHospitalParams = (filters) => {
    let speciality = filters.speciality ? filters.speciality : [];
    let city = filters.hcity ? filters.hcity : [];
    let rate = filters.rate ? filters.rate : {min: 0, max: 5};

    let str = "?Rating_lte=" + rate.max + "&Rating_gte=" + rate.min;
    if (city.length) {
        city.forEach(c => {
            str += "&City_contains=" + c;
        })
    }
    if (speciality.length) {
        speciality.forEach(s => {
            str += "&Special_in=" + s;
        })
    }
    return str;
}