import React from 'react'
import { connect } from "react-redux";
import { withRouter } from 'react-router-dom';
import SubmenuList from '../headerSubmenuList';
import { Input } from "antd";
import {

    getSuggestionsConditions, getSuggestionsDoctors, getSuggestionsHospitals, getSuggestionsSpeciality,
    getSuggestionsTreatments
} from '../../../../redux/actions'

const PreventionList = ({ suggTreatments, suggConditions, suggSpecialisations, suggHospitals, suggDoctors, treatment, handleSpeclChange, condition, doctor, hospital, specialisation }) => {
    // console.log("preventionList::condition",suggConditions);
    const getFinderData = () => {

        // if (!suggTreatments.length)
        //     getSuggestionsTreatments();
        //
        // if (!suggConditions.length)
        //     getSuggestionsConditions();
        //
        // if (!suggSpecialisations.length)
        //     getSuggestionsSpeciality();
        //
        // if (!suggHospitals.length)
        //     getSuggestionsHospitals();
        //
        // if (!suggDoctors.length)
        //     getSuggestionsDoctors();
    }
    React.useEffect(() => {
        getFinderData();

    }, [])

    return (
        <React.Fragment>
            <li className="li-list">
                <h4>
                    Gender
                </h4>
                <Input
                    style={{ border: "0" }}
                    name="Gender"
                    placeholder="Gender" />
            </li>
            <li className="li-list">
                <h4>
                    Age
                </h4>
                <Input
                    style={{ border: "0" }}
                    name="Age"
                    placeholder="Age" />
            </li>
            {/*<SubmenuList name="Current Condition" value={condition} handleSpeclChange={handleSpeclChange} optionValues={suggConditions}*/}
            {/*    speclName="Condition" />*/}
            {/*<SubmenuList name="Parental History" value={condition} handleSpeclChange={handleSpeclChange} optionValues={suggConditions}*/}
            {/*    speclName="Condition" />*/}

        </React.Fragment>


    )
}



const mapStateToProps = (state) => ({


    suggTreatments: state.data.treatments,
    suggConditions: state.data.conditions,
    suggSpecialisations: state.data.specialisations,
    suggHospitals: state.data.hospitals,
    suggDoctors: state.data.doctors,
})
export default connect(
    mapStateToProps, {

    getSuggestionsTreatments,
    getSuggestionsConditions,
    getSuggestionsSpeciality,
    getSuggestionsHospitals,
    getSuggestionsDoctors,

}
)(withRouter(PreventionList));