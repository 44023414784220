import React, { Component } from "react";
import Slider from "react-slick";
import "../commonAll/index.scss";
import "../commonAll/atag.scss";
import { connect } from "react-redux";
import { Spin } from 'antd';
import qs from "qs";

// import {Button} from 'react-bootstrap';

// import custom Components
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faCity,
    faEnvelope,
    faGlobe,
    faVideo,
    faMobile,
    faPhoneAlt,
} from "@fortawesome/free-solid-svg-icons";
import DetailsTopTabs from "./common/details-top-tabs";
import { addToCart, addToCartUnsafe, addToWishlist } from "../../redux/actions";
import renderHTML from "react-render-html";
import LiveChat from "./common/product/liveChat";

import axios from "axios";
import appConfig from "../../config";

import CommentsHosital from "./common/comments/comments-hosital";

import { Button, Collapse, Empty, Skeleton, Menu, Row, Col, Switch } from "antd";
import { Rate } from "antd";
import { MailOutlined, AppstoreOutlined, SettingOutlined, FilterOutlined } from '@ant-design/icons';
//import { Link } from 'react-router-dom';
import { Anchor } from "antd";
import { Link as ReactLink } from "react-router-dom";
import HospitalProcedure from "./common/product/hospital-procedure";
import Interweave from "interweave";
import HospitalSpecialities from "./common/product/hospital-specialitis";
import DoctorCardSmall from "../search/doctor-card-small";
import { Input, AutoComplete } from 'antd';
import { SelectProps } from 'antd/es/select';
import { cleanTags, createDocQuery, getCategoryData, getParams, getSearchParamID, getURLq, parms2Array } from "../../api/apicalls";
import _, { lte } from 'lodash';

import { Select } from 'antd';
import { Map, InfoWindow, Marker, GoogleApiWrapper } from "google-maps-react";
import MapContainer from "../commonAll/mapContainer";
import {
    RiseOutlined
} from '@ant-design/icons';
import { searchKeywords } from "../../services";
import { Helmet } from "react-helmet";
import Text from "antd/es/typography/Text";
import Accredetion from "../search/common/accredetion";
import SpclDoctors from "../search/common/spclDoctors";

const { Option } = Select;

const { Link } = Anchor;

const { Panel } = Collapse;

const { SubMenu } = Menu;

class Hospital extends Component {
    baseUrl;

    constructor() {
        super();
        this.state = {
            nav1: null,
            nav2: null,
            item: {},
            doctors: [],
            // sticky: false,
            doctClicked: "#008CBA",
            departClicked: null,
            reviewClicked: null,
            roomClicked: null,
            hospitalProcedure: "",
            top: "",
            show: false,
            options: [],
            urlQ: '',
            searchItem: [],
            meta: {
                title: "Hosplan",
                desc: ""
            },
            availableFor: {

            },
            category: null,
            loading: true
        };
    }

    listenScrollEvent = (e) => {
        // console.log(window.scrollY)
        if (window.scrollY > 100) {
            this.setState({ top: "0" });
        } else {
            this.setState({ top: "" });
        }
    };

    componentDidMount() {
        window.addEventListener("scroll", this.listenScrollEvent);
        document.getElementById("sticky").style.display = "none";
        this.setState({
            nav1: this.slider1,
            nav2: this.slider2,
        });
        const {
            match: { params },
        } = this.props;
        this.hospitalId = params.id;

        // // eslint-disable-next-line no-restricted-globals
        // let urlQ = getURLq(location.href);
        // this.setState({urlQ})

        this.getHospital(params.id);

        if (this.state.options.length === 0) {
            this.setState({
                options: [...this.props.suggSpecialisations, ...this.props.suggConditions, ...this.props.suggTreatments]
            })
        }

    }

    getURLParams = () => {
        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href);
        let prms = [];

        let qerh, qerd = '';

        // console.log("The params are : ", params)
        if (params['Specialisation']) {
            prms.push(this.getSearchItem('specialisation', params['Specialisation']))
        }
        if (params['Speciality']) {
            prms.push(this.getSearchItem('speciality', params['Speciality']))
        }

        if (params['Treatment']) {
            prms.push(this.getSearchItem('treatment', params['Treatment']))
        }
        if (params['Condition']) {
            prms.push(this.getSearchItem('condition', params['Condition']))
        }

        if (params['Hospital']) {
            prms.push(this.getSearchItem('hospital', params['Hospital']))
        }
        if (params['Doctor']) {
            prms.push(this.getSearchItem('doctor', params['Doctor']))
        }
        // console.log("SEARCH FOR ", prms);

        qerd = this.createDocQuery(prms);
        return qerd;
    }

    getSearchItem = (cat, slug) => {
        let baseItem = {
            category: cat,
            id: slug
        };
        let item = {};
        // return item;

        switch (cat) {
            case 'specialisation':
                item = _.find(this.props.suggSpecialisations, { value: slug })
                break;
            case 'speciality':
                item = _.find(this.props.suggSpecialisations, { value: slug })
                break;

            case 'treatment':
                item = _.find(this.props.suggTreatments, { value: slug })
                break;
            case 'condition':
                item = _.find(this.props.suggConditions, { value: slug })
                break;

            case 'hospital':
                item = _.find(this.props.suggHospitals, { value: slug })
                break;
            case 'doctor':
                item = _.find(this.props.suggDoctors, { value: slug })
                break;
            default:
                console.log("Match Nothing")
        }
        console.log("Item is getSearchItem ", item)
        return item ? item : baseItem;
    }

    createDocQuery = (prms) => {
        let qerd = "";
        prms.map(query => {
            if (query) {
                if (query.category === "doctor") {
                    qerd = qerd.length
                        ? qerd + "&" + "id=" + query.id
                        : "id=" + query.id;
                } else {
                    qerd = qerd.length
                        ? qerd +
                        "&" +
                        searchKeywords[query.category] +
                        "=" +
                        query.id
                        : searchKeywords[query.category] + "=" + query.id;
                }
            }
        })
        console.log("SEARCH qerd = ", qerd);
        return qerd;
    }

    getHospital(id) {
        // let param = "/" + id;
        let param = "?Alias=" + id;
        axios.get(appConfig.hospitals + param).then((response) => {
            // Handle success.
            // console.log("HOSPITAL/ITEM: Data Received", response.data);
            let hospital = response.data[0];
            // eslint-disable-next-line no-restricted-globals
            // let urlQ = getURLq(location.href);
            let urlQ = this.getURLParams();
            // let urlQ = getURLq(location.href);
            // let prm = getParams()
            // console.log("Tha paramaters are : ", createDocQuery(parms2Array(urlQ)));

            let searchItem = this.getSearchParam();

            // this.setState({searchItem: searchItem.id})

            // if(searchItem)

            // noinspection JSCheckFunctionSignatures
            urlQ = this.getDocSearchURL(searchItem, urlQ);

            console.log("Tha paramaters are : ", urlQ);
            console.log("Tha searchItem are : ", searchItem);
            console.log("Hospital :: ", hospital);

            if (hospital.Name)
                document.documentElement.scrollTop = 335
            this.setState({
                hospital,
                searchItem: searchItem.id,
                meta: {
                    title: hospital.Name ? hospital.Name + " || Hosplan" : 'Hosplan',
                    desc: hospital.Description ? hospital.Description : ''
                }
            }, this.getDoctors(hospital.id, urlQ));
        });
    }

    getDocSearchURL = (searchItem, url) => {
        let urlQ = url
        if (searchItem.speciality) {
            urlQ = "specialities=" + searchItem.speciality;
        }
        if (searchItem.category === "speciality") {
            urlQ = "specialities=" + searchItem.id;
        }
        console.log('urlq', urlQ)
        return urlQ;
    }

    getDoctors(id, urlQ) {
        let param = "?hospital=" + id + "&_limit=-1";
        this.setState({ loading: true })

        if (urlQ) {
            param += "&" + urlQ

            // if(this.state.category != null){
            //     param+= '&'+ this.state.category
            // }
        }

        console.log('prms', param)
        // console.log('cats', this.state.category)
        axios.get(appConfig.doctors + param).then((response) => {
            // Handle success.
            console.log('HOSPITAL/Doctors: Data Received', response.data);
            let doctors = response.data;
            this.setState({ loading: false })
            this.setState({ doctors });
        });
    }


    getSearchParam = () => {
        // console.log("SEARCH :: Get Search Header Parameter : ")
        // eslint-disable-next-line no-restricted-globals
        let url = location.href;
        let params = getParams(url);

        let cat, slug = "";

        // console.log("SEARCH :: Get Search Header Parameter : ", params)
        if (params['Specialisation']) {
            cat = 'specialisation';
            slug = params['Specialisation'].replaceAll('-', ' ');
            this.setState({
                topic: params['Specialisation'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Speciality']) {
            cat = 'speciality';
            slug = params['Speciality'].replaceAll('-', ' ');
            this.setState({
                topic: params['Speciality'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Treatment']) {
            cat = 'treatment';
            slug = params['Treatment'].replaceAll('-', ' ');
            this.setState({
                topic: params['Treatment'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Condition']) {
            cat = 'condition';
            slug = params['Condition'].replaceAll('-', ' ');
            this.setState({
                topic: params['Condition'].replaceAll('-', ' '),
                cat, slug
            })
        } else if (params['Hospital']) {
            cat = 'hospital';
            slug = params['Hospital'].replaceAll('-', ' ');
            this.setState({
                topic: params['Hospital'].replaceAll('-', ' '),
            })
        } else if (params['Doctor']) {
            cat = 'doctor';
            slug = params['Doctor'].replaceAll('-', ' ');
            this.setState({
                topic: params['Doctor'].replaceAll('-', ' '),
            })
        }
        if (cat !== '' && slug !== '') {
            // eslint-disable-next-line no-restricted-globals
            let searchItem = this.getSearchItem(cat, slug);
            console.log("Search item is : ", searchItem);
            if (searchItem && searchItem.category && searchItem.slug) {
                // this.setState({searchItem: searchItem.id})
                return searchItem
            } else {
                getSearchParamID(cat, slug).then(r => {
                    console.log("Docemnet data Derom : ", r)
                    this.setState({ searchItem: r.id })
                    return r
                })
            }
        } else {
            return { id: "" };
        }
    }


    getSearchItem = (cat, slug) => {
        let item = {};
        switch (cat) {
            case 'treatment':
                item = _.find(this.props.suggTreatments, { value: slug })
                break;
            case 'condition':
                item = _.find(this.props.suggConditions, { value: slug })
                break;
            case 'specialisation':
                item = _.find(this.props.suggSpecialisations, { value: slug })
                break;
            case 'speciality':
                item = _.find(this.props.suggSpecialisations, { value: slug })
                break;
            case 'hospital':
                item = _.find(this.props.suggHospitals, { value: slug })
                break;
            case 'doctor':
                item = _.find(this.props.suggDoctors, { value: slug })
                break;
            default:
                console.log("Match Nothing")
        }
        // console.log("Slug is:", item)
        return item;
    }

    imgurl = (image) => {
        let dim = '0x0';
        let prefix = 'https://api.html5.run/unsafe/455x455/smart/';
        if (this.props.dimensions) {
            console.log(this.props.dimensions)
            prefix += this.props.dimensions.width + 'x' + this.props.dimensions.height + "/smart/";
        }
        // console.log("imgContainer",document.getElementById('imgContainer').clientHeight)
        return prefix + image;
        // return image;
    }

    render() {
        const { auth } = this.props;
        var products = {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            fade: true,
        };
        var productsnav = {
            slidesToShow: 3,
            swipeToSlide: true,
            arrows: false,
            dots: false,
            focusOnSelect: true,
        };
        const settings = {
            dots: false,
            infinite: true,
            speed: 300,
            slidesToShow: 3,
            slidesToScroll: 1,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 586,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    },
                },
            ],
        };
        const mapStyles = {
            width: "100%",
            height: "100%",
            top: "0px",
        };
        const item = this.state.hospital;
        // console.log("item", item);
        const hospitalPlaceholder = `${process.env.PUBLIC_URL
            }/assets/images/building.png`;
        const doctorPlaceholder = `${process.env.PUBLIC_URL
            }/assets/images/doctors/Doctor-Male-Placeholder.jpg`;

        const { doctors, searchItem, availableFor } = this.state;
        window.hPgaeProps = this.props;
        window.hPageState = this.state;
        // console.log("HOSPITAL/ITEM-PAGE : ", item);
        // console.log("HOSPITAL/ITEM-PAGE props : ", this.props);



        return (
            <section style={{ backgroundColor: 'rgb(236, 236, 236)', paddingTop: '0px' }}>
                {/*SEO Support*/}
                <Helmet>
                    <title>{this.state.meta.title}</title>
                    <meta name="description" content={this.state.meta.desc} />
                </Helmet>
                {/*SEO Support End */}
                <div
                    className=" container doctor-body hos-main"
                    style={{ backgroundColor: "rgb(236, 236, 236)" }}
                >
                    {item ? (
                        <div>
                            {item ? (
                                <div
                                    className="price-hospital-inside-card hide-me"
                                    style={{
                                        top: this.state.top,
                                        overflow: "hidden",
                                        borderRadius: "5px",
                                        boxShadow: "0 0px 10px 2px rgba(0,0,0,0.1)",
                                    }}
                                >
                                    <LiveChat />
                                </div>
                            ) : null}
                            <section
                                id="Overview"
                                style={{
                                    paddingTop: "0",
                                    backgroundColor: "rgb(236, 236, 236)",
                                }}
                            >
                                <div
                                    className="doctor-container"
                                    style={{ backgroundColor: "#fff" }}
                                >
                                    <div className="doctor-inner-container">
                                        {/*<div style={{ width: "992px", marginLeft: "auto", marginRight: "auto", marginTop: "10px" }}>*/}
                                        <div className=" inframe">
                                            <div className="doctor-page-card">
                                                <div className="summary row">
                                                    <div
                                                        id="imgContainer"
                                                        className="col-md-4 "
                                                        style={{ marginRight: "24px" }}
                                                    >
                                                        {item.Pictures && item.Pictures.length ? (
                                                            <img
                                                                className=" img-fluid"
                                                                src={this.imgurl(item.Pictures[0].url)}
                                                                alt=" hospital"
                                                            />
                                                        ) : (
                                                            <img
                                                                className="designated-provider-image"
                                                                src={hospitalPlaceholder}
                                                                alt=" hospital"
                                                            />
                                                        )}
                                                    </div>

                                                    <div className="col-md-6 my-3 mt-md-0">
                                                        <div style={{ color: "#444548" }} className="h2">
                                                            {item.Name}
                                                        </div>
                                                        {item.Address ? (
                                                            <div className="hospital__details">
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faCity}
                                                                />

                                                                {item.Address}
                                                            </div>
                                                        ) : null}
                                                        {item.Phone ? (
                                                            <div className="hospital__details">
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faPhoneAlt}
                                                                />
                                                                {item.Phone}
                                                            </div>
                                                        ) : null}
                                                        {item.Email ? (
                                                            <div className="hospital__details">
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faEnvelope}
                                                                />
                                                                {item.Email}
                                                            </div>
                                                        ) : null}
                                                        {item.Website ? (
                                                            <div className="hospital__details">
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faGlobe}
                                                                />
                                                                {item.Website}
                                                            </div>
                                                        ) : null}
                                                        {/*<div className="Patient-satisfaction-container">*/}
                                                        {/*    <svg*/}
                                                        {/*        width="60"*/}
                                                        {/*        height="60"*/}
                                                        {/*        version="1.1"*/}
                                                        {/*        className="coin coin-low"*/}
                                                        {/*        data-qa-target="patient-rating-coin"*/}
                                                        {/*    >*/}
                                                        {/*        <g>*/}
                                                        {/*            <circle cx="30" cy="30" r="27"/>*/}
                                                        {/*            <text x="31" y="35">*/}
                                                        {/*                45%*/}
                                                        {/*            </text>*/}
                                                        {/*        </g>*/}
                                                        {/*    </svg>*/}

                                                        {/*    <p style={{lineHeight: "1.5"}}>*/}
                                                        {/*        <strong style={{fontSize: "16px"}}>*/}
                                                        {/*            Patients that would definitely&nbsp;recommend*/}
                                                        {/*        </strong>*/}
                                                        {/*        <br/>*/}
                                                        {/*        <span>25% lower than the national average</span>*/}
                                                        {/*    </p>*/}
                                                        {/*</div>*/}
                                                        <hr />
                                                        <div className="rating">
                                                            <Text style={{ fontSize: '12px' }}>
                                                                Rating on Google :
                                                            </Text>
                                                            <Rate
                                                                disabled
                                                                allowHalf
                                                                value={item.googleRating ? item.googleRating : item.Rating}
                                                                style={{ color: "#ffa201", fontSize: '12px' }}
                                                            />{" "}
                                                            <br />
                                                            <Accredetion item={item} />
                                                        </div>

                                                        <div className="doctor-about-container">
                                                            <div
                                                                style={{ color: "#444548" }}
                                                                className="about-doctor"
                                                            >
                                                                <div className='hspDescPage'>
                                                                    {/*<Interweave content={cleanTags(item.Description, "<b><i><u><p><ol><ul>")}/>*/}
                                                                    <Interweave content={cleanTags(item.ShortDetails, "<b><i><u><p><ol><ul>")} />
                                                                </div>
                                                                {/*{this.state.show*/}
                                                                {/*    ? renderHTML(item.Description)*/}
                                                                {/*    : renderHTML(*/}
                                                                {/*        item.Description.substring(0, 200) + "..."*/}
                                                                {/*    )}*/}
                                                            </div>
                                                            {/*<button*/}
                                                            {/*    className="readMore btn btn-solid p-2"*/}
                                                            {/*    // style={{ color: "#000" }}*/}
                                                            {/*    onClick={() =>*/}
                                                            {/*        this.setState({show: !this.state.show})*/}
                                                            {/*    }*/}
                                                            {/*>*/}
                                                            {/*    {this.state.show ? "read less" : "read more"}*/}
                                                            {/*</button>*/}
                                                        </div>
                                                        <HospitalSpecialities
                                                            spcl={item.specialities}
                                                            hospi={item}
                                                            clname={"tag"}
                                                            styl={{
                                                                color: '#9a9a9a',
                                                                fontSize: '10px',
                                                                textTransform: 'none',
                                                                padding: '0.1rem 0.4rem',
                                                                background: '#ececec',
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <hr
                                style={{
                                    marginLeft: "auto",
                                    marginRight: "auto",
                                }}
                            />

                            <div className="container inframe">
                                <Anchor style={{ marginLeft: "0px" }} className="width-75">
                                    <Link
                                        href={`/hospital/${this.hospitalId}#Overview`}
                                        title="Overview"
                                    />
                                    <Link
                                        href={`/hospital/${this.hospitalId}#Doctors`}
                                        title="Doctors"
                                    />
                                    <Link
                                        href={`/hospital/${this.hospitalId}#facilities`}
                                        title="Procedures"
                                    />
                                    {/*<Link*/}
                                    {/*    href={`/hospital/${this.hospitalId}#Facilities`}*/}
                                    {/*    title="Facilities"*/}
                                    {/*/>*/}
                                    <Link
                                        href={`/hospital/${this.hospitalId}#Map`}
                                        title="Location"
                                    />
                                    <Link
                                        href={`/hospital/${this.hospitalId}#Reviews`}
                                        title="Reviews"
                                    />
                                    {/*<Link*/}
                                    {/*    href={`/hospital/${this.hospitalId}#Gallery`}*/}
                                    {/*    title="Hotels"*/}
                                    {/*/>*/}
                                    {/*{item.ShortDetails ? (*/}
                                    {/*    <Link*/}
                                    {/*        href={`/hospital/${this.hospitalId}#Short`}*/}
                                    {/*        title="Details"*/}
                                    {/*    />*/}
                                    {/*) : null}*/}
                                </Anchor>

                                {/*<div style={{width: "960px", marginLeft: "auto", marginRight: "auto"}}>*/}

                                <section id="Doctors" style={{ margin: "0" }}>

                                    <section className="silky width-75">
                                        <h3>Search</h3>

                                        <div>
                                            {/*<Row justify="space-between">*/}
                                            <Row justify="start">
                                                <Col span={20}>
                                                    <div>
                                                        <Select
                                                            virtual={true}

                                                            allowClear
                                                            showSearch
                                                            showArrow={false}
                                                            placeholder="Please select"
                                                            optionFilterProp={"content"}
                                                            defaultValue={searchItem ? searchItem : []}
                                                            // dropdownMatchSelectWidth={252}
                                                            style={{ width: '100%', marginBottom: "10px" }}
                                                            className="dSearchHospital"
                                                            onClear={() => {
                                                                console.log("Clear All");
                                                                this.getDoctors(item.id)
                                                            }}
                                                            // options={this.state.options}
                                                            // onSelect={onSelect}
                                                            // onChange={(e) => {
                                                            //     this.getDoctors(item.id)
                                                            // }}
                                                            onSelect={(e, opt) => {
                                                                this.setState({loading: true})
                                                                // console.log("e is : ", opt);
                                                                let docs = [];
                                                                let param = "?hospital=" + item.id + "&_limit=-1";
                                                                axios.get(appConfig.doctors + param).then((response) => {
                                                                    // Handle success.
                                                                    // console.log('HOSPITAL/Doctors: Data Received', response.data);
                                                                    let docts = response.data;
                                                                    if (opt.category === "SuperSpeciality") {
                                                                        // Not found anything
                                                                    }
                                                                    if (opt.category === "speciality") {
                                                                        console.log("Searching for : ", opt)
                                                                        let categoryUrl = `specialities=${opt.value}`;
                                                                        this.getDoctors(item.id, categoryUrl);
                                                                        this.setState({ category: categoryUrl })

                                                                        //     docts.map(d => {
                                                                        //         let x = _.filter(d.specialities, { id: opt.value })


                                                                        //         if (x.length > 0) {
                                                                        //             docs.push(d)
                                                                        //             d.specialities.map((e) => {
                                                                        //                 let catArr = []
                                                                        //                 console.log('e', e)
                                                                        //                 catArr.push([e.name])
                                                                        //                 console.log('catra', catArr)
                                                                        //                 this.setState({ category: catArr })
                                                                        //             })
                                                                        //         } 
                                                                        //     })
                                                                        //     console.log('cat', this.state.category)
                                                                        //     this.setState({ doctors: docs })
                                                                        //     console.log('doctors', this.state.doctors)
                                                                    }
                                                                    if (opt.category === "condition") {
                                                                        console.log("Searching for : ", opt)
                                                                        let categoryUrl = `conditions=${opt.value}`;
                                                                        this.getDoctors(item.id, categoryUrl);
                                                                        this.setState({ category: categoryUrl })


                                                                        // docts.map(d => {
                                                                        //     let x = [];
                                                                        //     let spl = _.find(this.props.suggConditions, { 'value': opt.value })
                                                                        //     // console.log("the search condition is : ", spl);
                                                                        //     if (spl && spl.speciality) {
                                                                        //         x = _.filter(d.specialities, { id: spl.speciality })
                                                                        //         // console.log("the x is : ", x)
                                                                        //         if (x.length > 0) {
                                                                        //             docs.push(d)
                                                                        //         }
                                                                        //     } else {
                                                                        //         x = _.filter(d.conditions, { id: opt.value })
                                                                        //         if (x.length > 0) {
                                                                        //             docs.push(d)
                                                                        //         }
                                                                        //     }

                                                                        // })
                                                                        // this.setState({ doctors: docs })
                                                                    }
                                                                    if (opt.category === "treatment") {
                                                                        console.log("Searching for : ", opt)
                                                                        let categoryUrl = `treatments=${opt.value}`;
                                                                        this.getDoctors(item.id, categoryUrl);
                                                                        this.setState({ category: categoryUrl })

                                                                        // docts.map(d => {
                                                                        //     console.log("user search for : ", opt);
                                                                        //     let x = [];
                                                                        //     let spl = _.find(this.props.suggTreatments, { 'value': opt.value })
                                                                        //     if (spl && spl.speciality) {
                                                                        //         x = _.filter(d.specialities, { id: spl.speciality })
                                                                        //         // console.log("the x is : ", x)
                                                                        //         if (x.length > 0) {
                                                                        //             docs.push(d)
                                                                        //         }
                                                                        //     } else {
                                                                        //         x = _.filter(d.treatments, { id: opt.value })
                                                                        //         if (x.length > 0) {
                                                                        //             docs.push(d)
                                                                        //         }
                                                                        //     }
                                                                        // })
                                                                        // this.setState({ doctors: docs })
                                                                    }
                                                                });

                                                            }}
                                                            onSearch={(e) => {
                                                                console.log("e is ", e)
                                                                getSearchParamID("fordocs", e, 20).then(res => {

                                                                    // let options = _.map(r, 'label')
                                                                    // this.setState({options})
                                                                    // console.log("Docemnet data Derom : ", res)

                                                                    if (this.state.options && this.state.options.length) {
                                                                        let uniqueUsersByID = _.orderBy(_.uniqBy([...this.state.options, ...res], "id"), ['label'], ['asc']);
                                                                        this.setState({ options: [...uniqueUsersByID], loading: false });
                                                                    } else {
                                                                        let r2 = _.orderBy(res, ['label'], ['asc']);
                                                                        this.setState({ options: [...r2], loading: false });
                                                                    }
                                                                })
                                                            }}
                                                        >
                                                            {this.state.options.map((item, index) => {
                                                                // console.log("Item", item)
                                                                return (
                                                                    <Option key={index} value={item.slug} label={item.label} content={`${item.label} ${item.keywords} ${item.meta}`}
                                                                        category={item.category}>
                                                                        {/*    style={{*/}
                                                                        {/*    display: 'flex',*/}
                                                                        {/*    justifyContent: 'space-between',*/}
                                                                        {/*}}*/}
                                                                        <div>
                                                                            <span style={{ textTransform: 'capitalize' }}>{item.label}</span>
                                                                            <br />
                                                                            <span style={{ fontSize: "12px", fontWeight: "500", textTransform: 'capitalize' }}>{item.category}</span>
                                                                            {/*<br/>*/}
                                                                            {/*{item.keywords ? (*/}
                                                                            {/*    <div>*/}
                                                                            {/*        <span style={{fontSize: "12px", fontWeight: "500", textTransform:'capitalize'}}>*/}
                                                                            {/*          {item.keywords}*/}
                                                                            {/*        </span>*/}
                                                                            {/*    </div>*/}
                                                                            {/*) : null}*/}
                                                                        </div>
                                                                    </Option>
                                                                );
                                                            })}
                                                            {/*<Input.Search size="large" placeholder="Treatment, Condition, Specialisation" enterButton />*/}
                                                        </Select>
                                                    </div>
                                                </Col>
                                                <Col span={4}>
                                                    <Menu mode="horizontal"
                                                    >


                                                        <SubMenu key="filters" icon={<FilterOutlined />} title="Filter">
                                                            <Menu.ItemGroup title="Rating">
                                                                <Menu.Item key="setting:4">
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // this.rateHandle(5, "drate");
                                                                            console.log("DIV CLICKED")
                                                                            this.setState({ rateValue: 4 })
                                                                            let expUrl = `googleRating_gte=4`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if (this.state.docExp) {
                                                                                    expUrl += `&Experience_gte=${this.state.docExp[0]}&Experience_lte=${this.state.docExp[1]}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}
                                                                    >
                                                                        <Rate
                                                                            disabled
                                                                            allowHalf
                                                                            value={4}
                                                                            style={{ color: "#ffa201", fontSize: '12px' }}
                                                                        /> {" & Up "}
                                                                    </span>
                                                                </Menu.Item>
                                                                <Menu.Item key="setting:3">
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // this.rateHandle(5, "drate");
                                                                            console.log("DIV CLICKED")
                                                                            this.setState({ rateValue: 3 })
                                                                            let expUrl = `googleRating_gte=5`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if (this.state.docExp) {
                                                                                    expUrl += `&Experience_gte=${this.state.docExp[0]}&Experience_lte=${this.state.docExp[1]}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}
                                                                    >
                                                                        <Rate
                                                                            disabled
                                                                            allowHalf
                                                                            value={3}
                                                                            style={{ color: "#ffa201", fontSize: '12px' }}
                                                                        /> {" & Up "}
                                                                    </span>
                                                                </Menu.Item>
                                                                <Menu.Item key="setting:2">
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // this.rateHandle(5, "drate");
                                                                            console.log("DIV CLICKED")
                                                                            this.setState({ rateValue: 2 })
                                                                            let expUrl = `googleRating_gte=2`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if (this.state.docExp) {
                                                                                    expUrl += `&Experience_gte=${this.state.docExp[0]}&Experience_lte=${this.state.docExp[1]}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}
                                                                    >
                                                                        <Rate
                                                                            disabled
                                                                            allowHalf
                                                                            value={2}
                                                                            style={{ color: "#ffa201", fontSize: '12px' }}
                                                                        /> {" & Up "}
                                                                    </span>
                                                                </Menu.Item>
                                                                <Menu.Item key="setting:1">
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // this.rateHandle(5, "drate");
                                                                            console.log("DIV CLICKED")
                                                                            this.setState({ rateValue: 1 })
                                                                            let expUrl = `googleRating_gte=1`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if (this.state.docExp) {
                                                                                    expUrl += `&Experience_gte=${this.state.docExp[0]}&Experience_lte=${this.state.docExp[1]}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}
                                                                    >
                                                                        <Rate
                                                                            disabled
                                                                            allowHalf
                                                                            value={1}
                                                                            style={{ color: "#ffa201", fontSize: '12px' }}
                                                                        /> {" & Up "}
                                                                    </span>
                                                                </Menu.Item>

                                                                {/*<Menu.Item key="setting:2">Option 2</Menu.Item>*/}
                                                            </Menu.ItemGroup>
                                                            <Menu.ItemGroup title="Experience">
                                                                <Menu.Item key="exp:5">
                                                                    <span style={{ cursor: 'pointer', fontWeight: 400 }}
                                                                        onClick={e => {
                                                                            // this.props.handleDocExp([0, 5])
                                                                            this.setState({ docExp: [0, 5] })
                                                                            let expUrl = `Experience_gte=0&Experience_lte=5`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if(this.state.rateValue){
                                                                                    expUrl += `&googleRating_gte=${this.state.rateValue}`
                                                                                }
                                                                            }
                                                                            console.log(expUrl)
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}>Under 5 years</span>
                                                                </Menu.Item>
                                                                <Menu.Item key="exp:4">
                                                                    <span style={{ cursor: 'pointer', fontWeight: 400 }}
                                                                        onClick={e => {
                                                                            // this.props.handleDocExp([5, 10])
                                                                            this.setState({ docExp: [5, 10] })
                                                                            let expUrl = `Experience_gte=5&Experience_lte=10`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if(this.state.rateValue){
                                                                                    expUrl += `&googleRating_gte=${this.state.rateValue}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}>5 to 10 years</span>
                                                                </Menu.Item>
                                                                <Menu.Item key="exp:3">
                                                                    <span style={{ cursor: 'pointer', fontWeight: 400 }}
                                                                        onClick={e => {
                                                                            // this.props.handleDocExp([10, 15])
                                                                            this.setState({ docExp: [10, 15] })
                                                                            let expUrl = `Experience_gte=10&Experience_lte=15`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if(this.state.rateValue){
                                                                                    expUrl += `&googleRating_gte=${this.state.rateValue}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}>10 to 15 years</span>
                                                                </Menu.Item>
                                                                <Menu.Item key="exp:2">
                                                                    <span style={{ cursor: 'pointer', fontWeight: 400 }}
                                                                        onClick={e => {
                                                                            // this.props.handleDocExp([15, 20])
                                                                            this.setState({ docExp: [15, 100] })
                                                                            let expUrl = `Experience_gte=15&Experience_lte=100`;
                                                                            if (this.state.category != null) {
                                                                                expUrl += `&${this.state.category}`
                                                                                if(this.state.rateValue){
                                                                                    expUrl += `&googleRating_gte=${this.state.rateValue}`
                                                                                }
                                                                            }
                                                                            this.getDoctors(item.id, expUrl);
                                                                        }}>15 years and Above</span>
                                                                </Menu.Item>
                                                                {/*<Menu.Item key="exp:1">*/}
                                                                {/*    <span style={{cursor: 'pointer', fontWeight: 400}}*/}
                                                                {/*          onClick={e => {*/}
                                                                {/*              this.props.handleDocExp([20, 100] )*/}
                                                                {/*              this.setState({docExp: [20, 100] })*/}
                                                                {/*          }}>20 years and above</span>*/}
                                                                {/*</Menu.Item>*/}


                                                            </Menu.ItemGroup>
                                                            <Menu.ItemGroup title="Available For">
                                                                <Menu.Item key="vc">
                                                                    <Switch
                                                                        defaultChecked={availableFor.videoConference}
                                                                        onChange={(checked) => {
                                                                            this.getDoctors(item.id, `OnVideo=${checked}`);
                                                                            // this.handleAvailableChange({
                                                                            //     videoConference: checked,
                                                                            //     onCall: availableFor.onCall,
                                                                            //     inHospital: availableFor.inHospital,
                                                                            // });
                                                                        }}
                                                                    />
                                                                    <span>Video Conference</span>
                                                                </Menu.Item>
                                                                <Menu.Item key="phone">
                                                                    <Switch
                                                                        defaultChecked={availableFor.onCall}
                                                                        onChange={(checked) => {
                                                                            this.getDoctors(item.id, `OnPhone=${checked}`);
                                                                            // this.handleAvailableChange({
                                                                            //     videoConference: availableFor.videoConference,
                                                                            //     onCall: checked,
                                                                            //     inHospital: availableFor.inHospital,
                                                                            // });
                                                                        }}
                                                                    />
                                                                    <span>Phone Call</span>
                                                                </Menu.Item>
                                                                <Menu.Item key="location">
                                                                    <Switch
                                                                        defaultChecked={availableFor.inHospital}
                                                                        onChange={(checked) => {
                                                                            this.getDoctors(item.id, `OnLocation=${checked}`);
                                                                            // this.handleAvailableChange({
                                                                            //     videoConference: availableFor.videoConference,
                                                                            //     onCall: availableFor.onCall,
                                                                            //     inHospital: checked,
                                                                            // });
                                                                        }}
                                                                    />
                                                                    <span>In Hospital</span>
                                                                </Menu.Item>
                                                                <Menu.Item key="plc1" />
                                                                {/*<Menu.Item key="plc2"/>*/}
                                                            </Menu.ItemGroup>
                                                        </SubMenu>
                                                        {/*<SubMenu key="SubMenu" icon={<FilterOutlined />}>*/}

                                                        {/*</SubMenu>*/}
                                                        {/*<Menu.Item key="alipay">*/}
                                                        {/*    <a href="https://ant.design" target="_blank" rel="noopener noreferrer">*/}
                                                        {/*        Navigation Four - Link*/}
                                                        {/*    </a>*/}
                                                        {/*</Menu.Item>*/}
                                                    </Menu>
                                                </Col>
                                            </Row>
                                            {/*<Row>*/}
                                            {/*    <Col>1 of 3</Col>*/}
                                            {/*    <Col>2 of 3</Col>*/}
                                            {/*    <Col>3 of 3</Col>*/}
                                            {/*</Row>*/}
                                        </div>

                                        <div>





                                            <div className="container-fluid">

                                                {this.state.loading ? <div className="example">
                                                {/*{true? <div className="example">*/}
                                                    {/*<Spin size="large" />*/}
                                                    <img src={`https://hosplan.com/assets/images/loader-med.gif`} style={{maxWidth:'100%'}}/>
                                                </div> : <div className="row dCardContainer"
                                                    style={{ margin: "0px", height: "520px", overflowX: "hidden", overflowY: "auto", border: "1px solid #ececec" }}>
                                                    {this.state.doctors.length ? this.state.doctors.map((doctor, indx) => {
                                                        return (
                                                            <div key={indx} className="">
                                                                <DoctorCardSmall data={doctor} index={indx} />
                                                            </div>
                                                        )
                                                    }) : <div><Empty description={
                                                        <span>
                                                            No Doctors Found
                                                        </span>
                                                    } /></div>}
                                                </div>}
                                            </div>

                                        </div>



                                        {/*{this.state.doctors.length > 3 ? (*/}
                                        {/*    <section>*/}
                                        {/*        <Slider {...settings}>*/}
                                        {/*            {this.state.doctors*/}
                                        {/*                ? this.state.doctors.map((vari, index) => (*/}
                                        {/*                    <div*/}
                                        {/*                        key={index}*/}
                                        {/*                        style={{height: "100px", width: "300px"}}*/}
                                        {/*                    >*/}
                                        {/*                        <div>*/}
                                        {/*                            /!* < a href={'/doctor/' + vari.slug} style={{ *!/*/}
                                        {/*                            <ReactLink*/}
                                        {/*                                to={`${process.env.PUBLIC_URL}/doctor/${*/}
                                        {/*                                    vari.slug*/}
                                        {/*                                }`}*/}
                                        {/*                                style={{*/}
                                        {/*                                    display: "flex",*/}
                                        {/*                                    alignItems: "center",*/}
                                        {/*                                    border: "1px solid #eed7d7",*/}
                                        {/*                                    marginRight: "20px",*/}
                                        {/*                                }}*/}
                                        {/*                            >*/}
                                        {/*                                /!*{vari.Pictures && vari.Pictures.length ? (*!/*/}
                                        {/*                                <img*/}
                                        {/*                                    src={*/}
                                        {/*                                        vari.Pictures && vari.Pictures[0]*/}
                                        {/*                                            ? vari.Pictures[0].url*/}
                                        {/*                                            : doctorPlaceholder*/}
                                        {/*                                    }*/}
                                        {/*                                    style={{width: "100px", flex: "1"}}*/}
                                        {/*                                    alt="Treatment Excellence"*/}
                                        {/*                                />*/}
                                        {/*                                /!*) : null}*!/*/}

                                        {/*                                <div*/}
                                        {/*                                    style={{*/}
                                        {/*                                        display: "flex",*/}
                                        {/*                                        flexDirection: "column",*/}
                                        {/*                                        flex: "3",*/}
                                        {/*                                        marginLeft: "21px",*/}
                                        {/*                                    }}*/}
                                        {/*                                >*/}
                                        {/*                                    <div*/}
                                        {/*                                        // to={`${process.env.PUBLIC_URL}/doctor/${*/}
                                        {/*                                        //     vari.slug*/}
                                        {/*                                        // }`}*/}
                                        {/*                                        style={{color: "vilot"}}*/}
                                        {/*                                    >*/}
                                        {/*                                        {vari.Name}*/}
                                        {/*                                    </div>*/}
                                        {/*                                    <Rate*/}
                                        {/*                                        disabled*/}
                                        {/*                                        value={vari.Rating}*/}
                                        {/*                                        style={{*/}
                                        {/*                                            fontSize: 17,*/}
                                        {/*                                            color: "#ffa201",*/}
                                        {/*                                        }}*/}
                                        {/*                                    />*/}
                                        {/*                                </div>*/}
                                        {/*                            </ReactLink>*/}
                                        {/*                        </div>*/}
                                        {/*                    </div>*/}
                                        {/*                ))*/}
                                        {/*                : null}*/}
                                        {/*        </Slider>*/}
                                        {/*    </section>*/}
                                        {/*) : (*/}
                                        {/*    <section*/}
                                        {/*        style={{*/}
                                        {/*            display: "flex",*/}
                                        {/*            alignItems: "center",*/}
                                        {/*            justifyContent: "space-between",*/}
                                        {/*            height: "115px",*/}
                                        {/*        }}*/}
                                        {/*    >*/}
                                        {/*        {this.state.doctors.map((vari, index) => (*/}
                                        {/*            <div*/}
                                        {/*                key={index}*/}
                                        {/*                style={{*/}
                                        {/*                    display: "flex",*/}
                                        {/*                    flex: "1",*/}
                                        {/*                    flexDirection: "column",*/}
                                        {/*                    height: "100%",*/}
                                        {/*                    width: "380px",*/}
                                        {/*                }}*/}
                                        {/*            >*/}
                                        {/*                <ReactLink*/}
                                        {/*                    to={`${process.env.PUBLIC_URL}/doctor/${*/}
                                        {/*                        vari.slug*/}
                                        {/*                    }`}*/}
                                        {/*                    style={{*/}
                                        {/*                        display: "flex",*/}
                                        {/*                        alignItems: "center",*/}
                                        {/*                        border: "1px solid #eed7d7",*/}
                                        {/*                        marginRight: "20px",*/}
                                        {/*                    }}*/}
                                        {/*                >*/}
                                        {/*                    <img*/}
                                        {/*                        src={*/}
                                        {/*                            vari.Pictures[0]*/}
                                        {/*                                ? vari.Pictures[0].url*/}
                                        {/*                                : doctorPlaceholder*/}
                                        {/*                        }*/}
                                        {/*                        style={{width: "100px"}}*/}
                                        {/*                        alt="Treatment Excellence"*/}
                                        {/*                    />*/}
                                        {/*                    <div*/}
                                        {/*                        style={{*/}
                                        {/*                            display: "flex",*/}
                                        {/*                            flexDirection: "column",*/}
                                        {/*                            flex: "3",*/}
                                        {/*                            marginLeft: "21px",*/}
                                        {/*                        }}*/}
                                        {/*                    >*/}
                                        {/*                        <div*/}
                                        {/*                            // to={`${process.env.PUBLIC_URL}/doctor/${*/}
                                        {/*                            //     vari.slug*/}
                                        {/*                            // }`}*/}
                                        {/*                            style={{color: "vilot"}}*/}
                                        {/*                        >*/}
                                        {/*                            {vari.Name}*/}
                                        {/*                        </div>*/}
                                        {/*                        <Rate*/}
                                        {/*                            disabled*/}
                                        {/*                            value={vari.Rating}*/}
                                        {/*                            style={{fontSize: 17, color: "#ffa201"}}*/}
                                        {/*                        />*/}
                                        {/*                    </div>*/}
                                        {/*                </ReactLink>*/}
                                        {/*            </div>*/}
                                        {/*        ))}*/}
                                        {/*    </section>*/}
                                        {/*)}*/}
                                    </section>
                                </section>
                                <section id="Short" className="my-3">
                                    <div className="width-75 my-3 py-4">
                                        {/*<h3>Details</h3>*/}
                                        {/*<div style={{lineHeight: "1.5"}} className="width-75">*/}
                                        {/*    {renderHTML(item.ShortDetails)}*/}
                                        {/*</div>*/}
                                        <div className='hspDescPage'>
                                            <Interweave content={cleanTags(item.Description, "<b><i><u><p><ol><ul>")} />
                                        </div>

                                    </div>

                                    <div>


                                        {/*<div className="container-fluid">*/}
                                        {/*    <div className=""*/}
                                        {/*         style={{*/}
                                        {/*             margin: "0px", height: "175px", overflowX: "scroll", overflowY: "hidden", border: "1px solid #ececec", display: 'flex',*/}
                                        {/*             flexDirection: 'row',*/}
                                        {/*             padding: "10px",*/}
                                        {/*             backgroundColor: '#ececec',*/}
                                        {/*             boxShadow: 'inset 0 0 10px #00000026'*/}
                                        {/*         }}>*/}
                                        {/*        {item.doctors.length ? item.doctors.map((doctor, indx) => {*/}
                                        {/*            return (*/}
                                        {/*                <div key={indx} className="">*/}
                                        {/*                    <DoctorCardSmall data={doctor} index={indx}/>*/}
                                        {/*                </div>*/}
                                        {/*            )*/}
                                        {/*        }) : <div><Empty description={*/}
                                        {/*            <span>*/}
                                        {/*                No Doctors Found*/}
                                        {/*            </span>*/}
                                        {/*        }/></div>}*/}
                                        {/*    </div>*/}
                                        {/*</div>*/}

                                    </div>

                                </section>

                                <section id="facilities" className="my-3">
                                    {item.CardioDescription ? (
                                        <div
                                            style={{ borderTop: "1px solid rgb(222, 226, 230)" }}
                                            className="width-75 my-3 py-4"
                                        >
                                            <h3>Cardiology :</h3>
                                            {/*<div>*/}
                                            {/*    {cleanTags(item.CardioDescription, "<b><i><u><p><ol><ul>")}*/}
                                            {/*</div>*/}
                                            <div className='hspDescPage'>
                                                <Interweave content={cleanTags(item.CardioDescription, "<b><i><u><p><ol><ul>")} />
                                            </div>

                                            <SpclDoctors item={item}
                                                spcl={['608ee1000c66ab2b0b58c435', '6096d0120c66ab2b0b58cb75', '609abe9704e6d869dda20d24', '609abe0504e6d869dda20d20']} />
                                        </div>
                                    ) : null}
                                    {item.OrthoDescription ? (
                                        <div
                                            style={{ borderTop: "1px solid rgb(222, 226, 230)" }}
                                            className="width-75 my-3 py-4"
                                        >
                                            <h3>Orthopedics :</h3>
                                            <div className='hspDescPage'>
                                                <Interweave content={cleanTags(item.OrthoDescription, "<b><i><u><p><ol><ul>")} />
                                            </div>
                                            <SpclDoctors item={item} spcl={['608ab1ff7c4eeb0936f7a65a', '608bb3380c66ab2b0b58c315']} />
                                        </div>
                                    ) : null}
                                    {item.OncoDescription ? (
                                        <div
                                            style={{ borderTop: "1px solid rgb(222, 226, 230)" }}
                                            className="width-75 my-3 py-4"
                                        >
                                            <h3>Oncology :</h3>
                                            <div className='hspDescPage'>
                                                <Interweave content={cleanTags(item.OncoDescription)} />
                                            </div>
                                            <SpclDoctors item={item} spcl={['609cbe2904e6d869dda21090',
                                                '609e263404e6d869dda21176', '608d027d0c66ab2b0b58c3b5',
                                                '60c717cb2912ad448b6b8178', '60c4864d2912ad448b6b8168',
                                                '609abdc404e6d869dda20d1f', '609abdac04e6d869dda20d1e',
                                                '609b5a7504e6d869dda20d44']} />
                                        </div>
                                    ) : null}{" "}
                                    {item.TransplatDescription ? (
                                        <div
                                            style={{ borderTop: "1px solid rgb(222, 226, 230)" }}
                                            className="width-75 my-3 py-4"
                                        >
                                            <h3>Transplant :</h3>
                                            <div className='hspDescPage'>
                                                <Interweave content={cleanTags(item.TransplatDescription)} />
                                            </div>
                                            <SpclDoctors item={item}
                                                spcl={['609ac0cb04e6d869dda20d28', '609ac09a04e6d869dda20d26', '609bc60104e6d869dda2101b', '609ac0b304e6d869dda20d27']} />
                                        </div>
                                    ) : null}
                                    {item.NeuroDescription ? (
                                        <div
                                            style={{ borderTop: "1px solid rgb(222, 226, 230)" }}
                                            className="width-75 my-3 py-4"
                                        >
                                            <h3>Neurology :</h3>
                                            <div className='hspDescPage'>
                                                <Interweave content={cleanTags(item.NeuroDescription)} />
                                            </div>
                                            <SpclDoctors item={item}
                                                spcl={['5fa8f1323eb89c7b98a54e77', '609abf3204e6d869dda20d25', '60c480a32912ad448b6b815c', '60cc6323e49ea47f55ffa986']} />
                                        </div>
                                    ) : null}
                                </section>

                                <section id="Procedures">
                                    {/*<h3>Procedures</h3>*/}
                                    {/*<p style={{lineHeight: "1.5"}} className="width-75">*/}
                                    {/*    {item.Name} performs different procedures. Click on a*/}
                                    {/*    procedure name to see more details and to view doctors for*/}
                                    {/*    that specialty.*/}
                                    {/*</p>*/}
                                    {/*<HospitalSpecialities*/}
                                    {/*    spcl={item.specialities}*/}
                                    {/*    hospi={item}*/}
                                    {/*    clname={"tag"}*/}
                                    {/*    styl={{*/}
                                    {/*        color: '#9a9a9a',*/}
                                    {/*        fontSize: '10px',*/}
                                    {/*        textTransform: 'none',*/}
                                    {/*        padding: '0.1rem 0.4rem',*/}
                                    {/*        background: '#ececec',*/}
                                    {/*    }}*/}
                                    {/*/>*/}

                                    {/*<ul>*/}
                                    {/*    /!*{item.specialities.length?:null}*!/*/}
                                    {/*    */}
                                    {/*    <li*/}
                                    {/*        className="btn"*/}
                                    {/*        style={{*/}
                                    {/*            border: "1px solid #b49494",*/}
                                    {/*            margin: "0 20px 20px 0",*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <ReactLink*/}
                                    {/*            style={{color: "#666"}}*/}
                                    {/*            onClick={() => {*/}
                                    {/*                this.setState({*/}
                                    {/*                    hospitalProcedure: {*/}
                                    {/*                        Name: "Cardiology",*/}
                                    {/*                        Data: [*/}
                                    {/*                            {*/}
                                    {/*                                name: "dertlogist",*/}
                                    {/*                                url: "http://goodsdsds.com",*/}
                                    {/*                            },*/}
                                    {/*                            {name: "tolostid", url: "http://ssssr.com"},*/}
                                    {/*                        ],*/}
                                    {/*                    },*/}
                                    {/*                });*/}
                                    {/*            }}*/}
                                    {/*            to={`${process.env.PUBLIC_URL}/hospital/${*/}
                                    {/*                this.hospitalId*/}
                                    {/*            }#hospital-procedure`}*/}
                                    {/*        >*/}
                                    {/*            Cardiology*/}
                                    {/*        </ReactLink>*/}
                                    {/*    </li>*/}

                                    {/*    <li*/}
                                    {/*        className="btn"*/}
                                    {/*        style={{*/}
                                    {/*            border: "1px solid #b49494",*/}
                                    {/*            margin: "0 20px 20px 0",*/}
                                    {/*        }}*/}
                                    {/*    >*/}
                                    {/*        <ReactLink*/}
                                    {/*            style={{color: "#666"}}*/}
                                    {/*            onClick={() => {*/}
                                    {/*                this.setState({*/}
                                    {/*                    hospitalProcedure: {*/}
                                    {/*                        Name: "Neurology",*/}
                                    {/*                        Data: [*/}
                                    {/*                            {*/}
                                    {/*                                name: "nuroscience",*/}
                                    {/*                                url: "http://goodsdsds.com",*/}
                                    {/*                            },*/}
                                    {/*                            {name: "htukk", url: "http://ssssr.com"},*/}
                                    {/*                        ],*/}
                                    {/*                    },*/}
                                    {/*                });*/}
                                    {/*            }}*/}
                                    {/*            to={`${process.env.PUBLIC_URL}/hospital/${*/}
                                    {/*                this.hospitalId*/}
                                    {/*            }#hospital-procedure`}*/}
                                    {/*        >*/}
                                    {/*            Neurology*/}
                                    {/*        </ReactLink>*/}
                                    {/*    </li>*/}
                                    {/*</ul>*/}
                                    {/*<section id="hospital-procedure">*/}
                                    {/*    <HospitalProcedure data={this.state.hospitalProcedure}/>*/}
                                    {/*</section>*/}
                                </section>
                                {/*<section id="Facilities">*/}
                                {/*    <h3>Our Facilities:</h3>*/}
                                {/*    <DetailsTopTabs item={item} docs={this.state.doctors}/>*/}
                                {/*</section>*/}
                                <section id="Map">
                                    <h3>Location: <Button
                                        type="primary"
                                        icon={<RiseOutlined />}
                                        type="dashed"
                                        onClick={() => {
                                            let lnk = `https://www.google.com/maps/dir/?api=1&destination_place_id=${item.place_id}&destination=${item.place_id}`
                                            window.open(lnk, "_blank")
                                        }}
                                    >Get Directions</Button></h3>
                                    {/*<span>*/}
                                    {/*  https://www.google.com/maps/dir/?api=1&destination_place_id=ChIJpU32JP_jDDkRUPH72kckNRI&destination=ChIJpU32JP_jDDkRUPH72kckNRI  */}

                                    {/*</span>*/}
                                    {/*<DetailsTopTabs item={item} docs={this.state.doctors}/>*/}
                                    {/*<div className="mt-4 text-center">*/}
                                    <div className="width-75">
                                        <MapContainer item={item} />
                                    </div>

                                    {/*<div*/}
                                    {/*    style={{ position: "relative", paddingBottom: "56.25%" }}*/}
                                    {/*>*/}
                                    {/*    {item.Geo ? (*/}
                                    {/*        <Map*/}
                                    {/*            google={this.props.google}*/}
                                    {/*            zoom={14}*/}
                                    {/*            style={mapStyles}*/}
                                    {/*            initialCenter={{*/}
                                    {/*                lat: item.Geo.Latitude,*/}
                                    {/*                lng: item.Geo.Longitude,*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <Marker name={item.name} />*/}
                                    {/*        </Map>*/}
                                    {/*    ) : null}*/}
                                    {/*</div>*/}
                                    {/*</div>*/}
                                </section>
                                <section id="Reviews">
                                    <CommentsHosital item={item} auth={auth} type={"hospital"} />
                                </section>
                                <section id="Gallery" />

                                {/*<section style={{height: "800px"}}>*/}
                                {/*    <h3>Hotels Nearby</h3>*/}

                                {/*    <ins*/}
                                {/*        className="bookingaff"*/}
                                {/*        data-aid="2101544"*/}
                                {/*        data-target_aid="2101544"*/}
                                {/*        data-prod="map"*/}
                                {/*        data-width="100%"*/}
                                {/*        data-height="590"*/}
                                {/*        data-lang="ualng"*/}
                                {/*        data-dest_id="0"*/}
                                {/*        data-dest_type="landmark"*/}
                                {/*        data-latitude="28.5406104"*/}
                                {/*        data-longitude="77.2830214"*/}
                                {/*        data-landmark_name="Apollo Hospital"*/}
                                {/*        data-mwhsb="0"*/}
                                {/*        data-address="Indraprastha Apollo Hospital, Mathura Road, Sarita Vihar, New Delhi, Delhi, India"*/}
                                {/*    >*/}
                                {/*        <a href="//www.booking.com?aid=2101544">Booking.com</a>*/}
                                {/*    </ins>*/}
                                {/*    {(function (d, sc, u) {*/}
                                {/*        var s = d.createElement(sc),*/}
                                {/*            p = d.getElementsByTagName(sc)[0];*/}
                                {/*        s.type = "text/javascript";*/}
                                {/*        s.async = true;*/}
                                {/*        s.src = u + "?v=" + +new Date();*/}
                                {/*        p.parentNode.insertBefore(s, p);*/}
                                {/*    })(*/}
                                {/*        document,*/}
                                {/*        "script",*/}
                                {/*        "//aff.bstatic.com/static/affiliate_base/js/flexiproduct.js"*/}
                                {/*    )}*/}
                                {/*    /!*<script type="text/javascript">*!/*/}
                                {/*    /!*    *!/*/}
                                {/*    /!*</script>*!/*/}
                                {/*</section>*/}
                            </div>
                        </div>
                    ) : (
                        <Skeleton active avatar paragraph={{ rows: 12 }} />
                    )}
                </div>
                <div
                    className="action-menu"
                    style={{
                        width: "95%",
                        /* align-items: center; */
                        /* position: relative; */
                        /* width: 750px; */
                        // margin: '10px auto 10px auto',
                        // margin:'10px',
                        // position:'fixed',
                        // bottom:'10px',
                        boxShadow: "0px 4px 8px 4px rgb(0 0 0 / 15%)",
                        borderRadius: "10px",
                        backgroundColor: "white",
                        paddingRight: "20px",
                        textAlign: "center",
                        paddingLeft: "30px",

                        marginTop: "10px",
                        marginBottom: "5px",
                        marginRight: "2.5%",
                        marginLeft: "2.5%",
                    }}
                >
                    <ul
                        className="ul"
                        style={{
                            display: "flex",
                            justifyContent: "space-around",
                            width: "100%",
                            flexWrap: "wrap",
                        }}
                    >
                        {/*<div>Live Chat</div>*/}
                        {/*<div>Get Opinion</div>*/}
                        {/*<div>Teleconsult</div>*/}
                        <div>
                            <li
                                className="li"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                            >
                                <img
                                    src="/assets/images/icons/askADoctor.png"
                                    style={{ width: "50px", height: "50px" }}
                                />
                                <p style={{ color: "rgb(0, 0, 0)", fontSize: "10px" }}>
                                    Live Chat
                                </p>
                            </li>
                        </div>
                        <div>
                            <li
                                className="li"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                            >
                                <img
                                    src="/assets/images/icons/Diagnostics.png"
                                    style={{ width: "50px", height: "50px" }}
                                />
                                <p style={{ color: "rgb(0, 0, 0)", fontSize: "10px" }}>
                                    Get Opinion
                                </p>
                            </li>
                        </div>
                        <div>
                            <li
                                className="li"
                                style={{ borderBottom: "none", cursor: "pointer" }}
                            >
                                <img
                                    src="/assets/images/icons/onCall.png"
                                    style={{ width: "50px", height: "50px" }}
                                />
                                <p style={{ color: "rgb(0, 0, 0)", fontSize: "10px" }}>
                                    Teleconsult
                                </p>
                            </li>
                        </div>
                    </ul>
                </div>
            </section>
        );
    }
}

const
    mapStateToProps = (state, ownProps) => {
        let productId = ownProps.match.params.id;
        return {
            item: state.data.products.find((el) => el.id == productId),
            symbol: state.data.symbol,
            auth: state.auth,

            suggTreatments: state.data.treatments,
            suggConditions: state.data.conditions,
            suggSpecialisations: state.data.specialisations,
            suggHospitals: state.data.hospitals,
            suggDoctors: state.data.doctors,

        };
    };

export default connect(
    mapStateToProps,
    { addToCart, addToCartUnsafe, addToWishlist }
)(Hospital);