import React from "react";
import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import { getHospitals } from "../../api/apicalls";
import { Product5, Slider4, Slider6, SliderHome1 } from "../../services/script";
import Slider from "react-slick";

const NewCard = () => {
  const [hospitals, setHospitals] = React.useState([]);
  React.useEffect(() => {
    getHospitals("?_sort=Rating:DESC").then((res) => {
      console.log(res);
      setHospitals(res);
    });
  }, []);
  return (
    <Row>
      <Col span={24} className="py-4">
        <Slider
          style={{ width: "100%" }}
          {...Slider4}
          className="top_hospitals no-arrow"
        >
          {hospitals.map((item) => (
            <div style={{ margin: "20px 10px" }} key={item.id}>
              <div className="d-flex card w-100 h-100 shadow ">
                <div
                  className=" d-flex flex-row w-100 p-4 "
                  style={{ height: "150px" }}
                >
                  {/* <figure
                    style={{
                      width: "200px",
                      height: "200px",
                      marginTop: "-33px",
                    }}
                  > */}
                  <img
                    style={{
                      width: "100px",
                      height: "100px",
                      marginTop: "-33px",
                      borderRadius: "5px",
                    }}
                    src={
                      (item.Pictures.length && item.Pictures[0].url) ||
                      "/assets/images/2.jpg"
                    }
                    alt="useroa,"
                  />
                  {/* </figure> */}
                  <div className="p-2">
                    <h4 className="mb-1" style={{ fontWeight: "900" }}>
                      {item.Name}
                    </h4>
                    <span
                      className="d-block my-3"
                      style={{
                        height: "2px",
                        width: "50px",
                        background: "red",
                      }}
                    ></span>
                    <p>{item.ShortDetails.substr(0, 50) + "..."}</p>
                  </div>
                </div>
                <div
                  className="d-flex pt-1 px-4 justify-content-between align-items-center"
                  style={{ height: "70px" }}
                >
                  <p className="">T&C</p>
                  <Link
                    className="text-primary font-wieght-bold"
                    style={{ fontSize: "18px" }}
                    to={`/hospital/${item.Alias}`}
                  >
                    View Details
                  </Link>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </Col>
    </Row>
  );
};

export default NewCard;
