import React, {Component} from 'react';
import {Helmet} from 'react-helmet'
import {connect} from 'react-redux'
import {Link, Redirect} from 'react-router-dom'
import PaypalExpressBtn from 'react-paypal-express-checkout';
import SimpleReactValidator from 'simple-react-validator';

import Breadcrumb from "../commonAll/breadcrumb";
import {removeFromWishlist, removeFromCart} from '../../redux/actions'
import {getCartTotal, getUser} from "../../services";
import axios from "axios";
import appConfig from "../../config";
import {login, setUser} from "../../redux/actions/authActions";

// import {StripeProvider, Elements} from 'react-stripe-elements';
// import StripeCheckout from 'react-stripe-checkout';
// import {loadStripe} from '@stripe/stripe-js';

// import {CardElement, Elements} from '@stripe/react-stripe-js';
// import {loadStripe} from '@stripe/stripe-js';
// import CheckoutForm from "./CheckoutForm";
import {Modal} from "antd";
import {getParams} from "../../api/apicalls";

// const stripePromise = loadStripe('pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD');
const bkash = `/assets/images/bkash.png`;

class checkOut extends Component {

    constructor(props) {
        super(props)

        this.state = {
            user: {
                email: '',
                password: ''
            },

            reguser: {
                uname: '',
                phone: '',
                email: '',
                password: ''
            },

            // payment: 'stripe',
            payment: 'bkash',
            first_name: '',
            last_name: '',
            phone: '',
            email: '',
            country: '',
            address: '',
            city: '',
            state: '',
            pincode: '',
            create_account: '',

            bookingEntry: {},

            visible: false,
            confirmLoading: false,
            paymentID: "",
            paymentStatus: "redirecting",
            modelTitle: '',
            paymentErrorMessage: '',
        }
        this.validator = new SimpleReactValidator();
        this.handleChange = this.handleChange.bind(this);
        this.handleRegChange = this.handleRegChange.bind(this);
    }

    componentDidMount() {
        let user = getUser();
        // console.log("ceckout page loaded")
        // setTimeout(
        //     window.scrollTo(0, 0),
        //     300
        // )
        setTimeout( () => {
            window.scrollTo( { top: 0, behavior: 'smooth' } );
        },200);

        if (user) {
            this.setState({
                user,
                first_name: user.user.name,
                phone: user.user.phone,
                email: user.user.email,
                country: '',
                address: '',
                city: '',
                state: '',
                pincode: '',
            })
        }
        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href);

        console.log("Params", params)
        if (params.paymentID && params.status && params.status === "failure") {
            this.setState({
                paymentID: params.paymentID,
                paymentStatus: params.status,
                // paymentStatus: "cancel"
                modelTitle: 'Payment Failed',
                visible: true
            })
        } else if (params.paymentID && params.status && params.status === "cancel") {
            this.setState({
                paymentID: params.paymentID,
                paymentStatus: params.status,
                modelTitle: 'Payment Not Complete',
                visible: true
            })
        } else if (params.paymentID && params.status && params.status === "success") {
            this.setState({
                paymentID: params.paymentID,
                paymentStatus: params.status,
                modelTitle: 'Payment In Process',
                visible: true,
            }, this.executeBK)
        } else if (params.paymentID && params.status && params.status === "duplicate") {
            this.setState({
                paymentID: params.paymentID,
                paymentStatus: params.status,
                visible: true,
                modelTitle: 'Payment Failed',
            }, this.executeBK)
        }


    }

    executeBK() {
        // eslint-disable-next-line no-restricted-globals
        let params = getParams(location.href);

        let payid = params.paymentID;
        let user = this.props.user;

        axios
            .post(appConfig.executeBK, {bkaPaymentID: payid}, {
                headers: {
                    Authorization: 'Bearer ' + user.user.jwt
                }
            })
            .then(response => {
                // Handle success.
                console.log('Well done!');
                console.log('execution of api ', response.data);
                if (response.data.statusCode === '0000') {
                    this.props.history.push({
                        pathname: '/order-success',
                        state: {
                            payment: response.data,
                            items: this.props.cartItems,
                            orderTotal: this.props.total,
                            symbol: this.props.symbol
                        }
                    })
                }
                if (response.data.statusCode === '2029' || response.data.statusCode === '2062') {
                    this.setState({
                        paymentID: params.paymentID,
                        paymentStatus: 'duplicate',
                        visible: true,
                        modelTitle: 'Payment Failed',
                    })
                }else{
                    this.setState({
                        paymentID: params.paymentID,
                        paymentStatus: 'duplicate',
                        visible: true,
                        modelTitle: 'Payment Failed',
                        paymentErrorMessage: response.data.statusMessage?response.data.statusMessage:''
                    })
                }

            })
            .catch((error, resp) => {
                // Handle error.
                console.log('An error occurred:', error, resp);
            });

    }


    handleChange(event) {
        //console.log('handleChange', this);
        const {name, value} = event.target;
        console.log(name, value, event.target.value);
        console.log('handleChange', this);
        const {user} = this.state;
        this.setState({
            user: {
                ...user,
                [name]: value
            }
        });
    }

    handleRegChange(event) {
        //console.log('handleChange', this);
        const {name, value} = event.target;
        console.log(name, value, event.target.value);
        console.log('handleChange', this);
        const {reguser} = this.state;
        this.setState({
            reguser: {
                ...reguser,
                [name]: value
            }
        });
    }

    userRegister = () => {
        console.log("ole ole ole", this.state.reguser);
        this.setState({submitted: true});
        const {reguser} = this.state;
        if (reguser.uname && reguser.phone && reguser.email && reguser.password) {
            //this.props.register(user);
            console.log("i can register");
            axios
                .post(appConfig.register, {
                    username: reguser.email,
                    email: reguser.email,
                    password: reguser.password,
                    name: reguser.uname,
                    phone: reguser.phone,
                    type: 'user',
                })
                .then(response => {
                    // Handle success.
                    console.log('Well done!');
                    console.log('User', response.data);
                    console.log('User profile', response.data.user);
                    console.log('User token', response.data.jwt);
                    localStorage.setItem('user', JSON.stringify(response.data));
                    //window.open(`${process.env.PUBLIC_URL}/`, '_self');
                    this.props.setUser(response.data);
                })
                .catch((error, resp) => {
                    // Handle error.
                    console.log('An error occurred:', error, resp);
                });
        }
    }

    printDateTime = (dt) => {
        let x = new Date(dt);
        let dtStr = x.toDateString() + " , " + x.toLocaleTimeString();
        return dtStr;
    }

    setStateFromInput = (event) => {
        var obj = {};
        obj[event.target.name] = event.target.value;
        this.setState(obj);
    }

    checkhandle(value) {
        this.setState({
            payment: value
        })
    }


    newCheckout() {
        this.createOrder(this.props.user, "xxx", this.props.cartItems, this.props.total);
        //this.showModal();
    }

    newCheckoutBKash() {
        if(this.props.total){
            this.showModal();
            this.createOrder(this.props.user, "xxx", this.props.cartItems, this.props.total);
        }else{
            this.createOrder(this.props.user, "xxx", this.props.cartItems, this.props.total);
        }
    }


    createOrder = (user, token, cart, total) => {
        console.log("user ", user);
        // console.log("payment info ", token);
        // console.log("cart items ", cart, total);
        let booking = {
            // "PaymentType": token.type,
            // "clientip": token.client_ip,
            // "CardBrand": token.card.brand,
            // "CardLast": token.card.last4,
            // "ordertime": new Date(token.created * 1000),
            // "TotalAmount": total.toString(),
            "FeeStatus": "Initiated",
            "ClientPhone": user.user.user.phone,
            "ClientEmail": user.user.user.email,
            "user": user.user.user.id,
            "NoBookings": cart.length,
            "Bookings": [],
            "doctor": cart[0].id,
            "Status": "New",
            "doctorSlug": cart[0].slug,
            "callback": appConfig.checkoutCallback,
        };

        cart.forEach(item => {
            let book = {
                "DrID": item.id,
                "DrName": item.Name,
                "DrDesignation": item.Designation,
                "DrPhone": item.Phone,
                "DrCity": item.City,
                "DrImage": (item.Pictures && item.Pictures[0].url) ? item.Pictures[0].url : '/assets/images/doctors/Doctor-Male-Placeholder.jpg',
                "BookingStatus": "New",
                "BookingType": item.bType,
                "BookingTime": item.bTime,
                "BookingURL": this.creatRoomUrl(item.Name, user.user.user.name, item.bTime)
                //"BookingURL": "https://conf.html5.run/pages/r.html?room=demo&p=eyJsc1JlcFVybCI6Imh0dHBzOi8vY29uZi5odG1sNS5ydW4vIiwibmFtZXMiOiJWYXJ1biIsInZpc2l0b3JOYW1lIjoiS2FwaWwiLCJkYXRldGltZSI6IjIwMjAtMDQtMjBUMDY6MzA6MDAuMDAwWiJ9"
            }
            booking.Bookings.push(book);
        })

        let url = appConfig.bookingOrderCreate;
        console.log("data is ", booking, url);


        // let url = "http://localhost:1337/booking-orders/pay/";

        axios
            .post(url, booking, {
                headers: {
                    Authorization: 'Bearer ' + user.user.jwt
                }
            })
            .then(response => {
                // Handle success.
                console.log('Well done!');
                console.log('Booking Entry', response.data);
                // console.log('Token', response.data.clientsecret);
                this.setState({bookingEntry: response.data}, () => {
                    console.log("OREDR is : ", response.data)
                    if(response.data.bkashURL){
                        this.showModal();
                        window.open(`${response.data.bkashURL}`, "_self");
                    }else if(this.props.total === 0){
                        this.props.history.push({
                            pathname: '/order-success',
                            state: {
                                payment: response.data,
                                items: this.props.cartItems,
                                orderTotal: this.props.total,
                                symbol: this.props.symbol
                            }
                        })
                    }
                });
                //this.showModal();
            })
            .catch((error, res) => {
                // Handle error.
                console.log('An error occurred:', error, error.message, JSON.stringify(error));
                console.log("responce ", res);
                //this.setState({invalid:true})
            });
    }

    creatRoomUrl = (drName, visName, dt) => {
        // https://conf.html5.run/pages/r.html?room=demo&p=eyJsc1JlcFVybCI6Imh0dHBzOi8vY29uZi5odG1sNS5ydW4vIiwibmFtZXMiOiJWYXJ1biIsInZpc2l0b3JOYW1lIjoiS2FwaWwiLCJkYXRldGltZSI6IjIwMjAtMDQtMjBUMDY6MzA6MDAuMDAwWiJ9
        let data = {
            lsRepUrl: "https://conf.html5.run/",
            names: drName,
            visitorName: visName,
            datetime: dt
        }
        console.log("Checkout url data ", data);
        let param = btoa(JSON.stringify(data));
        let room = "testroom";
        let url = data.lsRepUrl + "pages/r.html?room=" + room + "&p=" + param;
        return url;
    }

    showModal = () => {
        this.setState({
            visible: true,
            paymentStatus: "redirecting",
            modelTitle: ''
        });
    };

    checkoutFormOK = () => {
        // this.child.handleSubmit();
        this.newCheckout()
    }

    handleOk = (token) => {


        this.setState({
            // ModalText: 'The modal will be closed after two seconds',
            confirmLoading: true,
        });
        this.setState({
            visible: false,
            confirmLoading: false,
        });

        this.props.history.push({
            pathname: '/order-success',
            state: {
                payment: token,
                items: this.props.cartItems,
                orderTotal: this.props.total,
                symbol: this.props.symbol
            }
        })
        // setTimeout(() => {
        //     this.setState({
        //         visible: false,
        //         confirmLoading: false,
        //     });
        // }, 2000);
    };

    handleCancel = () => {
        console.log('Clicked cancel button');
        this.setState({
            visible: false,
        });
    };


    render() {
        const {cartItems, symbol, total, user} = this.props;
        //console.log("CECKOUT User : ", this.state.user);

        window.chkprops = this.props;
        window.chkstate = this.state;

        console.log("checkout page loaded")
        // window.scrollTo(0, 0)

        // Paypal Integration
        const onSuccess = (payment) => {
            console.log("The payment was succeeded!", payment);
            this.props.history.push({
                pathname: '/order-success',
                state: {payment: payment, items: cartItems, orderTotal: total, symbol: symbol}
            })

        }

        const onCancel = (data) => {
            console.log('The payment was cancelled!', data);
        }

        const onError = (err) => {
            console.log("Error!", err);
        }

        const client = {
            sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            // sandbox: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
            // production: 'AZ4S98zFa01vym7NVeo_qthZyOnBhtNvQDsjhaZSMH-2_Y9IAJFbSD3HPueErYqN8Sa8WYRbjP7wWtd_',
        }

        //console.log("Page activated");
        //console.log("user is ", this.state.user);
        console.log("Cart Item is ", cartItems);


        return (

            <div>

                {/*SEO Support*/}
                <Helmet>
                    <title>Treatment Excellence | CheckOut Page</title>
                    <meta name="description"
                          content="Multikart – Multipurpose eCommerce React Template is a multi-use React template. It is designed to go well with multi-purpose websites. Multikart Bootstrap 4 Template will help you run multiple businesses."/>
                </Helmet>
                {/*SEO Support End */}

                <Breadcrumb title={'Checkout'}/>

                {/*{(this.state.paymentStatus === "failure") && <section className="section-b-space">*/}
                {/*    /!*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*!/*/}
                {/*    <div className="container padding-cls">*/}
                {/*        <div>*/}
                {/*            <span style={{color: '#ff0000'}}>*/}
                {/*                Oops! something went wrong. The transaction was unsuccessful*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>}*/}

                {/*{(this.state.paymentStatus === "cancel") && <section className="section-b-space">*/}
                {/*    /!*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*!/*/}
                {/*    <div className="container padding-cls">*/}
                {/*        <div>*/}
                {/*            <span style={{color: '#ff0000'}}>*/}
                {/*                Oops! something went wrong. The transaction was Canceled*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>}*/}

                {/*{(this.state.paymentStatus === "success") && <section className="section-b-space">*/}
                {/*    /!*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*!/*/}
                {/*    <div className="container padding-cls">*/}
                {/*        <div>*/}
                {/*            <span style={{color: '#00b100'}}>*/}
                {/*                Congrats the transaction is almost done.<br/>*/}
                {/*                Confirming the order.<br/>*/}
                {/*                Please Wait and do not refresh the page.*/}
                {/*            </span>*/}
                {/*        </div>*/}
                {/*    </div>*/}
                {/*</section>}*/}


                {/*<Elements stripe={stripePromise}>*/}
                <section className="section-b-space">
                    {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                    <div className="container padding-cls">
                        <div className="checkout-page">


                            <div className="checkout-form">
                                {/*<form>*/}
                                <div className="checkout row">
                                    <div className="col-lg-6 col-sm-12 col-xs-12">
                                        {(!user.isLogedIn) ? <div>




                                            <div className="col-lg-12" style={{
                                                border: '1px solid #dddddd',
                                                paddingTop: '20px',
                                                paddingBottom: '20px',
                                                marginBottom: '29px',
                                                maxWidth: '100%',
                                                marginTop: '20px'
                                            }}>
                                                {/*<h3>create account</h3>*/}
                                                <div className="theme-card">
                                                    {/*<form className="theme-form">*/}
                                                    <div className="checkout-title">
                                                        <h3>Register New User</h3>
                                                    </div>
                                                    <div className="form-row">
                                                        <div className="col-md-6">
                                                            <label htmlFor="email"
                                                                   style={{color: '#777777'}}>Name</label>
                                                            <input type="text" className="form-control"
                                                                   id="uname"
                                                                   name="uname" value={this.state.reguser.uname}
                                                                   onChange={this.handleRegChange}
                                                                   placeholder="Name" required=""/>
                                                            {this.state.submitted && !this.state.reguser.uname &&
                                                                <div className="help-block">Name is required</div>
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="review"
                                                                   style={{color: '#777777'}}>Phone</label>
                                                            <input type="text" className="form-control"
                                                                   id="phone"
                                                                   name="phone" value={this.state.reguser.phone}
                                                                   onChange={this.handleRegChange}
                                                                   placeholder="Phone" required=""/>
                                                            {this.state.submitted && !this.state.reguser.phone &&
                                                                <div className="help-block">Phone is required</div>
                                                            }
                                                        </div>
                                                    </div>
                                                    <div className="form-row" style={{marginTop: '20px'}}>
                                                        <div className="col-md-6">
                                                            <label htmlFor="email"
                                                                   style={{color: '#777777'}}>Email</label>
                                                            <input type="text" className="form-control"
                                                                   id="email"
                                                                   name="email" value={this.state.reguser.email}
                                                                   onChange={this.handleRegChange}
                                                                   placeholder="Email" required=""/>
                                                            {this.state.submitted && !this.state.reguser.email &&
                                                                <div className="help-block">Email is required</div>
                                                            }
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label htmlFor="review"
                                                                   style={{color: '#777777'}}>Password</label>
                                                            <input type="password" className="form-control"
                                                                   id="review" name="password"
                                                                   value={this.state.reguser.password}
                                                                   onChange={this.handleRegChange}
                                                                   placeholder="Enter your password"
                                                                   required=""/>
                                                            {this.state.submitted && !this.state.reguser.password &&
                                                                <div className="help-block">Password is
                                                                    required</div>
                                                            }
                                                        </div>
                                                        {/*<a href="#" className="btn btn-solid">create Account</a>*/}
                                                        {/*<button className="btn btn-solid"*/}
                                                        {/*        style={{marginLeft: '10px'}} >Create*/}
                                                        {/*    Account*/}
                                                        {/*</button>*/}
                                                        <div className="btn btn-solid"
                                                             style={{marginLeft: '5px', marginTop: '25px'}}
                                                             onClick={this.userRegister}>Create Account
                                                        </div>
                                                    </div>
                                                    {/*</form>*/}
                                                </div>
                                            </div>

                                            <div style={{textAlign: 'center'}}>
                                                <h3>Or</h3>
                                            </div>

                                            <div className="col-lg-6 col-sm-12 col-xs-12"
                                                 style={{
                                                     border: '1px solid #dddddd',
                                                     paddingTop: '20px',
                                                     paddingBottom: '20px',
                                                     marginBottom: '29px',
                                                     maxWidth: '100%'
                                                 }}>
                                                <div className="checkout-title">
                                                    <h3>Login</h3>
                                                </div>
                                                {/*<form className="theme-form">*/}
                                                <div className="form-group">
                                                    {this.state.invalid &&
                                                        <div className="help-block">Invalid UserName or
                                                            Password<br/>
                                                        </div>
                                                    }
                                                    <label htmlFor="email">Email</label>

                                                    <input type="text" className="form-control" id="email"
                                                           placeholder="Email"
                                                           name="email" value={this.state.user.email}
                                                           onChange={this.handleChange}
                                                           required=""/>
                                                    {this.state.submitted && !this.state.user.email &&
                                                        <div className="help-block">Email is required</div>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label htmlFor="review">Password</label>
                                                    <input type="password" className="form-control"
                                                           id="password"
                                                           name="password" value={this.state.user.password}
                                                           onChange={this.handleChange}
                                                           placeholder="Enter your password" required=""/>
                                                    {this.state.submitted && !this.state.user.password &&
                                                        <div className="help-block">Password is required</div>
                                                    }
                                                </div>
                                                <div className="btn btn-solid"
                                                     onClick={() => {
                                                         console.log("LOGINPAGE ole ole ole", this.state.user);
                                                         const {user} = this.state;
                                                         let data = {
                                                             identifier: user.email,
                                                             password: user.password,
                                                         }
                                                         //getUser();
                                                         this.props.login(data);
                                                     }}
                                                    //onClick={this.userLogin}
                                                >Login
                                                </div>
                                                <a href={`${process.env.PUBLIC_URL}/pages/forget-password`}
                                                   className="btn btn-solid" style={{marginLeft: "10px"}}>Forget
                                                    Password</a>
                                                {/*</form>*/}
                                            </div>

                                        </div> : ""}

                                        {(user.isLogedIn) ?
                                            <div>
                                                <div className="checkout-title">
                                                    <h3>Billing Details</h3>
                                                </div>
                                                <div className="row check-out">
                                                    {/*<div className="form-group col-md-12 col-sm-12 col-xs-12">*/}
                                                    {/*    <div className="field-label">Name</div>*/}
                                                    {/*    <input type="text" name="city" value={this.state.city}*/}
                                                    {/*           onChange={this.setStateFromInput}/>*/}
                                                    {/*    {this.validator.message('city', this.state.city, 'required|alpha')}*/}
                                                    {/*</div>*/}
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Name</div>
                                                        <input type="text" name="first_name"
                                                               value={this.state.first_name}
                                                               onChange={this.setStateFromInput}/>
                                                        {this.validator.message('first_name', this.state.first_name, 'required|alpha_space')}
                                                    </div>

                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">Email Address</div>
                                                        <input type="text" name="email" value={this.state.email}
                                                               onChange={this.setStateFromInput}/>
                                                        {this.validator.message('email', this.state.email, 'required|email')}
                                                    </div>

                                                    <div className="form-group col-md-6 col-sm-6 col-xs-12">
                                                        <div className="field-label">Phone</div>
                                                        <input type="text" name="phone" value={this.state.phone}
                                                               onChange={this.setStateFromInput}/>
                                                        {this.validator.message('phone', this.state.phone, 'required|phone')}
                                                    </div>


                                                    {/*<div className="form-group col-md-6 col-sm-6 col-xs-12">*/}
                                                    {/*    <div className="field-label">Password</div>*/}
                                                    {/*    <input type="text" name="last_name" value={this.state.last_name}*/}
                                                    {/*           onChange={this.setStateFromInput}/>*/}
                                                    {/*    {this.validator.message('last_name', this.state.last_name, 'required|alpha')}*/}
                                                    {/*</div>*/}

                                                    {/*<h3>Billing Details</h3>*/}

                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Country</div>
                                                        <select name="country" value={this.state.country}
                                                                onChange={this.setStateFromInput}>
                                                            <option>Bangladesh</option>
                                                            <option>India</option>
                                                            <option>South Africa</option>
                                                            <option>United State</option>
                                                            <option>Australia</option>
                                                        </select>
                                                        {this.validator.message('country', this.state.country, 'required')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Address</div>
                                                        <input type="text" name="address"
                                                               value={this.state.address}
                                                               onChange={this.setStateFromInput}
                                                               placeholder="Street address"/>
                                                        {this.validator.message('address', this.state.address, 'required|min:20|max:120')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-12 col-xs-12">
                                                        <div className="field-label">Town/City</div>
                                                        <input type="text" name="city" value={this.state.city}
                                                               onChange={this.setStateFromInput}/>
                                                        {this.validator.message('city', this.state.city, 'required|alpha')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                        <div className="field-label">State / County</div>
                                                        <input type="text" name="state" value={this.state.state}
                                                               onChange={this.setStateFromInput}/>
                                                        {this.validator.message('state', this.state.state, 'required|alpha')}
                                                    </div>
                                                    <div className="form-group col-md-12 col-sm-6 col-xs-12">
                                                        <div className="field-label">Postal Code</div>
                                                        <input type="text" name="pincode"
                                                               value={this.state.spincode}
                                                               onChange={this.setStateFromInput}/>
                                                        {this.validator.message('pincode', this.state.pincode, 'required|integer')}
                                                    </div>
                                                    <div
                                                        className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">

                                                        {/*<CardElement options={CARD_ELEMENT_OPTIONS}/>*/}

                                                        {/*<form onSubmit={this.handleSubmit}>*/}
                                                        {/*<CardSection hidePostalCode={true}/>*/}
                                                        <br/>
                                                        {/*<button className="btn-solid btn"*/}
                                                        {/*        disabled={!this.props.stripe}>Confirm order*/}
                                                        {/*</button>*/}
                                                        {/*</form>*/}

                                                        {/*<CheckoutForm orderfun={this.newCheckout}/>*/}

                                                        {/*<input type="checkbox" name="create_account" id="account-option"*/}
                                                        {/*       checked={this.state.create_account}*/}
                                                        {/*       onChange={this.setStateFromCheckbox}/>*/}
                                                        {/*&ensp; <label htmlFor="account-option">Create An Account?</label>*/}
                                                        {/*{this.validator.message('checkbox', this.state.create_account, 'create_account')}*/}
                                                    </div>
                                                </div>
                                            </div>
                                            : ""}

                                    </div>
                                    <div className="col-lg-6 col-sm-12 col-xs-12">
                                        <div className="checkout-details">
                                            <div className="order-box">
                                                <div className="title-box">
                                                    <div>Details <span> Total</span></div>
                                                </div>
                                                <ul className="qty">
                                                    {cartItems.map((item, index) => {
                                                        return <li key={index}>{item.Name}
                                                            <span> {symbol} {parseInt(item.sum * appConfig.inr2bdt, 10)}</span><br/>
                                                            {item.bType}
                                                            <br/>
                                                            At {this.printDateTime(item.bTime)}
                                                            {/*{Date(item.bTime).toLocaleString()}*/}
                                                            {/*At {item.bTime.toString()}*/}
                                                        </li>
                                                    })
                                                    }
                                                </ul>
                                                <ul className="sub-total">
                                                    <li>Subtotal <span className="count">{symbol}{total}</span>
                                                    </li>
                                                    {/*<li>Shipping <div className="shipping">*/}
                                                    {/*    <div className="shopping-option">*/}
                                                    {/*        <input type="checkbox" name="free-shipping"*/}
                                                    {/*               id="free-shipping"/>*/}
                                                    {/*        <label htmlFor="free-shipping">Free Shipping</label>*/}
                                                    {/*    </div>*/}
                                                    {/*    <div className="shopping-option">*/}
                                                    {/*        <input type="checkbox" name="local-pickup"*/}
                                                    {/*               id="local-pickup"/>*/}
                                                    {/*        <label htmlFor="local-pickup">Local Pickup</label>*/}
                                                    {/*    </div>*/}
                                                    {/*</div>*/}
                                                    {/*</li>*/}
                                                </ul>

                                                <ul className="total">
                                                    <li>Total <span className="count">{symbol}{total}</span>
                                                    </li>
                                                </ul>
                                            </div>

                                            <div className="payment-box">
                                                <div className="upper-box">
                                                    <div className="payment-options">
                                                        <ul>
                                                            {/*<li>*/}
                                                            {/*    <div className="radio-option stripe">*/}
                                                            {/*        <input type="radio" name="payment-group"*/}
                                                            {/*               id="payment-2" defaultChecked={true}*/}
                                                            {/*               onClick={() => this.checkhandle('stripe')}/>*/}
                                                            {/*        /!*<label htmlFor="payment-2">Stripe (Credit /*!/*/}
                                                            {/*        /!*    Debit Card)</label>*!/*/}
                                                            {/*        <label htmlFor="payment-1">Credit /*/}
                                                            {/*            Debit Card<span*/}
                                                            {/*                className="image"><img*/}
                                                            {/*                src={`${process.env.PUBLIC_URL}/assets/images/paypal.png`}*/}
                                                            {/*                alt=""/></span></label>*/}
                                                            {/*    </div>*/}
                                                            {/*</li>*/}
                                                            <li>
                                                                <div className="radio-option stripe">
                                                                    <input type="radio" name="payment-group"
                                                                           id="payment-2" defaultChecked={true}
                                                                           onClick={() => this.checkhandle('bKash')}/>
                                                                    {/*<label htmlFor="payment-2">Stripe (Credit /*/}
                                                                    {/*    Debit Card)</label>*/}
                                                                    <label htmlFor="payment-1">Pay with bKash
                                                                        <span className="image">
                                                                            <img src={bkash}/>
                                                                            {/*<img*/}
                                                                            {/*src={`${process.env.PUBLIC_URL}/assets/images/paypal.png`}*/}
                                                                            {/*alt=""/>*/}
                                                                        </span>
                                                                    </label>
                                                                </div>
                                                            </li>
                                                            {/*<li>*/}
                                                            {/*    <div className="radio-option paypal">*/}
                                                            {/*        <input type="radio" name="payment-group"*/}
                                                            {/*               id="payment-1"*/}
                                                            {/*               onClick={() => this.checkhandle('paypal')}/>*/}
                                                            {/*        <label htmlFor="payment-1">PayPal<span*/}
                                                            {/*            className="image"><img*/}
                                                            {/*            src={`${process.env.PUBLIC_URL}/assets/images/paypal.png`}*/}
                                                            {/*            alt=""/></span></label>*/}
                                                            {/*    </div>*/}
                                                            {/*</li>*/}
                                                        </ul>
                                                    </div>
                                                </div>
                                                {/*{(total !== 0) ?*/}
                                                    <div className="text-right">
                                                        {(this.state.payment === 'stripe') ?
                                                            <button type="button" className="btn-solid btn"
                                                                    onClick={() => this.newCheckout()}>Place
                                                                Order</button> :
                                                            <div>
                                                                {/*<button id="bKash_button" onClick={() => this.newCheckoutBKash()}>Pay with bKash</button>*/}
                                                                {/*<button id="bKash_button" onClick={() => this.newCheckoutBKash()} style={{border: 'none'}}>*/}
                                                                {/*    Pay with bKash<br/>*/}
                                                                {/*    <img src={bkash}/>*/}
                                                                {/*</button>*/}
                                                                    <button className="btn btn-primary" onClick={() => this.newCheckoutBKash()}>
                                                                        Place Order
                                                                    </button>
                                                                {/*<PaypalExpressBtn env={'sandbox'} client={client}*/}
                                                                {/*                  currency={'USD'} total={total}*/}
                                                                {/*                  onError={onError}*/}
                                                                {/*                  onSuccess={onSuccess}*/}
                                                                {/*                  onCancel={onCancel}/>*/}
                                                            </div>
                                                        }
                                                    </div>
                                                    {/*: ''}*/}


                                                {/*<CheckoutForm />*/}
                                                {/*<form onSubmit={this.handleSubmit}>*/}
                                                {/*    <CardSection />*/}
                                                {/*    /!*<button disabled={!this.props.stripe}>Confirm order</button>*!/*/}
                                                {/*</form>*/}


                                                {/*<StripeCheckout*/}
                                                {/*    token={this.onToken}*/}
                                                {/*    stripeKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD"*/}
                                                {/*    name="Test te" // the pop-in header title*/}
                                                {/*    description="test booking" // the pop-in header subtitle*/}
                                                {/*    email="info@vidhub.co"*/}
                                                {/*    reconfigureOnUpdate={false}*/}
                                                {/*    // ComponentClass="div"*/}
                                                {/*    label="Buy the Thing" // text inside the Stripe button*/}
                                                {/*    panelLabel="Give Money" // prepended to the amount in the bottom pay button*/}
                                                {/*    amount={450} // cents*/}
                                                {/*    currency="INR"*/}
                                                {/*>*/}
                                                {/*    /!*<button className="btn btn-primary">*!/*/}
                                                {/*    /!*    pay*!/*/}
                                                {/*    /!*</button>*!/*/}
                                                {/*</StripeCheckout>*/}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row section-t-space">
                                    {/*<div className="col-lg-6">*/}
                                    {/*    <div className="stripe-section">*/}
                                    {/*        <h5>stripe dummy card</h5>*/}
                                    {/*        <div>*/}
                                    {/*            <h5 className="checkout_class">dummy test</h5>*/}
                                    {/*            <table>*/}
                                    {/*                <tbody>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td>card number</td>*/}
                                    {/*                    <td>4242424242424242</td>*/}
                                    {/*                </tr>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td>mm/yy</td>*/}
                                    {/*                    <td>2/2020</td>*/}
                                    {/*                </tr>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td>cvc</td>*/}
                                    {/*                    <td>2222</td>*/}
                                    {/*                </tr>*/}
                                    {/*                </tbody>*/}
                                    {/*            </table>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                    {/*<div className="col-lg-6 m-sm-t-2">*/}
                                    {/*    <div className="stripe-section">*/}
                                    {/*        <h5>dummy card with verification</h5>*/}
                                    {/*        <div>*/}
                                    {/*            <h5 className="checkout_class">insufficient_funds*/}
                                    {/*                4000000000009995</h5>*/}
                                    {/*            <table>*/}
                                    {/*                <tbody>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td>card number</td>*/}
                                    {/*                    <td>4000002500003155</td>*/}
                                    {/*                </tr>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td>mm/yy</td>*/}
                                    {/*                    <td>2/2020</td>*/}
                                    {/*                </tr>*/}
                                    {/*                <tr>*/}
                                    {/*                    <td>cvc</td>*/}
                                    {/*                    <td>2222</td>*/}
                                    {/*                </tr>*/}
                                    {/*                </tbody>*/}
                                    {/*            </table>*/}
                                    {/*        </div>*/}
                                    {/*    </div>*/}
                                    {/*</div>*/}
                                </div>
                                {/*</form>*/}
                            </div>
                        </div>
                    </div>
                    {/*</StripeProvider>*/}
                </section>
                <Modal
                    title={this.state.modelTitle}
                    visible={this.state.visible}
                    // okButtonProps={{ disabled: true }}
                    // cancelButtonProps={{ disabled: true }}
                    // onOk={this.checkoutFormOK}
                    // onOk={this.handleCancel}
                    // confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                    footer={[]}
                >
                    {/*<span>Redirecting to BKash, Please Wait</span>*/}

                    {(this.state.paymentStatus === "redirecting") && <section className="section-b-space">
                        {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                        <div className="container padding-cls">
                            <div>
                                <span>Redirecting to BKash, Please Wait</span>
                            </div>
                        </div>
                    </section>}

                    {(this.state.paymentStatus === "failure") && <section className="section-b-space">
                        {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                        <div className="container padding-cls">
                            <div>
                            <span style={{color: '#ff0000'}}>
                                Payment Failed
                            </span>
                            </div>
                        </div>
                    </section>}

                    {(this.state.paymentStatus === "duplicate") && <section className="section-b-space">
                        {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                        <div className="container padding-cls">
                            <div>
                            <span style={{color: '#ff0000'}}>
                                {this.state.paymentErrorMessage? this.state.paymentErrorMessage:'Duplicate for All Transactions'}
                            </span>
                            </div>
                        </div>
                    </section>}

                    {(this.state.paymentStatus === "otperror") && <section className="section-b-space">
                        {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                        <div className="container padding-cls">
                            <div>
                            <span style={{color: '#ff0000'}}>
                                Payment Failed
                            </span>
                            </div>
                        </div>
                    </section>}

                    {(this.state.paymentStatus === "cancel") && <section className="section-b-space">
                        {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                        <div className="container padding-cls">
                            <div>
                            <span style={{color: '#ff0000'}}>
                                Payment Canceled
                            </span>
                            </div>
                        </div>
                    </section>}

                    {(this.state.paymentStatus === "success") && <section className="section-b-space">
                        {/*<StripeProvider apiKey="pk_test_REHyiGUxbzTzOde6HLEtbbvc00iBwDzlGD">*/}
                        <div className="container padding-cls">
                            <div>
                            <span style={{color: '#00b100'}}>
                                Congrats the transaction is almost done.<br/>
                                Confirming the order.<br/>
                                Please Wait and do not refresh the page.
                            </span>
                            </div>
                        </div>
                    </section>}
                    {/*<CheckoutForm onRef={ref => (this.child = ref)}  orderfun={this.newCheckout} user={user} bookingEntry={this.state.bookingEntry} okmodel={this.handleOk}/>*/}
                    {/*<CardElement options={CARD_ELEMENT_OPTIONS} />*/}
                </Modal>
                {/*</Elements>*/}
            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    cartItems: state.cartList.cart,
    // symbol: state.data.symbol,
    symbol: "BDT",
    total: getCartTotal(state.cartList.cart),
    user: state.auth,
})

export default connect(
    mapStateToProps,
    {removeFromWishlist, removeFromCart, login, setUser}
)(checkOut)